import { useNavigation } from '@react-navigation/native';
import { images } from '_/assets';
import { Button, Text } from '_/components/';
import { colors, fontSizes, spacing } from '_/constants/theme';
import { AppRoute } from '_/navigation/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, StyleSheet, Dimensions } from 'react-native';

export default function NoReservation() {
  const navigation = useNavigation<any>();
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Image source={images.denied} style={styles.image} />
      <Text fontType="medium" fontSize={fontSizes.md2}>
        {t('reservationsScreen.noReservationsMessage')}
      </Text>
      <Text fontType="medium" fontSize={fontSizes.md2}>
        {t('reservationsScreen.seeLocationsMessage')}
      </Text>
      <Button
        backgroundColor={colors.lightBlue}
        textColor={colors.white}
        style={styles.button}
        onPress={() => navigation.navigate(AppRoute.HOME)}
      >
        {t('reservationsScreen.seeLocations')}
      </Button>
    </View>
  );
}

const { height } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: height * 0.25 + 32,
    marginBottom: 60,
    paddingHorizontal: spacing.md,
  },
  image: {
    height: 87,
    width: 87,
    marginBottom: spacing.md,
  },
  button: {
    marginTop: 60,
  },
});
