import { colors, spacing } from '_/constants/theme';
import { StyleSheet, Dimensions, Platform } from 'react-native';

const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    maxHeight: windowHeight,
    width: '100%',
    ...(Platform.OS === 'web' && { maxWidth: 550 }),
    margin: 0,
    marginTop: 80,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    backgroundColor: colors.clear,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    paddingTop: 20,
  },
  scrollViewContainer: {
    paddingBottom: 40,
  },
  title: {
    marginTop: 0,
    marginBottom: spacing.lg,
    maxWidth: '90%',
  },
  wrapper: {
    paddingHorizontal: spacing.md,
  },
  dateInfo: {
    marginTop: 11,
    marginBottom: spacing.xxs,
    color: colors.darkGrey,
    textTransform: 'capitalize',
  },
  dotSeparator: {
    width: 5,
    height: 5,
    borderRadius: 5 / 2,
    backgroundColor: colors.lightGrey,
    marginHorizontal: spacing.xxxs,
  },
  amenities: {
    paddingHorizontal: spacing.md,
    marginBottom: 24,
  },
  infoMessage: {
    marginTop: spacing.md,
    backgroundColor: colors.attentionYellow,
    padding: spacing.md,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  infoText: {
    textAlignVertical: 'center',
    textAlign: 'justify',
    paddingHorizontal: spacing.md,
  },
  cancelationMessage: {
    marginVertical: spacing.md,
    paddingHorizontal: spacing.md,
  },
});

export default styles;
