interface ILogEvent {
  name: string;
  properties?: {
    name: string;
    screen: string;
    purpose: string;
  };
}

export default function useFirebaseAnalytics() {
  const analyticsLogEvent = async ({ name, properties }: ILogEvent) => {
    // TODO: Add analytics again
    console.log(name, properties);
    // await logEvent(name, {
    //   name: properties?.name,
    //   screen: properties?.screen,
    //   purpose: properties?.purpose,
    // });
  };

  return { analyticsLogEvent };
}
