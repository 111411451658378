import Text from '_/components/Text';
import { colors, fontSizes, spacing } from '_/constants/theme';
import { useDateContext } from '_/hooks/DateContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { getSpaceTypeColor } from '_/services/models/enums/space-type.enum';
import { WeekDaysTypes } from '_/services/models/events.model';
import { getDay } from 'date-fns';
import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
interface WeekDaysProps {
  selectedDays: WeekDaysTypes[];
  setSelectedDays: React.Dispatch<React.SetStateAction<WeekDaysTypes[]>>;
}

export default function WeekDays({ selectedDays, setSelectedDays }: WeekDaysProps) {
  const { spaceType } = useSpaceContext();

  const { selectedEventDate } = useDateContext();

  function handleSelectedDays(id: number) {
    const days = [...selectedDays];
    days[id].selected = !days[id].selected;
    setSelectedDays(days);
  }

  return (
    <View style={styles.weekDays}>
      {selectedDays.map((day) => {
        return (
          <TouchableOpacity
            style={[
              styles.day,
              {
                backgroundColor: day.selected ? getSpaceTypeColor(spaceType) : colors.white,
              },
            ]}
            disabled={getDay(selectedEventDate.start) === day.day}
            key={day.id}
            onPress={() => handleSelectedDays(day.id)}
          >
            <Text
              fontType="bold"
              fontSize={fontSizes.sm}
              color={day.selected ? colors.white : colors.black}
            >
              {day.name}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  weekDays: {
    paddingHorizontal: spacing.md,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  day: {
    paddingVertical: 10,
    paddingHorizontal: 8.75,
    borderRadius: 5,
  },
});
