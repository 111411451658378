import { Feather } from '@expo/vector-icons';
import { colors, fontSizes } from '_/constants/theme';
import { useSlotContext } from '_/hooks/SlotContext';
import { SpaceType, spaceTypeName } from '_/services/models/enums/space-type.enum';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';

import ArrowBackHeader from '../ArrowBackHeader';
import Text from '../Text';
import styles from './styles';

interface SpaceTypeHeaderProps {
  backButtonIsVisible?: boolean;
  onPress?: () => void;
  locationName?: string;
  spaceType: SpaceType;
  availableSlotsCount: string;
  setLayoutMode?: React.Dispatch<React.SetStateAction<'list' | 'map'>>;
  layoutMode?: 'list' | 'map';
  isMapVisible?: boolean;
  selectorOrSearch?: 'search' | 'selector';
  setSelectorOrSearch?: React.Dispatch<React.SetStateAction<'search' | 'selector'>>;
}

export default function SpaceTypeHeader({
  spaceType,
  locationName,
  availableSlotsCount,
  onPress,
  setLayoutMode,
  layoutMode,
  isMapVisible,
  selectorOrSearch,
  setSelectorOrSearch,
}: SpaceTypeHeaderProps) {
  const { setSlotSearchValue } = useSlotContext();
  const { t } = useTranslation();

  const handleChangeMode = useCallback(() => {
    !!setLayoutMode && setLayoutMode(layoutMode === 'map' ? 'list' : 'map');
    if (layoutMode === 'map') {
      !!setSelectorOrSearch && setSelectorOrSearch('selector');
    }
  }, [layoutMode, setLayoutMode, setSelectorOrSearch]);

  const handleChangeSelectorOrSearch = useCallback(() => {
    !!setSelectorOrSearch &&
      setSelectorOrSearch(selectorOrSearch === 'selector' ? 'search' : 'selector');
    setSlotSearchValue(undefined);
  }, [selectorOrSearch, setSelectorOrSearch, setSlotSearchValue]);

  const LayoutModeButton = useCallback(() => {
    if (!isMapVisible || spaceType !== SpaceType.WORK) {
      return null;
    }

    if (layoutMode === 'list') {
      return (
        <TouchableOpacity style={styles().changeModeButtons} onPress={handleChangeMode}>
          <Feather name="map-pin" size={18} />
        </TouchableOpacity>
      );
    }

    return (
      <TouchableOpacity style={styles().changeModeButtons} onPress={handleChangeMode}>
        <Feather name="list" size={18} />
      </TouchableOpacity>
    );
  }, [handleChangeMode, isMapVisible, layoutMode, spaceType]);

  const SelectorOrSearchButton = useCallback(() => {
    if (!isMapVisible || spaceType !== SpaceType.WORK) {
      return null;
    }
    if (selectorOrSearch === 'selector' && layoutMode === 'map') {
      return (
        <TouchableOpacity
          style={[styles().changeModeButtons, styles().selectorOrSearchButton]}
          onPress={handleChangeSelectorOrSearch}
        >
          <Feather name="search" size={18} />
        </TouchableOpacity>
      );
    }
    if (layoutMode === 'map') {
      return (
        <TouchableOpacity
          style={[styles().changeModeButtons, styles().selectorOrSearchButton]}
          onPress={handleChangeSelectorOrSearch}
        >
          <Feather name="calendar" size={18} />
        </TouchableOpacity>
      );
    } else {
      return null;
    }
  }, [handleChangeSelectorOrSearch, isMapVisible, layoutMode, selectorOrSearch, spaceType]);

  return (
    <TouchableOpacity style={styles(spaceType).header} onPress={onPress} activeOpacity={1}>
      <View style={styles().artGroup}>
        <View style={styles(spaceType).artOne} />
        <View style={styles(spaceType).artTwo} />
        <View style={styles(spaceType).artThree} />
      </View>
      <ArrowBackHeader textColor={colors.white} style={styles().arrowBackHeader}>
        {locationName}
      </ArrowBackHeader>
      <View style={styles().infoGroup}>
        <View style={styles().lowerContainer}>
          <Text
            style={styles().title}
            fontSize={fontSizes.lg2}
            fontType="bold"
            color={colors.white}
          >
            {t(`spaceTypes.${spaceTypeName[spaceType]}`)}
          </Text>
          <View style={styles().optionsContainer}>
            <SelectorOrSearchButton />
            <LayoutModeButton />
          </View>
        </View>
        <Text fontType="medium" fontSize={fontSizes.md2} color={colors.white}>
          {availableSlotsCount}
        </Text>
      </View>
    </TouchableOpacity>
  );
}
