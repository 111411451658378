import { colors, spacing } from '_/constants/theme';
import { Dimensions, StyleSheet } from 'react-native';

const { height, width } = Dimensions.get('window');

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: spacing.md,
    paddingVertical: spacing.xl,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    marginTop: spacing.md,
    backgroundColor: colors.clear,
  },
  profileAvatarContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  logoutBtn: {
    marginTop: spacing.xl,
    marginBottom: spacing.xs,
  },
  topSection: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: height * 0.3,
    width,
    maxWidth: 550,
    backgroundColor: colors.darkBlue,
  },
  topPicture: {
    width: '100%',
    height: '100%',
    opacity: 0.5,
  },
});

export default styles;
