import { colors, spacing, alphaColor } from '_/constants/theme';
import { getSpaceTypeColor, SpaceType } from '_/services/models/enums/space-type.enum';
import { StyleSheet, Dimensions, Platform } from 'react-native';

const { width } = Dimensions.get('window');

const slotSize = width / 4 - 16;
const webSlotSize = width / 4 - 24;

const styles = (spaceType?: SpaceType) =>
  StyleSheet.create({
    modal: {
      backgroundColor: colors.clear,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      flex: 1,
      width: '100%',
      ...(Platform.OS === 'web' && { minWidth: 550, maxWidth: 550 }),
      margin: 0,
      marginTop: 80,
      alignSelf: 'center',
      justifyContent: 'flex-start',
      paddingVertical: 20,
    },
    scrollViewContainer: {
      paddingBottom: 40,
    },
    title: {
      marginBottom: spacing.lg,
      marginTop: 0,
    },
    wrapper: {
      paddingHorizontal: spacing.md,
    },
    list: {
      flex: 1,
      paddingHorizontal: spacing.xs,
      paddingVertical: spacing.xs,
    },
    container: {
      paddingVertical: spacing.lg,
      paddingHorizontal: 36,
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    text: {
      textAlign: 'center',
    },
    topText: {
      marginBottom: spacing.md,
    },
    bottomText: {
      marginTop: spacing.md,
    },
    slot: {
      width: slotSize,
      height: slotSize,
      maxWidth: webSlotSize,
      maxHeight: webSlotSize,
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 1,
      borderColor: alphaColor(getSpaceTypeColor(spaceType), 0.3),
      borderRadius: 6,
      margin: 6,
    },
    slotName: {
      padding: 1,
      textAlign: 'center',
    },
  });
export default styles;
