import { colors, fontFamily, fontSizes, spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  scene: {
    flex: 1,
  },
  tab: {
    backgroundColor: 'transparent',
    elevation: 0,
    borderBottomWidth: 2,
    borderColor: colors.white,
    paddingTop: spacing.xs,
  },
  indicator: {
    backgroundColor: colors.lightBlue,
    height: 2,
    marginBottom: -2,
    borderRadius: 2,
  },
  label: {
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: fontFamily.bold,
    fontSize: fontSizes.md,
    textTransform: 'capitalize',
  },
});

export default styles;
