import { useNavigation } from '@react-navigation/native';
import { images } from '_/assets';
import { Avatar, Button, DropdownInput, Input, Text, TextArea } from '_/components';
import { apiConfig } from '_/config/api';
import generalConfig from '_/config/general';
import { colors, fontSizes } from '_/constants/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useOrganizationContext } from '_/hooks/OrganizationContext';
import { AppRoute } from '_/navigation/types';
import { phoneMask } from '_/utils';
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Image,
  Linking,
  View,
  SafeAreaView,
  TouchableOpacity,
  KeyboardAvoidingView,
  Keyboard,
  Platform,
  ScrollView,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';

import { answers, answersOptions } from './answers';
import styles from './styles';

export default function OnBoarding() {
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState('');
  const [message, setMessage] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [keyBoardIsShown, setKeyBoardIsShown] = useState(false);
  const { getMember } = useOrganizationContext();
  const { signOut, updateUserCurrentOrganization, updateMember, user } = useAuth();
  const id = user?.id;
  const scrollViewRef = useRef<ScrollView>(null);
  const navigation = useNavigation<any>();
  const { t } = useTranslation();

  const handleGetMember = async () => {
    setLoading(true);
    try {
      const [member] = await getMember(user.id);
      if (member) {
        await updateMember(member);
        await updateUserCurrentOrganization({ id, currentOrganizationId: member.organizationId });
      }
    } catch (error) {
      showMessage({
        message: t('error'),
        description: t('onBoarding.errorMessages.notMember'),
        backgroundColor: colors.errorRed,
      });
    }
    setLoading(false);
  };

  const handleSendForm = useCallback(async () => {
    const dataSlack = {
      text: `Formulário App`,
      attachments: [
        {
          color: colors.black,
          fields: [
            {
              title: 'Nome da Empresa',
              value: companyName,
              short: false,
            },
            {
              title: 'Nome',
              value: user.name,
              short: false,
            },
            {
              title: 'Email',
              value: user.email,
              short: false,
            },
            {
              title: 'Telefone para contato',
              value: mobilePhone,
              short: false,
            },
            {
              title: 'Mensagem',
              value: message,
              short: false,
            },
          ],
          footer: `${generalConfig.appName}`,
        },
      ],
    };
    setLoading(true);
    await axios
      .post(apiConfig.slackUrl, dataSlack, {
        headers: { 'Content-Type': 'application/json' },
      })
      .catch((_error) => {
        showMessage({
          message: t('error'),
          description: t('onBoarding.errorMessages.sentError'),
          backgroundColor: colors.errorRed,
        });
      });
    setCompanyName('');
    setMessage('');
    setMobilePhone('');
    setLoading(false);
  }, [companyName, user.name, user.email, mobilePhone, message, t]);

  const isButtonDisabled = useMemo(() => {
    if (companyName === '' && message === '' && mobilePhone === '') {
      return true;
    } else {
      return false;
    }
  }, [companyName, message, mobilePhone]);

  const HandleButton = useCallback(() => {
    if (answer === answers.withoutAccess) {
      return (
        <Button
          hasBorder
          loading={loading}
          iconSize={18}
          backgroundColor={colors.lightBlue}
          textColor={colors.white}
          onPress={handleGetMember}
        >
          {t('onBoarding.joinApp', { company: generalConfig.appName })}
        </Button>
      );
    }
    if (answer === answers.knowMoreAbout) {
      return (
        <Button
          hasBorder
          loading={loading}
          iconSize={18}
          backgroundColor={colors.lightBlue}
          textColor={colors.white}
          onPress={handleSendForm}
          disabled={isButtonDisabled}
          style={{ opacity: isButtonDisabled ? 0.5 : 1 }}
        >
          {t('onBoarding.sendQuestion')}
        </Button>
      );
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, handleSendForm, isButtonDisabled, loading]);

  const HandleSafeArea = useCallback(() => {
    if (Platform.OS === 'ios') {
      return (
        <KeyboardAvoidingView enabled={false}>
          <Text style={styles.text} fontSize={fontSizes.md2}>
            {t('onBoarding.chooseOneOptionBellow')}
          </Text>
          <DropdownInput setValue={setAnswer} value={answer} options={answersOptions} />

          <HandleButton />
          <Button onPress={signOut}>{t('logout')}</Button>
        </KeyboardAvoidingView>
      );
    }
    if (!keyBoardIsShown && Platform.OS === 'android') {
      return (
        <KeyboardAvoidingView enabled={false}>
          <Text style={styles.text} fontSize={fontSizes.md2}>
            {t('onBoarding.chooseOneOptionBellow')}
          </Text>
          <DropdownInput setValue={setAnswer} value={answer} options={answersOptions} />

          <HandleButton />
          <Button onPress={signOut}>{t('logout')}</Button>
        </KeyboardAvoidingView>
      );
    }
    if (keyBoardIsShown && Platform.OS === 'android') {
      return null;
    } else {
      return null;
    }
  }, [HandleButton, answer, keyBoardIsShown, signOut, t]);

  useEffect(() => {
    scrollViewRef.current?.scrollToEnd();
  }, [HandleSafeArea]);

  useEffect(() => {
    Keyboard.addListener('keyboardDidHide', () => setKeyBoardIsShown(false));
    Keyboard.addListener('keyboardDidShow', () => setKeyBoardIsShown(true));
  }, []);

  return (
    <View style={styles.wrapper}>
      <SafeAreaView>
        <TouchableOpacity
          style={styles.goToProfile}
          onPress={() => navigation.navigate(AppRoute.PROFILE_STACK_SCREEN)}
        >
          <Text fontSize={fontSizes.md2} fontType="medium" style={styles.goToProfileText}>
            {t('profileScreen.profile')}
          </Text>
          <Avatar size={24} />
        </TouchableOpacity>
      </SafeAreaView>
      <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false} ref={scrollViewRef}>
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Image source={images.logo} style={styles.image} resizeMode="center" />
          <Text style={styles.text} fontSize={fontSizes.md2}>
            {t('onBoarding.welcomeApp', { company: generalConfig.appName })}
          </Text>
          <Text style={styles.text} fontSize={fontSizes.md2}>
            {t('onBoarding.seeFullContent')}
          </Text>
          {answer === answers.withoutAccess && (
            <Text style={styles.text} fontSize={fontSizes.md2} fontType="bold">
              {t('onBoarding.adminAlreadyBounded')}
            </Text>
          )}
          {answer === answers.knowMoreAbout && (
            <>
              <TouchableOpacity style={styles.button}>
                <Text
                  style={styles.buttonText}
                  color={colors.lightBlue}
                  fontSize={fontSizes.md2}
                  fontType="bold"
                  onPress={() => Linking.openURL(generalConfig.website)}
                >
                  {t('onBoarding.appSite', { company: generalConfig.appName })}
                </Text>
              </TouchableOpacity>
              <Text style={styles.text} fontSize={fontSizes.md2}>
                {t('or')}
              </Text>
              <Text style={styles.text} fontSize={fontSizes.md2}>
                {t('onBoarding.fillFields')}
              </Text>
              <Input
                placeholder={t('onBoarding.companyName')}
                containerStyle={styles.input}
                onChangeText={setCompanyName}
                value={companyName}
              />
              <Input
                placeholder={t('onBoarding.phoneNumber')}
                containerStyle={styles.input}
                onChangeText={setMobilePhone}
                value={phoneMask(mobilePhone)}
                keyboardType="number-pad"
              />
              <TextArea
                onChangeText={setMessage}
                value={message}
                placeholder={t('onBoarding.typeMessage')}
              />
            </>
          )}
          {!answer && (
            <Text style={styles.text} fontSize={fontSizes.md2}>
              {t('onBoarding.inTouch')}
            </Text>
          )}
        </View>
      </ScrollView>
      <HandleSafeArea />
    </View>
  );
}
