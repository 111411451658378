import { Header, Text } from '_/components';
import { colors } from '_/constants/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useEventContext } from '_/hooks/EventContext';
import { useLocationContext } from '_/hooks/LocationContext';
import { useNotificationContext } from '_/hooks/NotificationsContext';
import { useOrganizationContext } from '_/hooks/OrganizationContext';
import { usersApi } from '_/services/api';
import { TokenType } from '_/services/models/enums/token-type.enum';
import { registerForPushNotificationsAsync } from '_/services/notifications';
import React, { useEffect } from 'react';
import {
  FlatList,
  Platform,
  RefreshControl,
  TouchableOpacity,
  Dimensions,
  View,
} from 'react-native';

import OfficesList from './OfficesList';
import TodayReservations from './TodayReservations';
import { useTranslation } from 'react-i18next';

export default function Home() {
  const { member, user, updateMember, signOut } = useAuth();
  const { getOrganization, organizationData, getMember } = useOrganizationContext();
  const { getLocations, isLoading } = useLocationContext();
  const { getTodayEvents } = useEventContext();
  const { getNotReadNotifications } = useNotificationContext();
  const { width } = Dimensions.get('window');
  const { t } = useTranslation();

  useEffect(() => {
    async function setUserMember() {
      const [member] = await getMember(user?.id as string, user?.currentOrganizationId as string);
      if (member) {
        await updateMember(member);
      }
    }

    if (!member) {
      setUserMember();
    }
  }, [user?.id]);

  useEffect(() => {
    async function setUserNotificationToken() {
      const data = await registerForPushNotificationsAsync();
      if (!data) return;

      await usersApi.createUserToken({
        userId: user.id,
        deviceId: data.deviceId,
        token: data.token,
        type: TokenType.EXPO,
      });
    }

    setUserNotificationToken();
  }, [user.id]);

  useEffect(() => {
    if (member) {
      getOrganization(member?.organizationId);
      if (organizationData.active === false) {
        signOut();
      }
    }
  }, [getOrganization, member]);

  return (
    <View style={{ flex: 1 }}>
      <Header screenName="Home" organizationLogo={organizationData?.logoUrl} isHomeScreen />
      {Platform.OS === 'web' && (
        <TouchableOpacity
          style={{
            alignSelf: 'center',
            borderWidth: 1,
            borderRadius: 8,
            borderColor: colors.lightGrey,
            padding: 20,
            maxWidth: 550 - 32,
            width: width - 32,
          }}
          disabled
        >
          <Text style={{ textAlign: 'center' }}>{t('homeScreen.clickHereAndDownloadTheApp')}</Text>
        </TouchableOpacity>
      )}

      <FlatList
        contentContainerStyle={{ paddingBottom: 40 }}
        refreshControl={
          <RefreshControl
            refreshing={isLoading}
            onRefresh={async () => {
              if (member) {
                await getOrganization(member?.organizationId);
                const [reloadMember] = await getMember(
                  user.id,
                  user.currentOrganizationId as string
                );
                await updateMember(reloadMember);
              }
              await getLocations();
              await getTodayEvents();
              await getNotReadNotifications();
            }}
          />
        }
        data={['1']}
        keyExtractor={(item) => item}
        renderItem={() => (
          <>
            <TodayReservations />
            <OfficesList />
          </>
        )}
      />
    </View>
  );
}
