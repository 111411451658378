import { useNavigation } from '@react-navigation/native';
import { images } from '_/assets';
import { Button, Text } from '_/components';
import { colors, fontSizes } from '_/constants/theme';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { getSpaceTypeColor } from '_/services/models/enums/space-type.enum';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, View } from 'react-native';

import styles from './styles';

interface NoAvailableSlotsProps {
  maxCapacityReached: boolean;
  notAllowed?: boolean;
}

export default function NoAvailableSlots({
  maxCapacityReached,
  notAllowed = false,
}: NoAvailableSlotsProps) {
  const navigation = useNavigation<any>();
  const { spaceType } = useSpaceContext();
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Text fontSize={fontSizes.md2} style={[styles.text, styles.topText]}>
        {t(
          `workstationScreen.noAvailableSlots.${
            notAllowed
              ? 'notAllowed'
              : maxCapacityReached
              ? 'maxCapacityReached'
              : 'noAvailableSlotsMessage'
          }`
        )}
      </Text>
      <Image source={images.calendarYellow} />
      <Text fontSize={fontSizes.md2} style={[styles.text, styles.bottomText]}>
        {t(
          `workstationScreen.noAvailableSlots.${
            notAllowed ? 'tryAnotherSpace' : 'tryAnotherSchedule'
          }`
        )}
      </Text>
      <Button
        backgroundColor={getSpaceTypeColor(spaceType)}
        textColor={colors.white}
        onPress={() => navigation.goBack()}
      >
        {t('workstationScreen.noAvailableSlots.backToSchedule')}
      </Button>
    </View>
  );
}
