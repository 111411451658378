import { images } from '_/assets';
import UpdateModal from '_/components/UpdateModal';
import generalConfig from '_/config/general';
import { fontFiles, fontFamily } from '_/constants/theme';
import { AuthProvider } from '_/hooks/AuthContext';
import { LanguageProvider } from '_/hooks/LanguageContext';
import { OrganizationProvider } from '_/hooks/OrganizationContext';
import Navigation from '_/navigation';
import i18n from '_/services/locale';
import logger from '_/services/logger';
import { setNotificationsHandler } from '_/services/notifications';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Platform, View } from 'react-native';
import FlashMessage from 'react-native-flash-message';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import * as Sentry from 'sentry-expo';

SplashScreen.preventAutoHideAsync();

function getSentryIntegrations() {
  if (Platform.OS !== 'web') {
    return [new Sentry.Native.ReactNativeTracing()];
  }
  return [];
}

Sentry.init({
  dsn: generalConfig.sentryDsn,
  enableInExpoDevelopment: false,
  debug: __DEV__,
  tracesSampleRate: 0.3,
  integrations: getSentryIntegrations(),
});

setNotificationsHandler();

export default function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadAsync() {
      try {
        await Asset.loadAsync(Object.values(images));
        await Font.loadAsync(fontFiles);
      } catch (error) {
        logger(error);
      } finally {
        setLoading(false);
      }
    }

    loadAsync();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (!loading) {
      await SplashScreen.hideAsync();
    }
  }, [loading]);

  if (loading) {
    return null;
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
        <I18nextProvider i18n={i18n}>
          <LanguageProvider>
            <AuthProvider>
              <OrganizationProvider>
                <Navigation />
                <FlashMessage
                  floating
                  position="top"
                  duration={5000}
                  titleStyle={{ fontFamily: fontFamily.bold }}
                  textStyle={{ fontFamily: fontFamily.regular }}
                />
                <UpdateModal />
              </OrganizationProvider>
            </AuthProvider>
            <StatusBar style="dark" />
          </LanguageProvider>
        </I18nextProvider>
      </View>
    </GestureHandlerRootView>
  );
}
