import logger from '../logger';
import { LoginResponse } from '../models/auth.model';
import { Lang } from '../models/enums/lang.enum';
import { UserTokensModel } from '../models/user-tokens.model';
import { UsersModel } from '../models/users.model';
import RequestCreator from './helpers/requestCreator';
import { GetListType } from './types/GetList';
import { ReqBase } from './types/ReqQuery';

interface AuthTypes {
  email: string;
  password: string;
  name: string;
  pin: string;
  lang: Lang;
  token: string;
  passwordConfirmation?: string;
}

interface Login {
  email: string;
  password: string;
  strategy: string;
}

export interface GoogleOAuthResponse {
  idToken?: string;
  accessToken: string | null;
}

export enum OAuthType {
  GOOGLE = 'google',
  LOCAL = 'local',
  MICROSOFT = 'microsoft',
}

class User extends RequestCreator<UsersModel> {
  private accountsPath = '/accounts';
  private authPath = '/authentication';
  private usersPath = '/users';
  private uploadAvatarPath = '/uploads/avatars';
  private userTokensPath = '/user-tokens';
  private notificationsPath = '/notifications';
  private refreshPath = '/refresh-tokens';

  signIn = async ({ email, password, strategy }: Login) => {
    try {
      const result = await this.api.post<LoginResponse>(this.authPath, {
        email,
        password,
        strategy,
      });
      const { accessToken } = result.data;

      this.setToken(accessToken);

      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  signInWithGoogle = async ({ accessToken }: GoogleOAuthResponse) => {
    try {
      const { data } = await this.api.post(this.authPath, {
        strategy: OAuthType.GOOGLE,
        access_token: accessToken,
      });
      this.setToken(data.accessToken);
      return data;
    } catch (error) {
      logger(error);
    }
  };

  signInWithMicrosoft = async (accessToken: string) => {
    try {
      const res = await this.api.post(this.authPath, {
        strategy: OAuthType.MICROSOFT,
        access_token: accessToken,
      });
      this.setToken(res.data.accessToken);
      return res.data;
    } catch (error) {
      logger(error);
    }
  };

  signUp = async ({ email, password, name }: Partial<AuthTypes>) => {
    try {
      const result = await this.api.post(this.usersPath, {
        email,
        password,
        name,
      });
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  checkEmail = async ({ email, lang }: Partial<AuthTypes>) => {
    try {
      const result = await this.api.post(this.accountsPath, {
        email,
        action: 'canMakeLogin',
        lang,
      });
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  sendSignUpPin = async ({ email, pin }: Partial<AuthTypes>) => {
    try {
      const result = await this.api.post(this.accountsPath, {
        email,
        pin,
        action: 'validatePinCodeAndGetUser',
      });
      return result;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  updateSignUpUser = async ({
    email,
    token,
    password,
    name,
    lang,
    passwordConfirmation,
  }: Partial<AuthTypes>) => {
    try {
      const result = await this.api.post(this.accountsPath, {
        email,
        token,
        password,
        name,
        lang,
        passwordConfirmation,
        action: 'updateUser',
      });
      return result;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  resetPasswordSend = async ({ email }: Partial<AuthTypes>) => {
    try {
      await this.api.post(this.accountsPath, { email, action: 'resetPasswordSend' });
      return true;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  validatePinCode = async ({ email, pin }: Partial<AuthTypes>) => {
    try {
      const result = await this.api.post(this.accountsPath, {
        email,
        pin,
        action: 'validatePinCodeAndGetUser',
      });
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  resetPassword = async ({ password, token, email }: Partial<AuthTypes>) => {
    try {
      const result = await this.api.post(this.accountsPath, {
        email,
        token,
        password,
        passwordConfirmation: password,
        action: 'resetPassword',
      });
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  uploadAvatar = async (formData: FormData) => {
    const data = await this.api.post(this.uploadAvatarPath, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data;
  };

  getUserTokens = async (deviceId: string) => {
    try {
      const { data } = await this.api.get<GetListType<UserTokensModel[]>>(this.userTokensPath, {
        params: { deviceId },
      });
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  createUserToken = async ({ userId, token, deviceId, type }: Partial<UserTokensModel>) => {
    try {
      const { data } = await this.api.post<UserTokensModel>(this.userTokensPath, {
        userId,
        token,
        deviceId,
        type,
      });
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  deleteUserToken = async (deviceId: string) => {
    try {
      const { data } = await this.api.delete(this.userTokensPath, {
        params: { deviceId },
      });

      return data;
    } catch (error) {
      logger(error);
    }
  };

  getNotifications = async ({ ...rest }: ReqBase<any>) => {
    const { data } = await this.api.get(this.notificationsPath, {
      params: {
        ...rest,
        '$sort[createdAt]': -1,
      },
    });
    return data;
  };
  getNotReadNotifications = async () => {
    const { data } = await this.api.get(this.notificationsPath, {
      params: {
        $select: ['id'],
        readAt: '<null>',
      },
    });
    return data;
  };
  setNotificationsRead = async () => {
    const { data } = await this.api.patch(
      this.notificationsPath,
      {
        readAt: new Date(),
      },
      {
        params: {
          readAt: '<null>',
        },
      }
    );
    return data;
  };

  refreshToken = async (refreshToken: string, userId: string) => {
    const { data } = await this.api.post<any>(this.refreshPath, {
      id: userId,
      refreshToken,
    });
    return data;
  };
}

const api = new User('/users');
export default api;
