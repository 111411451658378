import AsyncStorage from '@react-native-async-storage/async-storage';
import { apiConfig } from '_/config/api';
import auth from '_/config/auth';
import logger from '_/services/logger';
import axios, { AxiosInstance } from 'axios';
import * as Updates from 'expo-updates';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Platform } from 'react-native';

class API {
  protected api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: __DEV__ ? apiConfig.localUrl : apiConfig.apiUrl,
      timeout: apiConfig.apiTimeout,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (__DEV__) {
      this.api.interceptors.request.use((config) => {
        console.log(`${config.method} ${config.url} ${JSON.stringify(config.params || {})}`);
        return config;
      });
    }

    this.api.interceptors.request.use(
      async (config) => {
        const refreshPath = '/refresh-tokens';
        if (config.url !== refreshPath) {
          const userTokenExpiration = await this.getStorageItem(auth.tokenExpirationKey);
          const expiredDate = new Date(+(userTokenExpiration || 0) * 1000);
          const now = new Date();

          if (userTokenExpiration && now > expiredDate) {
            const userId = await this.getStorageItem(auth.userIdKey);
            const refreshToken = await this.getStorageItem(auth.refreshTokenKey);

            if (userId && refreshToken) {
              try {
                const {
                  data: { accessToken },
                } = await this.api.post<{ accessToken: string }>(refreshPath, {
                  id: userId,
                  refreshToken,
                });
                this.setToken(accessToken);
                config.headers.Authorization = `Bearer ${accessToken}`;
              } catch (error) {
                logger(error);
              }
            }
          } else {
            const userToken = await this.getStorageItem(auth.tokenKey);
            if (userToken) {
              config.headers.Authorization = `Bearer ${userToken}`;
            }
          }
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    this.api.interceptors.response.use(
      (response) => {
        return Promise.resolve(response);
      },

      (error) => {
        if (error?.response?.status === 401 && error?.response?.config?.url !== '/authentication') {
          this.removeToken();
          if (Platform.OS === 'web') {
            window.location.reload();
          } else {
            Updates.reloadAsync();
          }
        }

        return Promise.reject(error);
      }
    );
  }

  public setToken = (accessToken: string, refreshToken?: string, userId?: string) => {
    if (Platform.OS === 'web') {
      const token = accessToken.split(' ')[1] || accessToken;
      if (token) {
        const { exp } = jwt_decode<JwtPayload>(token);
        if (exp) {
          this.setStorageItem(auth.tokenExpirationKey, exp.toString());
        }
        if (refreshToken) {
          const refresh = refreshToken.split(' ')[1] || refreshToken;
          this.setStorageItem(auth.refreshTokenKey, refresh);
        }
      }
    } else {
      const { exp } = jwt_decode<JwtPayload>(accessToken);
      this.api.defaults.headers.authorization = `Bearer ${accessToken}`;
      if (exp) {
        this.setStorageItem(auth.tokenExpirationKey, exp.toString());
      }

      if (refreshToken) {
        this.setStorageItem(auth.refreshTokenKey, refreshToken);
      }
    }

    if (userId) {
      this.setStorageItem(auth.userIdKey, userId);
    }
  };

  public removeToken = () => {
    delete this.api.defaults.headers.authorization;
    AsyncStorage.multiRemove([
      auth.tokenKey,
      auth.userKey,
      auth.refreshTokenKey,
      auth.userIdKey,
      auth.tokenExpirationKey,
      auth.memberKey,
    ]);
  };

  private getStorageItem = async (key: string) => {
    return await AsyncStorage.getItem(key);
  };

  private setStorageItem = (key: string, value: string) => {
    AsyncStorage.setItem(key, value);
  };
}

export default API;
