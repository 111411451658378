import { createStackNavigator } from '@react-navigation/stack';
import OrganizationDisabled from '_/screens/OrganizationDisabled';
import React from 'react';

import ProfileStackScreen from './ProfileStackScreen';
import { AppRoute } from './types';
const OrganizationDisabledStack = createStackNavigator();

export default function OrganizationDisabledStackScreen() {
  return (
    <OrganizationDisabledStack.Navigator
      screenOptions={{ animationEnabled: true, headerShown: false }}
    >
      <OrganizationDisabledStack.Screen
        name={AppRoute.ORGANIZATION_DISABLED}
        component={OrganizationDisabled}
      />
      <OrganizationDisabledStack.Screen
        name={AppRoute.PROFILE_STACK_SCREEN}
        component={ProfileStackScreen}
      />
    </OrganizationDisabledStack.Navigator>
  );
}
