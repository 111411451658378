import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Text } from '_/components';
import { colors, fontSizes } from '_/constants/theme';
import { useDateContext } from '_/hooks/DateContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { AppRoute } from '_/navigation/types';
import { spaceTypeName, spaceTypeIcon } from '_/services/models/enums/space-type.enum';
import { SpacesModel } from '_/services/models/spaces.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';

import styles from './styles';

export interface CardProps {
  locationName?: string;
  space: SpacesProps;
}

export interface SpacesProps extends SpacesModel {
  availableSlotsCount: string;
}

export default function Card({ locationName, space }: CardProps) {
  const navigation = useNavigation<any>();
  const { t } = useTranslation();
  const { setSpaceId, setSpaceType, setLayoutMode, setCurrentSpace } = useSpaceContext();

  const { reset } = useDateContext();
  const spaceType = space.type;

  return (
    <TouchableOpacity
      style={styles(spaceType).card}
      onPress={() => {
        reset();
        setSpaceType(space.type);
        setSpaceId(space.id);
        setCurrentSpace(space);
        if (space?.isMapVisible === true) {
          setLayoutMode('map');
        }
        navigation.navigate(AppRoute.DATE_PICKER, {
          availableSlotsCount: space.availableSlotsCount,
          space,
          locationName,
        });
      }}
    >
      <View style={styles().artGroup}>
        <View style={styles(spaceType).artOne} />
        <View style={styles(spaceType).artTwo} />
        <View style={styles(spaceType).artThree} />
      </View>
      <View style={styles().titleContainer}>
        <Feather name={spaceTypeIcon[spaceType] as any} size={24} color={colors.white} />
        <Text style={styles().title} fontSize={18} fontType="bold" color={colors.white}>
          {space.name}
        </Text>
      </View>
      <Text fontSize={fontSizes.md2} color={colors.white}>
        {t(`spaceTypes.${spaceTypeName[spaceType]}`)}
      </Text>
      <Text fontSize={fontSizes.md} color={colors.white}>
        {space.availableSlotsCount}
      </Text>
    </TouchableOpacity>
  );
}
