import { useNavigation } from '@react-navigation/native';
import { Avatar, Button, Header, Input, WebWrapper } from '_/components';
import { colors } from '_/constants/theme';
import { useAuth } from '_/hooks/AuthContext';
import * as ImagePicker from 'expo-image-picker';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';

import styles from './styles';

export default function ProfileSettings() {
  const { user, updateUser, uploadAvatar } = useAuth();
  const navigation = useNavigation<any>();
  // const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [checkNewPassword, setCheckNewPassword] = useState('');
  const [isLoadingPicture, setLoadingPicture] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // function changePassword() {
  //   setConfirmPasswordVisible(!confirmPasswordVisible);
  // }

  const handleUpdateUser = async () => {
    if (newPassword !== checkNewPassword) {
      showMessage({
        message: t('error'),
        description: t('signupScreen.errorMessages.passwordConfirmation'),
        backgroundColor: colors.errorRed,
      });
      return;
    }
    try {
      setLoading(true);
      const data = {
        id: user.id,
        ...(oldPassword ? { oldPassword, newPassword } : {}),
      };
      await updateUser(data);
      showMessage({
        message: t('success'),
        description: t('profileScreen.successMessages.profileUpdated'),
        backgroundColor: colors.successGreen,
      });
      navigation.goBack();
    } catch (error: any) {
      if (error?.response?.data?.message === 'Weak password') {
        showMessage({
          message: t('error'),
          description: t('signupScreen.errorMessages.strongPassword'),
          backgroundColor: colors.errorRed,
        });
      }
      if (error?.response?.data?.message === 'Must provide the old password') {
        showMessage({
          message: t('error'),
          description: t('profileScreen.errorMessages.oldPasswordRequired'),
          backgroundColor: colors.errorRed,
        });
      }
      if (error?.response?.data?.message === 'Old Password does not match') {
        showMessage({
          message: t('error'),
          description: t('profileScreen.errorMessages.wrongPassword'),
          backgroundColor: colors.errorRed,
        });
      }
      showMessage({
        message: t('error'),
        description: t('profileScreen.errorMessages.updateFail'),
        backgroundColor: colors.errorRed,
      });
    }
    setLoading(false);
  };

  const handleChangeProfilePicture = async () => {
    if (Platform.OS === 'web') {
      showMessage({
        message: t('error'),
        description: t('cameraErrors.notAvailablePlataform'),
        backgroundColor: colors.errorRed,
      });
      return;
    }

    const { granted } = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (!granted) {
      showMessage({
        message: t('error'),
        description: t('cameraErrors.noLibraryPermission'),
        backgroundColor: colors.errorRed,
      });
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.3,
    });

    if (!result.cancelled && result.type === 'image') {
      try {
        setLoadingPicture(true);
        const formData = new FormData();

        formData.append('file', {
          type: 'image/jpeg',
          name: `${user.id}.jpg`,
          uri: result.uri,
        } as any);

        const { pictureThumbUrl, pictureUrl } = await uploadAvatar(formData);
        await updateUser({ pictureThumbUrl, pictureUrl });
      } catch (error) {
        showMessage({
          message: t('error'),
          description: t('profileScreen.errorMessages.uploadPictureFail'),
          backgroundColor: colors.errorRed,
        });
      }

      setLoadingPicture(false);
    }
  };

  return (
    <WebWrapper>
      <Header screenName={t('profileScreen.myProfileButton.myProfile')} />
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        enabled
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      >
        <ScrollView>
          <View style={styles.wrapper}>
            <View style={styles.profileAvatarContainer}>
              <TouchableOpacity onPress={handleChangeProfilePicture}>
                <Avatar
                  isEditable
                  isLoading={isLoadingPicture}
                  size={125}
                  borderRadius={15}
                  source={user.pictureUrl}
                />
              </TouchableOpacity>
            </View>
            <Input
              tag={t('fullname')}
              placeholder="Ex: Rafael Schmidt"
              autoCapitalize="words"
              returnKeyType="next"
              autoComplete="name"
              icon="user"
              value={user.name}
              editable={false}
              disabled
            />
            <Input
              tag={t('email')}
              placeholder="Ex: rafael@placehub.io"
              autoCapitalize="none"
              returnKeyType="next"
              autoComplete="email"
              textContentType="emailAddress"
              keyboardType="email-address"
              icon="mail"
              autoCorrect={false}
              value={user.email}
              editable={false}
              disabled
            />
            <Input
              isPassword
              tag="Senha atual"
              placeholder="*********"
              autoCapitalize="none"
              textContentType="password"
              isHidden
              onChangeText={setOldPassword}
            />
            <Input
              isPassword
              tag="Nova senha"
              placeholder="*********"
              autoCapitalize="none"
              textContentType="password"
              isHidden
              onChangeText={setNewPassword}
            />
            <Input
              isPassword
              tag="Confirmar nova senha"
              placeholder="*********"
              autoCapitalize="none"
              textContentType="password"
              isHidden
              onChangeText={setCheckNewPassword}
            />
            {/* <Button textColor={colors.darkBlue} isHidden={!confirmPasswordVisible}>
          Alterar senha
        </Button> */}
            <Button
              loading={loading}
              backgroundColor={colors.successGreen}
              textColor={colors.white}
              onPress={handleUpdateUser}
            >
              {t('update')}
            </Button>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </WebWrapper>
  );
}
