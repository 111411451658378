import general from '_/config/general';
import { usersApi } from '_/services/api';
import logger from '_/services/logger';
import { NotificationContextData, NotificationsModel } from '_/services/models/notifications.model';
import * as Notifications from 'expo-notifications';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppState, AppStateStatus, Platform } from 'react-native';

const NotificationContext = createContext<NotificationContextData>({} as NotificationContextData);

type NotificationType = {
  children?: React.ReactNode;
};

export const NotificationProvider: React.FC<NotificationType> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationsModel[]>([]);
  const [notificationsListPage, setNotificationsListPage] = useState<number>(0);
  const [notificationsListLoading, setNotificationsListLoading] = useState(false);
  const [notReadNotifications, setNotReadNotifications] = useState<number>(0);
  const appState = useRef(AppState.currentState);

  const getNotifications = useCallback(
    async (reset = false) => {
      try {
        setNotificationsListLoading(true);
        const page = reset ? 0 : notificationsListPage + 1;
        setNotificationsListPage(page);
        const result = await usersApi.getNotifications({
          $limit: general.notificationLimit,
          $skip: page * general.notificationLimit,
        });
        if (reset) {
          setNotifications(result.data);
        } else {
          setNotifications((prevNotifications) =>
            prevNotifications
              .concat(result.data)
              .filter((notification) => prevNotifications.indexOf(notification) === -1)
              .sort(
                (a, b) =>
                  Date.parse(a.createdAt as unknown as string) -
                  Date.parse(b.createdAt as unknown as string)
              )
          );
        }
      } catch (error) {
        logger(error);
      }
      setNotificationsListLoading(false);
    },
    [notificationsListPage]
  );

  const getNotReadNotifications = useCallback(async () => {
    try {
      const { data } = await usersApi.getNotReadNotifications();
      setNotReadNotifications(data?.length);
    } catch (error) {
      logger(error);
    }
  }, []);

  const setNotificationsRead = useCallback(async () => {
    try {
      const data = await usersApi.setNotificationsRead();
      return data;
    } catch (error) {
      logger(error);
    }
  }, []);

  const _handleAppStateChange = useCallback(
    (nextAppState: AppStateStatus) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active' &&
        Platform.OS !== 'web'
      ) {
        getNotReadNotifications();
      }

      appState.current = nextAppState;
    },
    [getNotReadNotifications]
  );

  useEffect(() => {
    const subscription = AppState.addEventListener('change', _handleAppStateChange);

    return () => {
      subscription.remove();
    };
  }, [_handleAppStateChange]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      getNotReadNotifications();
    }
  }, [getNotReadNotifications]);

  useEffect(() => {
    Notifications.addNotificationReceivedListener(getNotReadNotifications);
  }, [getNotReadNotifications]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        notReadNotifications,
        notificationsListLoading,
        setNotificationsListLoading,
        getNotifications,
        setNotificationsRead,
        getNotReadNotifications,
        setNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotificationContext(): NotificationContextData {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotificationContext must be used within an NotificationProvider');
  }

  return context;
}
