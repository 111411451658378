import { spacing } from '_/constants/theme';
import { Platform, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: '#fff',
    paddingHorizontal: spacing.md,
    paddingVertical: 60,
  },

  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 21,
    marginBottom: 32,
  },
  flags: {
    width: 30,
    height: 30,
  },
  webStyle: {
    ...(Platform.OS === 'web' && { width: 550, alignSelf: 'center' }),
  },
});

export default styles;
