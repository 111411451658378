import DropdownButton from '../DropdownButton';
import CustomTextFieldModal from '../ConfirmReserveModal/CustomTextFieldModal';
import CustomDropDownFieldValuesModal from '../ConfirmReserveModal/CustomDropDownFieldModal';
import { DropdownFieldOptionsRequest } from '_/services/models/dropdown-field-options.model';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { sortedFields } from './utils';
import {
  CustomModalType,
  CustomModalTypeNoTitle,
  FieldValuesState,
  TCustomSlotType,
} from '../ConfirmReserveModal/interface';
import { View } from 'react-native';

interface IQuestionsField {
  slot: TCustomSlotType;
  fieldValues: FieldValuesState;
  setFieldValues: React.Dispatch<React.SetStateAction<FieldValuesState>>;
  customModalVisible: CustomModalType | undefined;
  setCustomModalVisible: React.Dispatch<React.SetStateAction<CustomModalType | undefined>>;
  memberShowCustomFields: boolean | undefined;
}

const QuestionsField = ({
  slot,
  memberShowCustomFields,
  fieldValues,
  setFieldValues,
  customModalVisible,
  setCustomModalVisible,
}: IQuestionsField) => {
  const { spaceType } = useSpaceContext();

  const handleFieldChange = (
    fieldName: CustomModalTypeNoTitle,
    value: string | DropdownFieldOptionsRequest
  ) => {
    setFieldValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const fieldsMetadata = slot.fieldsMetadata ?? {};
  const fields = sortedFields(fieldsMetadata);

  return (
    <>
      {!!fields?.length && memberShowCustomFields && (
        <>
          {fields.map((field, index) => {
            const fieldLabel = slot[field + 'Label'];
            const fieldValue = fieldValues[field];

            if (field.includes('dropdownField')) {
              const fieldOptions = slot[field + 'Options'];

              return (
                <View key={index}>
                  {!!fieldLabel && (
                    <>
                      <DropdownButton
                        text={(fieldValue as DropdownFieldOptionsRequest)?.value}
                        icon="help-circle"
                        onPress={() => setCustomModalVisible(field)}
                        tag={fieldLabel}
                      />
                      <CustomDropDownFieldValuesModal
                        key={index + fieldLabel}
                        hideDropdown={() => setCustomModalVisible(undefined)}
                        label={fieldLabel}
                        setDropdownField={(value) => handleFieldChange(field, value)}
                        value={fieldValue as DropdownFieldOptionsRequest}
                        options={fieldOptions}
                        visible={customModalVisible === field}
                        spaceType={spaceType}
                      />
                    </>
                  )}
                </View>
              );
            }

            if (field.includes('textField')) {
              return (
                <View key={index}>
                  {!!fieldLabel && (
                    <>
                      <DropdownButton
                        text={fieldValue as string}
                        icon="help-circle"
                        onPress={() => setCustomModalVisible(field)}
                        tag={fieldLabel}
                      />
                      <CustomTextFieldModal
                        key={index + fieldLabel}
                        spaceType={spaceType}
                        hideDropdown={() => setCustomModalVisible(undefined)}
                        label={fieldLabel}
                        setTextFieldValue={(value) => handleFieldChange(field, value)}
                        value={fieldValue as string}
                        visible={customModalVisible === field}
                      />
                    </>
                  )}
                </View>
              );
            }

            return null;
          })}
        </>
      )}
    </>
  );
};

export default QuestionsField;
