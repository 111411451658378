import { useLocationContext } from '_/hooks/LocationContext';
import DropdownButton from '../DropdownButton';
import { useTranslation } from 'react-i18next';
import CustomDropDownFieldValuesModal from '../ConfirmReserveModal/CustomDropDownFieldModal';
import { SpaceType } from '_/services/models/enums/space-type.enum';
import { DropdownFieldOptionsModel } from '_/services/models/dropdown-field-options.model';
import { CustomModalType } from '../ConfirmReserveModal/interface';

interface ICostCentersOptions {
  locationId: string;
  slotCostCenterEnabled: Boolean;
  memberShowCustomFields: Boolean;
  costCenter: any;
  setCostCenter: any;
  customModalVisible: CustomModalType | undefined;
  setCustomModalVisible: React.Dispatch<React.SetStateAction<CustomModalType | undefined>>;
  spaceType: SpaceType;
}

const CostCentersOptions = ({
  locationId,
  slotCostCenterEnabled,
  memberShowCustomFields,
  costCenter,
  setCostCenter,
  customModalVisible,
  setCustomModalVisible,
  spaceType,
}: ICostCentersOptions) => {
  const { getCostCenterByLocationId } = useLocationContext();
  const { t } = useTranslation();
  const costCentersArray = getCostCenterByLocationId(locationId)?.costCenters ?? [];
  const costCenters =
    costCentersArray.map((cc, ind) => ({
      id: `${ind}`,
      value: cc,
    })) ?? [];

  const showCostCenter =
    slotCostCenterEnabled && !!costCentersArray.length && memberShowCustomFields;

  return (
    <>
      {showCostCenter && (
        <DropdownButton
          text={costCenter.value || t('costCenter')}
          icon="dollar-sign"
          tag={costCenter.value && t('costCenter')}
          onPress={() => setCustomModalVisible('costCenter')}
        />
      )}
      {customModalVisible === 'costCenter' && (
        <CustomDropDownFieldValuesModal
          hideDropdown={() => setCustomModalVisible(undefined)}
          label={t('costCenter')}
          setDropdownField={setCostCenter}
          value={costCenter}
          options={costCenters as DropdownFieldOptionsModel[]}
          visible={customModalVisible === 'costCenter'}
          spaceType={spaceType}
        />
      )}
    </>
  );
};
export default CostCentersOptions;
