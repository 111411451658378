import { Feather } from '@expo/vector-icons';
import DropdownButton from '_/components/DropdownButton';
import { DATE_FORMAT } from '_/config/date';
import { colors, fontFamily, fontSizes } from '_/constants/theme';
import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { calendarTheme } from '_/screens/DatePicker/styles';
import { getSpaceTypeColor } from '_/services/models/enums/space-type.enum';
import { SlotsModel } from '_/services/models/slots.model';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, ScrollView, View } from 'react-native';
import { Calendar, DateData } from 'react-native-calendars';
import FlashMessage from 'react-native-flash-message';
import Modal from 'react-native-modal';

import Button from '../../Button';
import Text from '../../Text';
import Title from '../../Title';
import SelectSlotModal from './SelectSlotModal';
import styles, { customAvailableDates, customUnavailableDates, customClosedDates } from './styles';

interface RecurrentReservationModalInterface {
  handleCreateEvent: (createEvent: boolean, slotId?: string) => void;
  setRecurrentReservationModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
  selectedSlot: Omit<SlotsModel, 'amenities'>;
}

export default function RecurrentReservationModal({
  handleCreateEvent,
  setRecurrentReservationModalVisible,
  visible,
  selectedSlot,
}: RecurrentReservationModalInterface) {
  const [markedDates, setMarkedDates] = useState({});
  const [selectSlotModalVisible, setSelectSlotModalVisible] = useState(false);
  const {
    availableDates,
    eventsConflicts,
    closedDates,
    userEventsConflicts,
    loading,
    calendarLoading,
    modalMessage,
  } = useEventContext();

  const { selectedDay, today } = useDateContext();
  const { spaceType } = useSpaceContext();
  const { t } = useTranslation();
  const [slotId, setSlotId] = useState<any | null>();
  const [slot, setSlot] = useState<any | null>();

  const handleCloseModal = useCallback(() => {
    setRecurrentReservationModalVisible(false);
    setSlotId(null);
  }, [setRecurrentReservationModalVisible]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (visible && (slotId || selectedSlot?.id)) {
      timerId = setTimeout(() => {
        handleCreateEvent(false, slotId || selectedSlot?.id);
      }, 250);
    }

    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, slotId]);

  useEffect(() => {
    if (visible) {
      const availableDate = availableDates?.map((item: string) => parseISO(item));
      const available = availableDate?.map((date: Date) => {
        const aux = {
          [format(date, DATE_FORMAT)]: {
            selected: true,
            customStyles: customAvailableDates,
          },
        };
        return aux;
      });

      const conflictEvents = eventsConflicts.concat(userEventsConflicts);
      const hasConflictEvents = conflictEvents?.map((item: string) => parseISO(item));
      const conflict = hasConflictEvents?.map((date: Date) => {
        const aux2 = {
          [format(date, DATE_FORMAT)]: {
            selected: true,
            customStyles: customUnavailableDates,
          },
        };
        return aux2;
      });

      const closedDate = closedDates?.map((item: string) => parseISO(item));
      const closed = closedDate?.map((date: Date) => {
        const aux3 = {
          [format(date, DATE_FORMAT)]: {
            selected: true,
            customStyles: customClosedDates,
          },
        };
        return aux3;
      });

      const markedDates = [...available, ...conflict, ...closed];

      const objDates = Object.values(Object.assign({}, markedDates));
      const marks = objDates.reduce((a, b) => {
        return { ...a, ...b };
      }, {});
      setMarkedDates(marks);
    }
  }, [availableDates, eventsConflicts, userEventsConflicts, closedDates, visible]);

  const handleDayPress = useCallback(
    (day: DateData) => {
      const hasNoConflicts = availableDates.some(
        (item: string) => format(parseISO(item), DATE_FORMAT) === day.dateString
      );
      if (hasNoConflicts) {
        modalMessage?.current?.showMessage({
          message: t('workstationScreen.recurrentReservationModal.messages.noConflicts'),
          backgroundColor: colors.successGreen,
          duration: 3600,
        });
        return;
      }

      const hasUserConflicts = eventsConflicts.some(
        (item: string) => format(parseISO(item), DATE_FORMAT) === day.dateString
      );
      if (hasUserConflicts) {
        modalMessage?.current?.showMessage({
          message: t('workstationScreen.recurrentReservationModal.messages.timeConflicts'),
          backgroundColor: colors.errorRed,
          duration: 3600,
        });
        return;
      }

      const hasTimeConflicts = userEventsConflicts.some(
        (item: string) => format(parseISO(item), DATE_FORMAT) === day.dateString
      );
      if (hasTimeConflicts) {
        modalMessage?.current?.showMessage({
          message: t('workstationScreen.recurrentReservationModal.messages.userConflicts'),
          backgroundColor: colors.errorRed,
          duration: 3600,
        });
        return;
      }

      const hasClosedConflicts = closedDates.some(
        (item: string) => format(parseISO(item), DATE_FORMAT) === day.dateString
      );

      if (hasClosedConflicts) {
        modalMessage?.current?.showMessage({
          message: t('workstationScreen.recurrentReservationModal.messages.closedConflicts'),
          backgroundColor: colors.errorRed,
          duration: 3600,
        });
      }
    },
    [eventsConflicts, userEventsConflicts, availableDates, closedDates, t]
  );

  return (
    <Modal
      animationIn="slideInUp"
      animationOut="slideOutDown"
      backdropOpacity={0.5}
      coverScreen
      hasBackdrop
      isVisible={visible}
      onBackButtonPress={() => setRecurrentReservationModalVisible(false)}
      onBackdropPress={() => setRecurrentReservationModalVisible(false)}
      onSwipeStart={() => setRecurrentReservationModalVisible(false)}
      propagateSwipe
      style={styles.modal}
    >
      <FlashMessage
        floating
        position="top"
        duration={5000}
        titleStyle={{ fontFamily: fontFamily.bold }}
        textStyle={{ fontFamily: fontFamily.regular }}
        ref={modalMessage}
      />
      <ScrollView
        contentContainerStyle={styles.scrollViewContainer}
        showsVerticalScrollIndicator={false}
      >
        <Title
          style={styles.title}
          title={t('workstationScreen.recurrentReservationModal.confirmRecurrentReservation')}
          hasCloseButton
          onPress={() => setRecurrentReservationModalVisible(false)}
        />
        <View style={styles.wrapper}>
          <DropdownButton
            icon="map-pin"
            onPress={() => setSelectSlotModalVisible(true)}
            text={slot?.name || selectedSlot?.name}
          />
        </View>
        <View style={styles.wrapper}>
          <Text fontSize={fontSizes.md2}>
            {t('workstationScreen.recurrentReservationModal.recurrentReservationDescription')}
          </Text>
        </View>
        <View style={styles.calendarBorder}>
          {calendarLoading ? (
            <View style={{ paddingBottom: 12 }}>
              <ActivityIndicator size="large" style={styles.calendarLoading} color={colors.black} />
              <View style={{ backgroundColor: 'clear', zIndex: 2, opacity: 0.2 }}>
                <Calendar
                  theme={calendarTheme(spaceType)}
                  renderArrow={(direction) => (
                    <Feather
                      name={`chevron-${direction}` as any}
                      size={28}
                      color={colors.darkGrey}
                    />
                  )}
                />
              </View>
            </View>
          ) : (
            <View>
              <Calendar
                style={{ borderRadius: 16 }}
                theme={calendarTheme(spaceType)}
                minDate={format(today, DATE_FORMAT)}
                current={parseISO(selectedDay).toISOString()}
                onDayPress={handleDayPress}
                markingType="custom"
                markedDates={markedDates}
                renderArrow={(direction) => (
                  <Feather name={`chevron-${direction}` as any} size={28} color={colors.darkGrey} />
                )}
              />
              <View style={styles.descriptionWrapper}>
                <View style={styles.dotAlign}>
                  <View style={styles.availableDot} />
                  <View>
                    <Text fontSize={fontSizes.md}>
                      {t('workstationScreen.recurrentReservationModal.available')} (
                      {availableDates?.length})
                    </Text>
                  </View>
                </View>
                <View style={styles.dotAlign}>
                  <View style={styles.unavailableDot} />
                  <View>
                    <Text fontSize={fontSizes.md}>
                      {t('workstationScreen.recurrentReservationModal.unavailable')} (
                      {[...eventsConflicts, ...userEventsConflicts]?.length})
                    </Text>
                  </View>
                </View>
              </View>
              {closedDates?.length > 0 && (
                <View style={styles.closedOfficeWrapper}>
                  <View style={styles.dotAlign}>
                    <View style={styles.closedDot} />
                    <View>
                      <Text fontSize={fontSizes.md}>
                        {t('workstationScreen.recurrentReservationModal.closed')} (
                        {closedDates?.length})
                      </Text>
                    </View>
                  </View>
                </View>
              )}
            </View>
          )}
        </View>
        <View style={styles.wrapper}>
          <Button
            style={{ opacity: availableDates?.length === 0 ? 0.2 : 1 }}
            loading={loading}
            backgroundColor={getSpaceTypeColor(spaceType)}
            textColor={colors.white}
            disabled={availableDates?.length === 0}
            onPress={() => handleCreateEvent(true, slotId)}
          >
            {t('workstationScreen.recurrentReservationModal.reserve')}
          </Button>
          <Button onPress={handleCloseModal}>{t('cancel')}</Button>
        </View>
        <SelectSlotModal
          visible={selectSlotModalVisible}
          setSelectSlotModalVisible={setSelectSlotModalVisible}
          setNewSelectedSlot={setSlot}
          setNewSelectedSlotId={setSlotId}
        />
      </ScrollView>
    </Modal>
  );
}
