import { Feather } from '@expo/vector-icons';
import { colors, fontSizes } from '_/constants/theme';
import React, { useMemo, useState } from 'react';
import { TouchableOpacity, View, Modal, SafeAreaView, FlatList } from 'react-native';

import Text from '../Text';
import WebWrapper from '../WebWrapper';
import { ItemProps, SelectProps } from './interfaces';
import styles from './styles';

export default function Select({ text, options, defaultValue, onChangeSelect }: SelectProps) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [valueSelected, setValueSelected] = useState<string | number | null>(null);
  const renderOption = ({ text, value }: ItemProps) => {
    return (
      <TouchableOpacity
        style={[
          styles.optionsContainer,
          { backgroundColor: value === valueSelected ? colors.green : 'transparent' },
        ]}
        onPress={() => {
          onChangeSelect(value);
          setIsVisible(false);
          setValueSelected(value);
        }}
      >
        <Text fontSize={fontSizes.md2} fontType="medium" style={styles.optionTxt}>
          {text}
        </Text>
      </TouchableOpacity>
    );
  };

  const optionDefault: string = useMemo(() => {
    const option = options.find((option) => option.value === defaultValue);
    return option ? option.text : text;
  }, [options, text, defaultValue]);

  return (
    <View style={{ width: '50%' }}>
      <TouchableOpacity style={styles.container} onPress={() => setIsVisible(true)}>
        <Text fontSize={fontSizes.md2} fontType="medium">
          {optionDefault}
        </Text>
      </TouchableOpacity>
      <SafeAreaView>
        <Modal animationType="slide" visible={isVisible} onRequestClose={() => setIsVisible(false)}>
          <WebWrapper>
            <View>
              <TouchableOpacity
                onPress={() => setIsVisible(false)}
                hitSlop={{ top: 8, bottom: 8 }}
                style={{ paddingHorizontal: 8 }}
              >
                <Feather name="arrow-left" size={24} color={colors.black} />
              </TouchableOpacity>
            </View>
            <FlatList
              data={options}
              keyExtractor={(item) => String(item.key)}
              renderItem={({ item }) => renderOption(item)}
            />
          </WebWrapper>
        </Modal>
      </SafeAreaView>
    </View>
  );
}
