import { images } from '_/assets';
import SearchBar from '_/components/SearchBar';
import Text from '_/components/Text';
import Title from '_/components/Title';
import { colors, fontSizes, fontFamily } from '_/constants/theme';
import { useSlotContext } from '_/hooks/SlotContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { SlotsModel } from '_/services/models/slots.model';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, View, Image, ScrollView, TouchableOpacity } from 'react-native';
import FlashMessage from 'react-native-flash-message';
import Modal from 'react-native-modal';

import styles from './styles';

interface SelectSlotModalProps {
  setNewSelectedSlot: (slot?: SlotsModel) => void;
  setNewSelectedSlotId: (slotId: string) => void;
  setSelectSlotModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
}

export default function SelectSlotModal({
  setNewSelectedSlot,
  setNewSelectedSlotId,
  setSelectSlotModalVisible,
  visible,
}: SelectSlotModalProps) {
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const { availableList: slotsAvailable } = useSlotContext();
  const [slots, setSlots] = useState<SlotsModel[]>(slotsAvailable);
  const { spaceType } = useSpaceContext();
  const { t } = useTranslation();
  const modalMessage = useRef<any>(null);

  const handleSelectSlot = (slot: SlotsModel) => {
    setNewSelectedSlot(slot);
    setNewSelectedSlotId(slot.id);
    setSelectSlotModalVisible(false);
  };

  const NoSlotsAvailable = () => {
    return (
      <View style={styles().container}>
        <Text fontSize={fontSizes.md2} style={[styles().text, styles().topText]}>
          {t('workstationScreen.recurrentReservationModal.selectSlotModal.searchSlot')}
        </Text>
        <Image source={images.calendarYellow} />
        <Text fontSize={fontSizes.md2} style={[styles().text, styles().bottomText]}>
          {t('workstationScreen.recurrentReservationModal.selectSlotModal.pleaseTryAgain')}
        </Text>
      </View>
    );
  };

  const SlotItem = (item: SlotsModel) => {
    return (
      <TouchableOpacity style={styles(spaceType).slot} onPress={() => handleSelectSlot(item)}>
        <Text fontSize={18} color={colors.black} fontType="medium" style={styles().slotName}>
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };

  const getSlots = useCallback(
    async (searchValue = '') => {
      const slotsFound = slotsAvailable.filter((item) =>
        item.name.toLowerCase()?.includes(searchValue?.toLowerCase())
      );
      setSlots(slotsFound);
      setSearchValue(searchValue);
    },
    [slotsAvailable]
  );

  // Esse trecho de código talvez seja usado no futuro para fazer a requisição dos slots na API a partir do Search

  // const getSlots = useCallback(
  //   async (spaceId: string) => {
  //     setLoading(true);
  //     try {
  //       await getAvailableList({
  //         limit: general.apiSlotLimitList,
  //         reset: true,
  //         spaceId,
  //         startAtTime: startOfDay(selectedEventDate.start),
  //         endAtTime: endOfDay(selectedEventDate.end),
  //         slotName: searchValue,
  //       });
  //     } catch (error) {
  //       modalMessage?.current?.showMessage({
  //         message: 'Erro!',
  //         description: 'Houve um erro com a pesquisa. Por favor, tente novamente mais tarde',
  //         backgroundColor: colors.errorRed,
  //       });
  //     }
  //     setLoading(false);
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [searchValue, selectedEventDate.end, selectedEventDate.start]
  // );

  return (
    <Modal
      animationIn="slideInUp"
      animationOut="slideOutDown"
      backdropOpacity={0.5}
      coverScreen
      hasBackdrop
      isVisible={visible}
      onBackButtonPress={() => setSelectSlotModalVisible(false)}
      onBackdropPress={() => setSelectSlotModalVisible(false)}
      onSwipeStart={() => setSelectSlotModalVisible(false)}
      propagateSwipe
      style={styles().modal}
    >
      <FlashMessage
        floating
        position="top"
        duration={5000}
        titleStyle={{ fontFamily: fontFamily.bold }}
        textStyle={{ fontFamily: fontFamily.regular }}
        ref={modalMessage}
      />
      <ScrollView
        contentContainerStyle={styles().scrollViewContainer}
        showsVerticalScrollIndicator={false}
      >
        <Title
          title={t('workstationScreen.recurrentReservationModal.selectSlotModal.selectSlot')}
          hasCloseButton
          onPress={() => setSelectSlotModalVisible(false)}
          style={[styles().title, { maxWidth: '90%' }]}
        />
        <View style={styles().wrapper}>
          <SearchBar
            searchValue={searchValue}
            setSearchValue={(searchValue) => getSlots(searchValue)}
            placeholder={t(
              'workstationScreen.recurrentReservationModal.selectSlotModal.searchPlaceholder'
            )}
          />
        </View>
        <FlatList
          ListEmptyComponent={<NoSlotsAvailable />}
          contentContainerStyle={{ paddingBottom: 60 }}
          data={slots}
          keyExtractor={(item) => item.id}
          numColumns={4}
          renderItem={(item) => SlotItem(item.item)}
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={false}
          style={styles().list}
        />
      </ScrollView>
    </Modal>
  );
}
