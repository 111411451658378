import { colors, spacing, fontFamily, alphaColor } from '_/constants/theme';
import { StyleSheet, Dimensions, Platform } from 'react-native';

const windowHeight = Dimensions.get('window').height;
const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  modal: {
    backgroundColor: colors.clear,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    flex: 1,
    width: '100%',
    ...(Platform.OS === 'web' && { maxWidth: 550 }),
    margin: 0,
    marginTop: 80,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    maxHeight: windowHeight,
    paddingTop: 20,
  },
  scrollViewContainer: {
    paddingBottom: 40,
  },
  title: {
    marginTop: 0,
    marginBottom: spacing.lg,
  },
  wrapper: {
    paddingHorizontal: spacing.md,
  },
  calendarBorder: {
    borderWidth: 1,
    margin: 16,
    borderRadius: 16,
    borderColor: colors.lightGrey,
  },
  calendarLoading: {
    zIndex: 3,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  descriptionWrapper: {
    alignSelf: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginVertical: spacing.md,
    width: '100%',
    paddingHorizontal: spacing.xl,
  },
  dotAlign: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  availableDot: {
    width: 11,
    height: 11,
    borderRadius: 6,
    backgroundColor: colors.successGreen,
    alignSelf: 'center',
    margin: spacing.xxs,
  },
  unavailableDot: {
    width: 11,
    height: 11,
    borderRadius: 6,
    backgroundColor: colors.errorRed,
    alignSelf: 'center',
    margin: spacing.xxs,
  },
  closedDot: {
    width: 11,
    height: 11,
    borderRadius: 6,
    backgroundColor: colors.darkGrey,
    alignSelf: 'center',
    margin: spacing.xxs,
  },
  closedOfficeWrapper: {
    marginBottom: spacing.md,
  },
  dropdownStyle: {
    borderTopLeftRadius: 8,
    width: width * 0.9,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    height: 60,
    alignSelf: 'center',
    marginVertical: spacing.md,
  },
  dropdownContainerStyle: {
    height: 'auto',
    width: width * 0.9,
    borderRadius: 8,
    alignSelf: 'center',
    marginVertical: spacing.md,
  },
  textStyle: {
    fontSize: spacing.md,
    textAlign: 'left',
    color: colors.black,
    fontFamily: fontFamily.bold,
  },
});

export const customAvailableDates = {
  container: {
    backgroundColor: colors.successGreen,
    borderRadius: 5,
  },
  text: {
    color: alphaColor(colors.white),
  },
};

export const customUnavailableDates = {
  container: {
    backgroundColor: colors.errorRed,
    borderRadius: 5,
  },
  text: {
    color: alphaColor(colors.white),
  },
};

export const customClosedDates = {
  container: {
    backgroundColor: colors.darkGrey,
    borderRadius: 5,
  },
  text: {
    color: alphaColor(colors.white),
  },
};

export default styles;
