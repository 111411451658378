import { colors, spacing } from '_/constants/theme';
import { Dimensions, StyleSheet } from 'react-native';

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  reservationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: spacing.xs,
    maxWidth: width,
  },
  innerReservationContainer: {
    paddingVertical: 8,
    flexDirection: 'row',
    flex: 1,
  },
  date: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    width: 40,
    minWidth: 40,
    height: 40,
  },
  reservationInfo: {
    maxWidth: width * 0.4,
    paddingRight: spacing.sm,
  },
  locationInfo: {
    marginTop: 3,
  },
  locationName: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dotSeparator: {
    width: 5,
    height: 5,
    borderRadius: 5 / 2,
    backgroundColor: colors.midGrey,
    marginHorizontal: spacing.xxs,
  },
  tagWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default styles;
