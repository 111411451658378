import { colors, spacing } from '_/constants/theme';
import { Dimensions, Platform, StyleSheet } from 'react-native';
import { getBottomSpace } from 'react-native-iphone-x-helper';

const { height } = Dimensions.get('window');

const opacity = 0.75;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...(Platform.OS === 'web' && {
      width: 550,
      alignSelf: 'center',
    }),
  },
  cameraContainer: {
    flex: 1,
    backgroundColor: colors.black,
  },
  titleContainer: {
    marginTop: 12,
    alignItems: 'center',
  },
  title: {
    marginTop: 48,
    marginBottom: 6,
  },
  requestMsg: {
    marginTop: height / 2,
  },
  layerTop: {
    flex: 2,
    backgroundColor: colors.black,
    alignItems: 'center',
    justifyContent: 'center',
    opacity,
  },
  layerCenter: {
    flexDirection: 'row',
  },
  layerLeft: {
    flex: 1,
    backgroundColor: colors.black,
    opacity,
  },
  layerRight: {
    flex: 1,
    backgroundColor: colors.black,
    opacity,
  },
  layerBottom: {
    flex: 2,
    backgroundColor: colors.black,
    opacity,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.black,
    paddingTop: spacing.lg,
    paddingBottom: getBottomSpace() + spacing.lg,
  },
});

export default styles;
