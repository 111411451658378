import { Button, Input, Text, Title } from '_/components';
import { colors, fontSizes } from '_/constants/theme';
import { usersApi } from '_/services/api';
import * as EmailValidator from 'email-validator';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { ForgotPasswordProps } from '..';
import styles from './styles';

export default function SendEmail({
  setNextStep,
  userEmail,
  setUserEmail,
  modalRef,
}: ForgotPasswordProps) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSendEmail = async () => {
    if (!userEmail) {
      modalRef?.current?.showMessage({
        message: t('error'),
        description: t('signinScreen.errorMessages.emailRequired'),
        backgroundColor: colors.errorRed,
      });
      return;
    }
    if (!EmailValidator.validate(userEmail)) {
      modalRef?.current?.showMessage({
        message: t('error'),
        description: t('signupScreen.errorMessages.invalidEmail'),
        backgroundColor: colors.errorRed,
      });
      return;
    }
    try {
      setLoading(true);
      await usersApi.resetPasswordSend({ email: userEmail });
      setNextStep();
    } catch (error) {
      setLoading(false);
      modalRef?.current?.showMessage({
        message: t('error'),
        description: t('signinScreen.errorMessages.emailNotFound'),
        backgroundColor: colors.errorRed,
      });
    }
  };

  return (
    <>
      <Title title={`${t('signinScreen.forgotPassword')}?`} style={styles.title} />
      <View style={styles.wrapper}>
        <Text fontSize={fontSizes.md2} style={styles.text}>
          {t('signinScreen.forgotPasswordModal.forgotPasswordMessage')}
        </Text>
        <Input
          tag={t('email')}
          icon="mail"
          placeholder="email@placehub.io"
          autoCapitalize="none"
          returnKeyType="next"
          autoComplete="email"
          textContentType="emailAddress"
          autoCorrect={false}
          value={userEmail}
          onChangeText={setUserEmail}
        />
        <Button
          loading={loading}
          backgroundColor={colors.lightBlue}
          textColor={colors.white}
          onPress={handleSendEmail}
        >
          {t('signinScreen.forgotPasswordModal.sendRecoveryCode')}
        </Button>
      </View>
    </>
  );
}
