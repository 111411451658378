import { useRoute } from '@react-navigation/native';
import { images } from '_/assets';
import { Button, ArrowBackHeader, Input, DropdownInput } from '_/components';
import { colors } from '_/constants/theme';
import { useAuth } from '_/hooks/AuthContext';
import { usersApi } from '_/services/api';
import { Lang } from '_/services/models/enums/lang.enum';
import { UsersModel } from '_/services/models/users.model';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAvoidingView, Platform, ScrollView, View, Image } from 'react-native';
import { showMessage } from 'react-native-flash-message';

import styles from './styles';

interface ParamsProps {
  token: string;
  user: UsersModel;
  email: string;
  lang: Lang;
}

export default function Signup() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const { signIn } = useAuth();
  const { t } = useTranslation();
  const route = useRoute();
  const params = route.params as ParamsProps;
  const [language, setLanguage] = useState<Lang>(params?.lang);

  const languageOptions = [
    {
      value: Lang.PTBR,
      icon: () => <Image source={images.brazilFlag} style={styles.flags} />,
    },
    {
      value: Lang.EN,
      icon: () => <Image source={images.usFlag} style={styles.flags} />,
    },
  ];

  useEffect(() => {
    if (params?.user) {
      setName(params.user.name);
    }
    setEmail(params.email);
  }, [params.email, params.lang, params.user]);

  const handleSignup = async () => {
    if (!name || !email || !password || !confirmPassword) {
      showMessage({
        message: t('error'),
        description: t('signupScreen.errorMessages.fieldsRequired'),
        backgroundColor: colors.errorRed,
      });
      return;
    }

    if (password !== confirmPassword) {
      showMessage({
        message: t('error'),
        description: t('signupScreen.errorMessages.passwordConfirmation'),
        backgroundColor: colors.errorRed,
      });
      return;
    }
    try {
      setLoading(true);
      if (params?.user) {
        await usersApi.updateSignUpUser({
          email,
          password,
          name,
          lang: language,
          token: params.token,
          passwordConfirmation: confirmPassword,
        });
      } else {
        await usersApi.signUp({ name, email, password });
      }
      await signIn({ email, password });
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.data?.message === 'Weak password') {
        showMessage({
          message: t('error'),
          description: t('signupScreen.errorMessages.strongPassword'),
          backgroundColor: colors.errorRed,
        });
      } else {
        showMessage({
          message: t('error'),
          description: t('signupScreen.errorMessages.signupFail'),
          backgroundColor: colors.errorRed,
        });
      }
    }
  };

  return (
    <ScrollView contentContainerStyle={[styles.webStyle, { paddingBottom: 40 }]}>
      <View style={styles.wrapper}>
        <KeyboardAvoidingView enabled behavior={Platform.OS === 'ios' ? 'position' : undefined}>
          <View style={styles.header}>
            <ArrowBackHeader style={styles.title}>{t('signupScreen.signup')}</ArrowBackHeader>
            {Platform.OS !== 'web' && (
              <DropdownInput
                options={languageOptions}
                setValue={setLanguage}
                value={language}
                style={{ width: 100 }}
              />
            )}
          </View>
          <Input
            tag={t('fullname')}
            placeholder="Rafael Schmidt"
            autoCapitalize="words"
            autoComplete="name"
            icon="user"
            value={name}
            onChangeText={setName}
            editable={!loading}
          />
          <Input
            tag={t('email')}
            placeholder="email@placehub.io"
            autoCapitalize="none"
            returnKeyType="next"
            autoComplete="email"
            textContentType="emailAddress"
            autoCorrect={false}
            icon="mail"
            value={email.trim()}
            onChangeText={setEmail}
            editable={false}
          />
          <Input
            tag={t('password')}
            placeholder="*********"
            autoCapitalize="none"
            textContentType="password"
            isPassword
            value={password}
            onChangeText={setPassword}
            editable={!loading}
          />
          <Input
            tag={t('confirmPassword')}
            placeholder="*********"
            autoCapitalize="none"
            textContentType="password"
            isPassword
            value={confirmPassword}
            onChangeText={setConfirmPassword}
            editable={!loading}
          />
          <Button
            backgroundColor={colors.lightBlue}
            textColor={colors.white}
            onPress={handleSignup}
            loading={loading}
          >
            {t('signupScreen.signup')}
          </Button>
        </KeyboardAvoidingView>
      </View>
    </ScrollView>
  );
}
