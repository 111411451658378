import { Feather } from '@expo/vector-icons';
import { colors, spacing } from '_/constants/theme';
import { EventStatus } from '_/services/models/enums/event-status.enum';
import { NotificationSentType } from '_/services/models/enums/notification-sent-type.enum';
import React from 'react';
import { View, StyleSheet } from 'react-native';

interface NotificationIconProps {
  type?: NotificationSentType;
  status?: EventStatus;
}

export default function NotificationIcon({ status }: NotificationIconProps) {
  return (
    <View
      style={[
        styles.iconContainer,
        {
          backgroundColor: status === EventStatus.REJECTED ? colors.errorRed : colors.successGreen,
        },
      ]}
    >
      <Feather
        name={status === EventStatus.REJECTED ? 'x-circle' : 'check-circle'}
        size={20}
        color={colors.white}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  iconContainer: {
    padding: spacing.xxs,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
