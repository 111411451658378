import { images } from '_/assets';
import React, { useCallback } from 'react';
import { View, Animated, Easing, ActivityIndicator, Platform } from 'react-native';

import Text from '../Text';
import { PHLoadingProps } from './interfaces';
import styles from './styles';

const PHLoading = ({ message, loading, type = 'simple' }: PHLoadingProps) => {
  const Loading = useCallback(() => {
    const spinValue = new Animated.Value(0);

    Animated.loop(
      Animated.timing(spinValue, {
        toValue: 1,
        duration: 3000,
        easing: Easing.linear,
        useNativeDriver: Platform.OS !== 'web',
      })
    ).start();

    const spin = spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg'],
    });
    if (loading) {
      return (
        <View style={styles.container}>
          {!!message && <Text style={styles.message}>{message}</Text>}
          {type === 'logo' && (
            <Animated.Image source={images.minimalLogo} style={{ transform: [{ rotate: spin }] }} />
          )}
          {type === 'simple' && <ActivityIndicator size="large" color="#000" />}
        </View>
      );
    } else {
      return null;
    }
  }, [loading, message, type]);

  return <Loading />;
};

export default PHLoading;
