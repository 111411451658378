import { Feather } from '@expo/vector-icons';
import { Text } from '_/components';
import { colors, fontSizes } from '_/constants/theme';
import { useSlotContext } from '_/hooks/SlotContext';
import React, { useCallback, useState } from 'react';
import {
  FlatList,
  Keyboard,
  ListRenderItem,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

import { styles } from '../styles';

interface LocationSlot {
  id: string;
  name: string;
  location: Record<string, any> | null | undefined;
  user: string | null;
  latitude?: number;
  longitude?: number;
}

interface SearchResultListProps {
  handleAnimateToSlot?: (item?: LocationSlot) => void;
}

const SearchResultList: React.FC<SearchResultListProps> = ({ handleAnimateToSlot }) => {
  const { slotsList, slotSearchValue, setSlotSearchValue } = useSlotContext();
  const [closeSearchList, setCloseSearchList] = useState<boolean>(false);

  const handleSelectOption = useCallback(
    (item?: LocationSlot) => {
      handleAnimateToSlot?.(item);
      setCloseSearchList(true);
      setSlotSearchValue(undefined);
      Keyboard.dismiss();
      setCloseSearchList(false);
    },
    [handleAnimateToSlot, setSlotSearchValue]
  );

  const Backdrop = useCallback(() => {
    return (
      <TouchableWithoutFeedback onPress={() => handleSelectOption()}>
        <View style={styles.backdrop} />
      </TouchableWithoutFeedback>
    );
  }, [handleSelectOption]);

  const renderResults = useCallback<ListRenderItem<LocationSlot>>(
    ({ item }) => (
      <TouchableOpacity
        style={styles.result}
        onPress={() => handleSelectOption(item)}
        activeOpacity={0.95}
      >
        <View style={styles.resultContainer}>
          <Feather name="map-pin" size={24} />
          <Text fontSize={fontSizes.md2} style={styles.text} fontType="bold">
            {item.name}
          </Text>
        </View>
        <View style={styles.resultContainer}>
          {item.user && <Feather name="user" size={24} />}
          <Text
            fontSize={fontSizes.md2}
            style={styles.text}
            fontType="bold"
            color={item.user ? colors.red : colors.successGreen}
            numberOfLines={1}
          >
            {item.user?.split(' ').slice(0, 2).join(' ') || 'Disponível'}
          </Text>
        </View>
      </TouchableOpacity>
    ),
    [handleSelectOption]
  );

  const HandleSearchResultsList = useCallback(() => {
    const arrayToBeSearched: LocationSlot[] = slotsList.map((slot) => {
      return {
        id: Math.random().toString(36).substr(2, 9),
        name: slot.name,
        location: slot.mapLocation,
        user: (slot.events && slot.events[0]?.user?.name) || null,
        latitude: slot.mapLocation?.latitude,
        longitude: slot.mapLocation?.longitude,
      };
    });

    const resultBySlotName = arrayToBeSearched.filter((item) =>
      item.name.toLowerCase()?.includes(slotSearchValue?.toLowerCase() as string)
    );

    const resultByUserName = arrayToBeSearched.filter((item) =>
      item.user?.toLowerCase()?.includes(slotSearchValue?.toLowerCase() as string)
    );

    const result = resultBySlotName.concat(resultByUserName).slice(0, 5);

    if (slotSearchValue === '') {
      return null;
    }

    if (closeSearchList) {
      return null;
    }

    if (result.length > 0) {
      return (
        <FlatList
          data={[...new Set(result)]}
          style={styles.list}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item) => item.id}
          renderItem={renderResults}
          ListFooterComponent={Backdrop}
          keyboardShouldPersistTaps="always"
        />
      );
    }

    return null;
  }, [Backdrop, closeSearchList, renderResults, slotSearchValue, slotsList]);

  return <HandleSearchResultsList />;
};

export default SearchResultList;
