import { createStackNavigator } from '@react-navigation/stack';
import Signin from '_/screens/Signin';
import Signup from '_/screens/Signup';
import React from 'react';

import { AppRoute } from './types';

const LoginStack = createStackNavigator();

export default function LoginStackScreen() {
  return (
    <LoginStack.Navigator screenOptions={{ animationEnabled: true, headerShown: false }}>
      <LoginStack.Screen name={AppRoute.LOGIN} component={Signin} />
      <LoginStack.Screen name={AppRoute.SIGNUP} component={Signup} />
    </LoginStack.Navigator>
  );
}
