import React from 'react';
import { Platform, View, Dimensions } from 'react-native';

import { IWebWrapper } from './interfaces';

const WebWrapper = ({ children, webWrapperStyes }: IWebWrapper) => {
  const { width } = Dimensions.get('window');
  return (
    <>
      {Platform.OS === 'web' ? (
        <View
          style={[
            {
              width,
              alignSelf: 'center',
              maxWidth: 550,
            },
            webWrapperStyes,
          ]}
        >
          {children}
        </View>
      ) : (
        children
      )}
    </>
  );
};

export default WebWrapper;
