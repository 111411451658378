import { useNavigation } from '@react-navigation/native';
import { images } from '_/assets';
import { Button, Text } from '_/components';
import { colors, fontSizes } from '_/constants/theme';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { getSpaceTypeColor } from '_/services/models/enums/space-type.enum';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, View } from 'react-native';

import styles from './styles';

export default function ClosedLocation() {
  const navigation = useNavigation<any>();
  const { spaceType } = useSpaceContext();
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Image source={images.closedLocation} />
      <Text fontSize={fontSizes.lg2} fontType="bold" style={[styles.text, styles.topText]}>
        {t('closedSpace.closedSpaceMessage')}
      </Text>
      <Text fontSize={fontSizes.lg} style={[styles.text, styles.topText]}>
        {t('closedSpace.pickOtherDate')}
      </Text>
      <Button
        backgroundColor={getSpaceTypeColor(spaceType)}
        textColor={colors.white}
        onPress={() => navigation.goBack()}
      >
        {t('closedSpace.goBackToPicker')}
      </Button>
    </View>
  );
}
