import { images } from '_/assets';
import { Text } from '_/components';
import { fontSizes, spacing } from '_/constants/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, StyleSheet, Dimensions } from 'react-native';

export default function NoNotifications() {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Image source={images.denied} style={styles.image} />
      <Text fontSize={fontSizes.lg} style={styles.text}>
        {t('notificationsScreen.noNotificationsMessage')}
      </Text>
    </View>
  );
}

const { height } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    height: height - 90,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    marginBottom: spacing.md,
  },
  text: {
    textAlign: 'center',
    maxWidth: '60%',
  },
});
