import Color from 'color';
import { Dimensions, Platform } from 'react-native';

const WINDOW_WIDTH = Dimensions.get('window').width;
const guidelineBaseWidth = 375;

export function scaleSize(size: number) {
  if (Platform.OS === 'web') {
    return size;
  }
  return (WINDOW_WIDTH / guidelineBaseWidth) * size;
}

export function scaleFont(size: number) {
  return size;
}

export const colors = {
  darkBlue: '#073B4C',
  lightBlue: '#118AB2',
  successGreen: '#06D6A0',
  green: '#0FC395',
  attentionYellow: '#FFD166',
  yellow: '#F2C150',
  darkYellow: '#C19735',
  errorRed: '#EF476F',
  red: '#EB5757',
  pink: '#EB6887',
  orange: '#F2994A',
  lightRed: '#FEF6F8',
  black: '#1F292E',
  darkGrey: '#415058',
  midGrey: '#81888B',
  lightGrey: '#C8CDD0',
  grey: '#A9ABAC',
  white: '#F2F2F3',
  clear: '#ffffff',
  transparent: '#00000000',
};

type ColorKeys = keyof typeof colors;
export type ColorType = typeof colors[ColorKeys];

export function alphaColor(color: string, amount: number = 1): string {
  return Color(color).alpha(amount).hsl().string();
}

export const spacing = {
  /** @const {4} */
  xxxs: scaleSize(4),

  /** @const {6} */
  xxs: scaleSize(6),

  /** @const {8} */
  xs: scaleSize(8),

  /** @const {12} */
  sm: scaleSize(12),

  /** @const {16} */
  md: scaleSize(16),

  /** @const {24} */
  lg: scaleSize(24),

  /** @const {36} */
  xl: scaleSize(36),

  /** @const {48} */
  xxl: scaleSize(48),

  /** @const {60} */
  xxxl: scaleSize(60),
};

export const fontSizes = {
  /** @const {10} */
  xs: scaleFont(10),

  /** @const {12} */
  sm: scaleFont(12),

  /** @const {14} */
  md: scaleFont(14),

  /** @const {16} */
  md2: scaleFont(16),

  /** @const {18} */
  lg: scaleFont(18),

  /** @const {20} */
  lg2: scaleFont(20),

  /** @const {22} */
  xl: scaleFont(22),

  /** @const {24} */
  xl2: scaleFont(24),

  /** @const {32} */
  xxl: scaleFont(32),

  /** @const {42} */
  xxxl: scaleFont(42),
};

export const fontFamily = {
  /** @const {Montserrat-Bold} */
  bold: 'Montserrat-Bold',

  /** @const {Montserrat-Light} */
  light: 'Montserrat-Light',

  /** @const {Montserrat-Medium} */
  medium: 'Montserrat-Medium',

  /** @const {Montserrat-Regular} */
  regular: 'Montserrat-Regular',

  /** @const {Montserrat-SemiBold} */
  semibold: 'Montserrat-SemiBold',
};

export type FontType = keyof typeof fontFamily;

export const fontFiles = {
  [fontFamily.bold]: require('_/assets/fonts/Montserrat-Bold.ttf'),
  [fontFamily.light]: require('_/assets/fonts/Montserrat-Light.ttf'),
  [fontFamily.medium]: require('_/assets/fonts/Montserrat-Medium.ttf'),
  [fontFamily.regular]: require('_/assets/fonts/Montserrat-Regular.ttf'),
  [fontFamily.semibold]: require('_/assets/fonts/Montserrat-SemiBold.ttf'),
};
