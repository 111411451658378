import { spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: spacing.md,
    paddingVertical: spacing.md,
  },
  switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing.sm,
    height: 32,
  },
  upperContainer: {
    marginTop: spacing.md,
  },
});

export default styles;
