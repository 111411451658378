import { colors, spacing } from '_/constants/theme';
import { getSpaceTypeColor, SpaceType } from '_/services/models/enums/space-type.enum';
import { darken } from 'polished';
import { StyleSheet } from 'react-native';

const styles = (spaceType?: SpaceType) =>
  StyleSheet.create({
    card: {
      backgroundColor: getSpaceTypeColor(spaceType),
      padding: 16,
      justifyContent: 'center',
      alignItems: 'flex-start',
      borderRadius: 15,
      marginTop: 8,
      margin: 8,
    },
    artGroup: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '50%',
    },
    artOne: {
      backgroundColor: darken(0.15, getSpaceTypeColor(spaceType)) || colors.darkYellow,
      position: 'absolute',
      width: '100%',
      height: '30%',
      borderBottomLeftRadius: 15,
      borderTopRightRadius: 15,
      opacity: 0.5,
    },
    artTwo: {
      backgroundColor: darken(0.15, getSpaceTypeColor(spaceType)) || colors.darkYellow,
      position: 'absolute',
      right: 0,
      width: '30%',
      height: '50%',
      borderTopRightRadius: 15,
      borderBottomLeftRadius: 15,
      opacity: 0.5,
    },
    artThree: {
      backgroundColor: darken(0.15, getSpaceTypeColor(spaceType)) || colors.darkYellow,
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '70%',
      height: '20%',
      borderTopLeftRadius: 15,
      borderBottomRightRadius: 15,
      opacity: 0.5,
    },
    titleContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    title: {
      marginVertical: 6,
      marginHorizontal: spacing.md,
    },
  });

export default styles;
