import { colors } from '_/constants/theme';

export enum SpaceType {
  CALL = 'CALL',
  MEETING = 'MEETING',
  KITCHEN = 'KITCHEN',
  WORK = 'WORK',
  LOUNGE = 'LOUNGE',
  CAFETERIA = 'CAFETERIA',
  BREAK_ROOM = 'BREAK_ROOM',
  THEATER = 'THEATER',
  FITNESS = 'FITNESS',
  STUDY_ROOM = 'STUDY_ROOM',
  PARKING = 'PARKING',
  INTERVIEW = 'INTERVIEW',
  STUDIO = 'STUDIO',
  LAB = 'LAB',
  CLASSROOM = 'CLASSROOM',
  EVENT = 'EVENT',
  GAME_CENTER = 'GAME_CENTER',
  EQUIPMENT = 'EQUIPMENT',
  VEHICLE = 'VEHICLE',
}

export const spaceTypeName = {
  [SpaceType.WORK]: 'workstation',
  [SpaceType.MEETING]: 'meetingRoom',
  [SpaceType.GAME_CENTER]: 'gameCenter',
  [SpaceType.PARKING]: 'parking',
  [SpaceType.KITCHEN]: 'kitchen',
  [SpaceType.LOUNGE]: 'lounges',
  [SpaceType.BREAK_ROOM]: 'breakRoom',
  [SpaceType.FITNESS]: 'fitness',
  [SpaceType.THEATER]: 'theater',
  [SpaceType.CAFETERIA]: 'cafeteria',
  [SpaceType.STUDY_ROOM]: 'studyRoom',
  [SpaceType.STUDIO]: 'studio',
  [SpaceType.CLASSROOM]: 'classroom',
  [SpaceType.EVENT]: 'event',
  [SpaceType.EQUIPMENT]: 'equipament',
  [SpaceType.VEHICLE]: 'vehicle',
  [SpaceType.CALL]: 'callRoom',
  [SpaceType.INTERVIEW]: 'interview',
  [SpaceType.LAB]: 'lab',
};

export function getSpaceTypeName(spaceType?: SpaceType) {
  if (spaceType && spaceTypeName[spaceType]) {
    return spaceTypeName[spaceType];
  }
  return '';
}

export const spaceTypeColor = {
  [SpaceType.WORK]: colors.yellow,
  [SpaceType.MEETING]: colors.pink,
  [SpaceType.GAME_CENTER]: colors.green,
  [SpaceType.PARKING]: colors.lightBlue,
  [SpaceType.KITCHEN]: colors.lightBlue,
  [SpaceType.LOUNGE]: colors.yellow,
  [SpaceType.BREAK_ROOM]: colors.pink,
  [SpaceType.FITNESS]: colors.yellow,
  [SpaceType.THEATER]: colors.yellow,
  [SpaceType.CAFETERIA]: colors.yellow,
  [SpaceType.STUDY_ROOM]: colors.yellow,
  [SpaceType.STUDIO]: colors.lightBlue,
  [SpaceType.CLASSROOM]: colors.yellow,
  [SpaceType.EVENT]: colors.yellow,
  [SpaceType.EQUIPMENT]: colors.yellow,
  [SpaceType.VEHICLE]: colors.lightBlue,
  [SpaceType.CALL]: colors.lightBlue,
  [SpaceType.INTERVIEW]: colors.yellow,
  [SpaceType.LAB]: colors.yellow,
};

export const spaceTypeIcon = {
  [SpaceType.WORK]: 'monitor',
  [SpaceType.MEETING]: 'message-circle',
  [SpaceType.GAME_CENTER]: 'message-circle',
  [SpaceType.PARKING]: 'message-circle',
  [SpaceType.KITCHEN]: 'message-circle',
  [SpaceType.LOUNGE]: 'message-circle',
  [SpaceType.BREAK_ROOM]: 'message-circle',
  [SpaceType.FITNESS]: 'message-circle',
  [SpaceType.THEATER]: 'message-circle',
  [SpaceType.CAFETERIA]: 'message-circle',
  [SpaceType.STUDY_ROOM]: 'message-circle',
  [SpaceType.STUDIO]: 'message-circle',
  [SpaceType.CLASSROOM]: 'message-circle',
  [SpaceType.EVENT]: 'message-circle',
  [SpaceType.EQUIPMENT]: 'message-circle',
  [SpaceType.VEHICLE]: 'message-circle',
  [SpaceType.CALL]: 'phone-call',
  [SpaceType.INTERVIEW]: 'message-circle',
  [SpaceType.LAB]: 'message-circle',
};

export function getSpaceTypeColor(spaceType?: SpaceType) {
  if (spaceType && spaceTypeColor[spaceType]) {
    return spaceTypeColor[spaceType];
  }
  return colors.yellow;
}

interface SpaceTypeMinuteIntervalType {
  [space: string]: 1 | 2 | 3 | 4 | 5 | 6 | 10 | 12 | 15 | 20 | 30;
}

const spaceTypeMinuteInterval: SpaceTypeMinuteIntervalType = {
  [SpaceType.WORK]: 15,
  [SpaceType.MEETING]: 5,
  [SpaceType.GAME_CENTER]: 15,
  [SpaceType.PARKING]: 15,
  [SpaceType.KITCHEN]: 15,
  [SpaceType.LOUNGE]: 15,
  [SpaceType.BREAK_ROOM]: 5,
  [SpaceType.FITNESS]: 15,
  [SpaceType.THEATER]: 15,
  [SpaceType.CAFETERIA]: 15,
  [SpaceType.STUDY_ROOM]: 5,
  [SpaceType.STUDIO]: 15,
  [SpaceType.CLASSROOM]: 15,
  [SpaceType.EVENT]: 15,
  [SpaceType.EQUIPMENT]: 5,
  [SpaceType.VEHICLE]: 15,
  [SpaceType.CALL]: 5,
  [SpaceType.INTERVIEW]: 5,
  [SpaceType.LAB]: 5,
};

export function getSpaceTypeMinuteInterval(spaceType?: SpaceType) {
  if (spaceType && spaceTypeMinuteInterval[spaceType]) {
    return spaceTypeMinuteInterval[spaceType];
  }
  return 15;
}
