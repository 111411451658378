import { Feather } from '@expo/vector-icons';
import { colors, spacing } from '_/constants/theme';
import React from 'react';
import { View, StyleSheet, Dimensions, Image } from 'react-native';
import Modal from 'react-native-modal';

interface ModalConfirmationProps {
  isVisible: boolean;
  onCloseModal(): void;
  selectedImage: string | undefined;
}

const windowWidth = Dimensions.get('window').width;

export default function ImageModal({
  isVisible,
  onCloseModal,
  selectedImage,
}: ModalConfirmationProps) {
  return (
    <Modal
      animationIn="slideInUp"
      isVisible={isVisible}
      hasBackdrop
      backdropOpacity={0.5}
      onBackdropPress={onCloseModal}
      onBackButtonPress={onCloseModal}
    >
      <View style={styles.centeredView}>
        <View style={styles.modal}>
          <Feather
            name="x"
            size={32}
            color={colors.clear}
            style={styles.closeIcon}
            onPress={onCloseModal}
          />
          {!!selectedImage && (
            <Image source={{ uri: selectedImage }} resizeMode="contain" style={styles.image} />
          )}
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    padding: spacing.md,
  },
  modal: {
    position: 'relative',
    width: windowWidth - 82,
    height: 400,
    borderRadius: 16,
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    zIndex: 1,
    right: spacing.sm,
    top: spacing.xl,
  },
  image: {
    width: windowWidth - 82,
    height: '100%',
    borderRadius: 8,
  },
});
