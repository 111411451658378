import { locationsApi } from '_/services/api';
import * as Location from 'expo-location';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { LocationContextData, LocationState } from '../services/models/locations.model';

const LocationContext = createContext<LocationContextData>({} as LocationContextData);

type LocationType = {
  children?: React.ReactNode;
};
export const LocationProvider: React.FC<LocationType> = ({ children }) => {
  const [locationsData, setLocationsData] = useState<LocationState>({} as LocationState);
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState<boolean>();

  const getLocations = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await locationsApi.getList();
      setLocationsData(result);

      return result.data;
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const requestPermission = useCallback(async () => {
    const { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      setPermission(false);
    } else {
      setPermission(true);
    }
  }, []);

  const getCostCenterByLocationId = useCallback(
    (locationId: string) => {
      const location = locationsData.data.find((location) => location.id === locationId);
      return location?.costCenterGroup;
    },
    [locationsData]
  );

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  return (
    <LocationContext.Provider
      value={{
        locations: locationsData,
        getLocations,
        isLoading,
        requestPermission,
        permission,
        getCostCenterByLocationId,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export function useLocationContext(): LocationContextData {
  const context = useContext(LocationContext);

  if (!context) {
    throw new Error('useLocation must be used within an LocationProvider');
  }

  return context;
}
