import { images } from '_/assets';
import Text from '_/components/Text';
import { fontSizes, spacing } from '_/constants/theme';
import React from 'react';
import { View, Image, StyleSheet } from 'react-native';

export default function NoMap() {
  return (
    <View style={styles.container}>
      <Image source={images.denied} style={styles.image} />
      <Text fontSize={fontSizes.md2} style={styles.text}>
        Por favor, ative o serviço de localização.
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 50,
    alignSelf: 'center',
  },
  image: {
    marginTop: 80,
    marginBottom: spacing.md,
  },
  text: {
    textAlign: 'center',
    maxWidth: '60%',
  },
});
