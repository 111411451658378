import { FieldsMetadata } from '_/services/models/slots.model';
import {
  CustomModalTypeNoTitle,
  FieldValuesState,
  TCustomSlotType,
} from '../ConfirmReserveModal/interface';

const allCustomFields: CustomModalTypeNoTitle[] = [
  'dropdownField1',
  'dropdownField2',
  'textField1',
  'textField2',
];

interface IFieldsToCheck {
  slot: TCustomSlotType;
  fieldValues: FieldValuesState;
  fieldsMetadata: FieldsMetadata;
  showCustomFields: boolean;
}

export const sortedFields = (fieldsMetadata = {} as FieldsMetadata) => {
  const sortedFields = Object.keys(fieldsMetadata).sort(
    (a, b) =>
      (fieldsMetadata[a as keyof FieldsMetadata]?.sort ?? Infinity) -
      (fieldsMetadata[b as keyof FieldsMetadata]?.sort ?? Infinity)
  );

  const defaultFields = allCustomFields.filter((field) => !(field in fieldsMetadata));
  return [...sortedFields, ...defaultFields] as Array<keyof FieldsMetadata>;
};

export const fieldsToCheck = ({
  slot,
  fieldValues,
  fieldsMetadata,
  showCustomFields,
}: IFieldsToCheck) => {
  const fieldsToCheck = [
    {
      label: slot?.dropdownField1Label,
      value: fieldValues?.dropdownField1,
      metadata: fieldsMetadata?.dropdownField1,
    },
    {
      label: slot?.dropdownField2Label,
      value: fieldValues?.dropdownField2,
      metadata: fieldsMetadata?.dropdownField2,
    },
    {
      label: slot?.textField1Label,
      value: fieldValues?.textField1,
      metadata: fieldsMetadata?.textField1,
    },
    {
      label: slot?.textField2Label,
      value: fieldValues?.textField2,
      metadata: fieldsMetadata?.textField2,
    },
  ];

  const showError = fieldsToCheck.some((field) => {
    return !!field.label && !field.value && (field.metadata?.required ?? true) && showCustomFields;
  });

  return showError;
};
