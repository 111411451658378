import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

export default function logger(error: any, name?: string) {
  if (__DEV__) {
    if (error?.response) {
      console.log(error.response, name);
    } else {
      console.log(error, name);
    }
  } else {
    if (Platform.OS === 'web') {
      if (error?.response?.data) {
        Sentry?.Browser?.addBreadcrumb({ data: error?.response?.data });
      }
      Sentry.Browser.captureException(error);
    } else {
      if (error?.response?.data) {
        Sentry?.Native?.addBreadcrumb({ data: error?.response?.data });
      }
      Sentry.Native.captureException(error);
    }
  }
}
