import { colors, fontSizes, spacing } from '_/constants/theme';
import React, { useMemo } from 'react';
import { StyleSheet, View, Image } from 'react-native';

import Text from '../Text';

interface OfficeAvatarProps {
  officeName: string;
  source?: string;
}

export default function OfficeAvatar({ officeName, source }: OfficeAvatarProps) {
  const initials = useMemo(() => {
    const fullName = officeName?.toUpperCase()?.split(' ');
    if (fullName.length === 1) {
      return fullName[0].substring(0, 2);
    } else {
      return (fullName.shift()?.[0] || '') + (fullName.pop()?.[0] || '');
    }
  }, [officeName]);

  if (source) {
    return <Image source={{ uri: source }} style={styles.picture} />;
  }

  return (
    <View style={styles.initials}>
      <Text color={colors.white} fontSize={fontSizes.md} fontType="bold">
        {initials}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  picture: {
    alignSelf: 'center',
    width: 40,
    height: 40,
    marginRight: spacing.sm,
    borderRadius: 4,
    backgroundColor: colors.lightBlue,
  },
  initials: {
    width: 40,
    height: 40,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: spacing.sm,
    borderRadius: 4,
    backgroundColor: colors.lightBlue,
  },
});
