import { colors, fontFamily, spacing } from '_/constants/theme';
import React from 'react';
import { TextInput, StyleSheet, View, TextInputProps, StyleProp, ViewStyle } from 'react-native';

import Text from '../Text';

interface TextAreaProps extends TextInputProps {
  tag?: string;
  constainerStyle?: StyleProp<ViewStyle>;
}

export default function TextArea({ tag, style, constainerStyle, ...rest }: TextAreaProps) {
  return (
    <View style={[styles.inputGroup, constainerStyle]}>
      {!!tag && (
        <Text color={colors.darkGrey} style={styles.tag}>
          {tag}
        </Text>
      )}
      <TextInput style={[styles.textInput, style]} multiline {...rest} />
    </View>
  );
}

const styles = StyleSheet.create({
  textInput: {
    width: '100%',
    fontSize: 14,
    height: 100,
    fontFamily: fontFamily.regular,
    marginTop: spacing.md,
    zIndex: 0,
  },
  inputGroup: {
    borderColor: colors.lightGrey,
    borderWidth: 1,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: spacing.md,
    marginTop: spacing.sm,
  },
  tag: {
    position: 'absolute',
    top: -10,
    left: 10,
    backgroundColor: '#FFF',
    paddingHorizontal: 6,
  },
});
