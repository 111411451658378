import { useNavigation, useRoute } from '@react-navigation/native';
import { Text } from '_/components';
import {
  QRCODE_LOCATION_PREFIX,
  QRCODE_SLOT_PREFIX,
  QRCODE_SPACE_PREFIX,
} from '_/config/qrCodePrefix';
import { colors } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { networkErrorMessage } from '_/helpers/networkError';
import { useEventContext } from '_/hooks/EventContext';
import { useLanguage } from '_/hooks/LanguageContext';
import useFirebaseAnalytics from '_/hooks/useFirebaseAnalytics';
import { eventsApi } from '_/services/api';
import { EventsResponse } from '_/services/models/events.model';
import { BarCodeScanner } from 'expo-barcode-scanner';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import Svg, { Path } from 'react-native-svg';
import QRCodeReaderWeb from 'react-qr-reader';

import styles from './styles';

interface ParamsProps {
  event: EventsResponse;
}

export default function QRCodeScanner() {
  const { getTodayEvents } = useEventContext();
  const [hasPermission, setHasPermission] = useState<any>(null);
  const [scanned, setScanned] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation<any>();
  const route = useRoute();
  const params = route.params as ParamsProps;
  const { event } = params;
  const { analyticsLogEvent } = useFirebaseAnalytics();
  const { t } = useTranslation();
  const { locale } = useLanguage();

  useEffect(() => {
    (async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  async function handleQRCodeScanned(data: string) {
    setLoading(true);
    try {
      const prefix = data.slice(0, data.lastIndexOf('/') + 1);
      switch (prefix) {
        case QRCODE_SLOT_PREFIX: {
          const slotId = data.replace(QRCODE_SLOT_PREFIX, '');

          if (slotId !== event.slotId) {
            showMessage({
              message: t('error'),
              description: t('qrCodeScreen.errorMessages.wrongSlotQrCode'),
              backgroundColor: colors.errorRed,
            });
            return;
          }

          await eventsApi.checkinByQrCode({ id: event.id, slotId });
          break;
        }
        case QRCODE_LOCATION_PREFIX: {
          const locationId = data.replace(QRCODE_LOCATION_PREFIX, '');

          if (locationId !== event.slot?.space?.location?.id) {
            showMessage({
              message: t('error'),
              description: t('qrCodeScreen.errorMessages.wrongSlotQrCode'),
              backgroundColor: colors.errorRed,
            });
            throw new Error();
          }

          await eventsApi.checkinByQrCode({ id: event.id, slotId: event.slotId });
          break;
        }
        case QRCODE_SPACE_PREFIX: {
          const spaceId = data.replace(QRCODE_SPACE_PREFIX, '');

          if (spaceId !== event.slot?.space?.id) {
            showMessage({
              message: t('error'),
              description: t('qrCodeScreen.errorMessages.wrongSlotQrCode'),
              backgroundColor: colors.errorRed,
            });
            throw new Error();
          }

          await eventsApi.checkinByQrCode({ id: event.id, slotId: event.slotId });
          break;
        }
        default:
          showMessage({
            message: t('error'),
            description: t('qrCodeScreen.errorMessages.invalidQrCode'),
            backgroundColor: colors.errorRed,
          });
          return;
      }

      setLoading(false);
      showMessage({
        message: t('success'),
        description: t('qrCodeScreen.successMessages.checkinSuccess'),
        backgroundColor: colors.successGreen,
      });
      analyticsLogEvent({
        name: 'makeCheckin',
        properties: {
          name: 'makeCheckin',
          screen: 'QRCodeScanner',
          purpose: 'Checkin event',
        },
      });

      navigation.goBack();
      await getTodayEvents();
    } catch (error: any) {
      setLoading(false);
      analyticsLogEvent({
        name: 'errorMakeCheckin',
        properties: {
          name: 'errorMakeCheckin',
          screen: 'QRCodeScanner',
          purpose: 'Checkin event',
        },
      });
      networkErrorMessage(error);
      const errorData = error?.response?.data?.data;
      switch (errorData?.type) {
        case 'slotNotAvailable':
          showMessage({
            message: t('error'),
            description: t('qrCodeScreen.errorMessages.slotNotAvailable'),
            backgroundColor: colors.errorRed,
          });
          break;
        case 'SlotAlreadyReserved':
          showMessage({
            message: t('error'),
            description: t('qrCodeScreen.errorMessages.isThereAnotherReservation', {
              endAt: formatDate(new Date(errorData?.currentEventEndAt), 'HH:mm', {
                locale,
              }),
            }),
            backgroundColor: colors.errorRed,
          });
        case 'checkinAfterEnded':
          showMessage({
            message: t('error'),
            description: t('qrCodeScreen.errorMessages.checkinAfterEnded'),
            backgroundColor: colors.errorRed,
          });
          break;
        case 'checkinAfterTolerance':
          showMessage({
            message: t('error'),
            description: t('qrCodeScreen.errorMessages.checkinAfterTolerance', {
              spaceTolerance: errorData?.spaceTolerance,
            }),
            backgroundColor: colors.errorRed,
          });
          break;
        default:
          showMessage({
            message: t('error'),
            description: t('qrCodeScreen.errorMessages.checkinFail'),
            backgroundColor: colors.errorRed,
          });
          break;
      }
    }
  }

  const handleBarCodeScanned = async (data: any) => {
    setScanned(true);
    if (!data) {
      return;
    }
    if (Platform.OS === 'web') {
      handleQRCodeScanned(data);
    } else {
      handleQRCodeScanned(data.data);
    }
  };

  const HandleScanner = useCallback(() => {
    if (Platform.OS === 'web') {
      return (
        <QRCodeReaderWeb
          onError={() => null}
          showViewFinder={false}
          delay={300}
          onScan={handleBarCodeScanned}
          style={StyleSheet.absoluteFillObject}
        />
      );
    }

    return (
      <BarCodeScanner
        type={BarCodeScanner.Constants.Type.back}
        barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
        onBarCodeScanned={scanned || loading ? undefined : handleBarCodeScanned}
        style={StyleSheet.absoluteFillObject}
      />
    );
  }, [loading, scanned]);

  return (
    <View style={styles.container}>
      <View style={styles.cameraContainer}>
        {hasPermission && <HandleScanner />}

        <View style={styles.layerTop}>
          {hasPermission && (
            <View style={styles.titleContainer}>
              <Text center fontType="bold" fontSize={28} color={colors.white} style={styles.title}>
                {t('qrCodeScreen.qrCode')}
              </Text>
              <Text center fontSize={16} color={colors.white}>
                {t('qrCodeScreen.qrCodeMessage')}
              </Text>
            </View>
          )}
          {hasPermission === null && (
            <View style={styles.titleContainer}>
              <Text fontType="bold" center fontSize={28} color={colors.white} style={styles.title}>
                {t('qrCodeScreen.waitingCameraRequest')}
              </Text>
            </View>
          )}
          {hasPermission === false && (
            <View style={styles.titleContainer}>
              <Text fontType="bold" center fontSize={28} color={colors.white} style={styles.title}>
                {t('qrCodeScreen.noAccessToCamera')}
              </Text>
              <Text center fontSize={16} color={colors.white}>
                {t('qrCodeScreen.openConfigToAllow')}
              </Text>
            </View>
          )}
        </View>
        <View style={styles.layerCenter}>
          <View style={styles.layerLeft} />
          <Svg width="280" height="280" viewBox="0 0 280 280" fill="none">
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 0H0V12C0 5.37262 5.37256 0 12 0ZM268 0C274.627 0 280 5.37256 280 12V0H268ZM280 268C280 274.627 274.627 280 268 280H280V268ZM12 280C5.37256 280 0 274.627 0 268V280H12Z"
              fill={colors.black}
              fillOpacity="0.75"
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26 20C22.6863 20 20 22.6863 20 26V50L22.8977 28.0604C23.253 25.37 25.37 23.253 28.0604 22.8977L50 20H26ZM20 254C20 257.314 22.6863 260 26 260H50L28.0604 257.102C25.37 256.747 23.253 254.63 22.8977 251.94L20 230V254ZM254 20C257.314 20 260 22.6863 260 26V50L257.102 28.0604C256.747 25.37 254.63 23.253 251.94 22.8977L230 20H254ZM254 260C257.314 260 260 257.314 260 254V230L257.102 251.94C256.747 254.63 254.63 256.747 251.94 257.102L230 260H254Z"
              fill="white"
              fillOpacity="0.5"
            />
          </Svg>
          <View style={styles.layerRight} />
        </View>
        <View style={styles.layerBottom} />
      </View>
      <TouchableOpacity onPress={navigation.goBack} style={styles.button}>
        <Text center fontSize={18} fontType="bold" color={colors.clear}>
          {t('qrCodeScreen.closeReader')}
        </Text>
      </TouchableOpacity>
    </View>
  );
}
