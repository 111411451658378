import { spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
    marginVertical: 4,
  },
  buttonNameAndInfo: {
    justifyContent: 'space-between',
    marginLeft: spacing.md,
  },
  buttonIcon: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  info: {
    marginTop: spacing.xxxs,
  },
});

export default styles;
