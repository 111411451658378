import WeekView from '@geisweiller/react-native-week-view';
import { Text, EventModal, ConfirmReserveModal } from '_/components';
import { colors, fontSizes } from '_/constants/theme';
import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { useSlotContext } from '_/hooks/SlotContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { RecurrenceType } from '_/services/models/enums/recurrence-type.enum';
import { getSpaceTypeColor } from '_/services/models/enums/space-type.enum';
import { EventsResponse } from '_/services/models/events.model';
import { SlotsModel } from '_/services/models/slots.model';
import { add, endOfDay, parseISO, startOfDay } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';

import { locale } from './addLocale';
import { EventComponentType, ModalProps } from './interfaces';
import styles from './styles';

export default function WeekCalendar({ visible, slot }: ModalProps) {
  const {
    handleChangeSelectedDay,
    selectedDay,
    handleEventDate,
    setSlotWithConflicts,
    checkOpenCloseHourMin,
  } = useDateContext();
  const { isSlotModalVisible, setIsSlotModalVisible } = useSlotContext();

  const [eventModalVisible, setEventModalVisible] = useState<string | undefined>();
  const [recurrenceType, setRecurrenceType] = useState<RecurrenceType>(RecurrenceType.ONCE);
  const [loading, setLoading] = useState(false);
  const { meetingEvents, setRecurrenceStr, getSpaceEvents, setMeetingReservations } =
    useEventContext();

  const { spaceType, spaceId, currentSpace } = useSpaceContext();
  const weekViewRef = useRef<any>(null);

  const { t } = useTranslation();

  const getReservations = useCallback(async () => {
    setLoading(true);
    const data = await getSpaceEvents({
      spaceId,
      startAtTime: startOfDay(parseISO(selectedDay)),
      endAtTime: endOfDay(parseISO(selectedDay)),
    });
    const slotEvents = data.slots.find((s: SlotsModel) => s.id === slot.id)?.events || [];
    setMeetingReservations(slotEvents as EventsResponse[]);
    setLoading(false);
  }, [getSpaceEvents, selectedDay, setMeetingReservations, slot.id, spaceId]);

  useEffect(() => {
    if (visible) {
      getReservations();
      weekViewRef.current?.goToDate(selectedDay, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, visible]);

  const EventComponent = useCallback(
    ({ event }: EventComponentType) => {
      return (
        <>
          <View style={styles.whoReserved}>
            <Text
              style={[
                styles.whoReservedName,
                {
                  color: event.color === getSpaceTypeColor(spaceType) ? colors.white : colors.black,
                },
              ]}
              fontSize={fontSizes.md}
            >
              {event.name}
            </Text>
            <EventModal
              eventModalVisible={eventModalVisible}
              onBackdropPress={() => setEventModalVisible(undefined)}
              onBackButtonPress={() => setEventModalVisible(undefined)}
              onSwipeStart={() => setEventModalVisible(undefined)}
              id={event.id}
              name={event.name}
              source={event.source}
              startDate={event.startDate}
              endDate={event.endDate}
              description={event.description}
            />
          </View>
        </>
      );
    },
    [eventModalVisible, spaceType]
  );

  const handleCloseConfirmationModal = useCallback(() => {
    setIsSlotModalVisible('');
    setSlotWithConflicts([]);
    setRecurrenceType(RecurrenceType.ONCE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSlotWithConflicts]);

  const onEventPress = useCallback((event: any) => {
    setEventModalVisible(event.id);
  }, []);

  const onGridClick = useCallback(
    (_event: Event, selectedStartHour: number, date: Date) => {
      const start = add(date, { hours: selectedStartHour });

      if (checkOpenCloseHourMin(start, add(start, { minutes: 30 }))) {
        showMessage({
          message: t('error'),
          description: t('workstationScreen.errorMessages.checkOpenCloseHourMinError', {
            openAt: currentSpace?.openAtHourMin ?? '00:00',
            closeAt: currentSpace?.closeAtHourMin ?? '23:59',
          }),
          backgroundColor: colors.errorRed,
        });
        return;
      }

      handleEventDate(date, selectedStartHour, meetingEvents);
      setRecurrenceStr('');
      setRecurrenceType(RecurrenceType.ONCE);
      setIsSlotModalVisible(slot.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleEventDate, setRecurrenceStr, meetingEvents]
  );

  const handleSwipeNext = useCallback(() => {
    handleChangeSelectedDay('add');
  }, [handleChangeSelectedDay]);

  const handleSwipePrev = useCallback(() => {
    handleChangeSelectedDay('sub');
  }, [handleChangeSelectedDay]);

  return (
    <View style={{ display: visible ? 'flex' : 'none' }}>
      <View style={styles.container}>
        {loading ? (
          <ActivityIndicator color={colors.black} size={35} style={{ marginTop: 20 }} />
        ) : (
          <View style={styles.container}>
            <WeekView
              events={meetingEvents}
              onEventPress={onEventPress}
              selectedDate={parseISO(selectedDay)}
              showTitle={false}
              headerStyle={{ borderColor: '#fff', display: 'none' }}
              hourTextStyle={styles.hourTextStyle}
              numberOfDays={1}
              EventComponent={EventComponent}
              onGridClick={onGridClick}
              locale={locale}
              headerTextStyle={styles.headerTextStyle}
              showNowLine
              nowLineColor={colors.pink}
              onSwipeNext={handleSwipeNext}
              onSwipePrev={handleSwipePrev}
              ref={weekViewRef}
            />
          </View>
        )}
      </View>

      {isSlotModalVisible === slot.id && (
        <ConfirmReserveModal
          onBackdropPress={handleCloseConfirmationModal}
          onBackButtonPress={handleCloseConfirmationModal}
          onSwipeStart={handleCloseConfirmationModal}
          slot={slot}
          recurrenceType={recurrenceType}
          setRecurrenceType={setRecurrenceType}
        />
      )}
    </View>
  );
}
