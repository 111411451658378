import { colors, spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  separator: {
    borderWidth: 1,
    borderColor: colors.lightGrey,
    opacity: 0.5,
    marginVertical: spacing.xs,
  },
  container: {
    paddingHorizontal: spacing.xs,
  },
});

export default styles;
