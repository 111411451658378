import { alphaColor } from '_/constants/theme';
import { getSpaceTypeColor, SpaceType } from '_/services/models/enums/space-type.enum';
import { Dimensions, StyleSheet } from 'react-native';

const { width } = Dimensions.get('window');

const slotSize = width / 4 - 16;
const webSlotSize = 550 / 4 - 24;

const styles = (spaceType?: SpaceType) =>
  StyleSheet.create({
    slot: {
      width: slotSize,
      height: slotSize,
      maxWidth: webSlotSize,
      maxHeight: webSlotSize,
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 1,
      borderColor: alphaColor(getSpaceTypeColor(spaceType), 0.3),
      borderRadius: 6,
      margin: 6,
    },
    slotName: {
      padding: 1,
      textAlign: 'center',
    },
  });

export default styles;
