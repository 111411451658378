import { useRoute } from '@react-navigation/native';
import { Header, Reservation, Text, WebWrapper } from '_/components';
import { colors, fontSizes } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { weekDays } from '_/helpers/weekDays';
import { useAuth } from '_/hooks/AuthContext';
import { useLanguage } from '_/hooks/LanguageContext';
import { eventsApi } from '_/services/api';
import { EventStatus } from '_/services/models/enums/event-status.enum';
import { getSpaceTypeColor } from '_/services/models/enums/space-type.enum';
import { EventsResponse } from '_/services/models/events.model';
import { parseISO, startOfDay } from 'date-fns';
import { lighten } from 'polished';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, RefreshControl, View } from 'react-native';
import { rrulestr } from 'rrule';

import styles from './styles';

interface RecurringReservationsProps {}

interface RouteParams {
  recurrenceId: string;
  recurrenceString: string;
}

function RecurringReservations(_: RecurringReservationsProps) {
  const [recurringReservations, setRecurringReservations] = useState<EventsResponse[]>([]);
  const { language, locale } = useLanguage();
  const { t } = useTranslation();
  const { recurrenceId, recurrenceString } = useRoute().params as RouteParams;
  const { member } = useAuth();

  async function getRecurringReservations() {
    const { data } = await eventsApi.getList({
      recurrenceId,
      'status[$ne]': EventStatus.CANCELED,
      createdToMemberId: member?.id,
      $limit: 366,
      '$sort[startAt]': 1,
      'startAt[$gte]': startOfDay(new Date()),
    });

    setRecurringReservations(data);
  }

  const selectedDays = useMemo(() => {
    const recurrenceRule = rrulestr(recurrenceString);
    const isDaily = recurrenceRule.options?.byweekday === null;
    const byweekday = recurrenceRule.options.byweekday;

    const selectedWeekdays = weekDays.map((weekDay) => {
      return {
        ...weekDay,
        selected: isDaily || byweekday?.includes(weekDay.id),
      };
    });

    return selectedWeekdays;
  }, [recurrenceString]);

  const lastRecurrenceDay = useMemo(() => {
    const lastDate = recurringReservations[recurringReservations.length - 1]?.startAt;
    if (!lastDate) return '';

    const formattedDay = formatDate(parseISO(lastDate), language.format?.full_date, {
      locale,
    });
    return formattedDay;
  }, [locale, language.format?.full_date, recurringReservations]);

  useEffect(() => {
    getRecurringReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WebWrapper>
      <Header screenName={t('recurrenceScreen.recurrenceDetails')} />
      <View style={styles.wrapper}>
        <View style={styles.tag}>
          <Text color={colors.darkGrey} fontSize={14}>
            {t('recurrenceScreen.recurrenceFrequency')}
          </Text>
        </View>
        <View style={styles.weekDays}>
          {selectedDays.map((day) => {
            return (
              <View
                style={[
                  styles.day,
                  {
                    backgroundColor: day.selected
                      ? lighten(
                          0.15,
                          getSpaceTypeColor(recurringReservations[0]?.slot?.space?.type) ||
                            colors.lightBlue
                        )
                      : colors.white,
                  },
                ]}
                key={day.id}
              >
                <Text fontType="bold" fontSize={fontSizes.xs} color={colors.black}>
                  {t(`weekDays.${day.name}`)}
                </Text>
              </View>
            );
          })}
        </View>
      </View>

      {!!lastRecurrenceDay && (
        <View style={styles.wrapper}>
          <View style={styles.tag}>
            <Text color={colors.darkGrey} fontSize={14}>
              {t('recurrenceScreen.recurrenceFinalDate')}
            </Text>
          </View>
          <View style={styles.lastDay}>
            <Text fontType="bold" fontSize={fontSizes.lg} color={colors.darkGrey}>
              {lastRecurrenceDay}
            </Text>
          </View>
        </View>
      )}

      <FlatList
        style={styles.list}
        contentContainerStyle={{ paddingBottom: 40 }}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={async () => {
              await getRecurringReservations();
            }}
          />
        }
        data={recurringReservations}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => <Reservation event={item} />}
      />
    </WebWrapper>
  );
}

export default RecurringReservations;
