import { useNavigation } from '@react-navigation/native';
import {
  Text,
  Avatar,
  Header,
  Button,
  ModalConfirmation,
  LanguageModal,
  WebWrapper,
} from '_/components';
import { colors, fontSizes, spacing } from '_/constants/theme';
import { useAuth } from '_/hooks/AuthContext';
import { AppRoute } from '_/navigation/types';
import { Role } from '_/services/models/enums/role.enum';
import Constants from 'expo-constants';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Platform, ScrollView, View } from 'react-native';

import ProfileButton from './ProfileButton';
import styles from './styles';

export default function Profile() {
  const navigation = useNavigation<any>();
  const [isVisible, setIsVisible] = useState(false);
  const [languageModalVisible, setLanguageModalVisible] = useState(false);
  const { signOut, user, member } = useAuth();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleCloseModal = useCallback(() => {
    setIsVisible(false);
  }, []);

  const handleConfirmModal = useCallback(() => {
    setLoading(true);
    signOut();
  }, [signOut]);

  const HandleBackground = useCallback(() => {
    return (
      <View style={styles.topSection}>
        {!!user?.pictureUrl && (
          <Image
            source={{ uri: user.pictureUrl }}
            style={styles.topPicture}
            resizeMode="cover"
            blurRadius={30}
          />
        )}
      </View>
    );
  }, [user?.pictureUrl]);

  return (
    <WebWrapper>
      <HandleBackground />
      <Header screenName={t('profileScreen.profile')} textColor={colors.white} />
      <ScrollView showsVerticalScrollIndicator={false} bounces={false}>
        <View style={styles.wrapper}>
          <View style={styles.profileAvatarContainer}>
            <Avatar size={125} borderRadius={15} source={user?.pictureUrl} />
            <Text center fontSize={fontSizes.lg2} fontType="bold" marginTop={spacing.md}>
              {user?.name}
            </Text>
            <Text
              center
              color={colors.midGrey}
              fontSize={fontSizes.md}
              marginTop={spacing.xxs}
              marginBottom={spacing.lg}
            >
              {user?.email}
            </Text>
          </View>

          <ProfileButton
            buttonName={t('profileScreen.myProfileButton.myProfile')}
            buttonInfo={t('profileScreen.myProfileButton.profileMessage')}
            icon="user"
            onPress={() => navigation.navigate(AppRoute.PROFILE_SETTINGS)}
          />

          <ProfileButton
            buttonName={t('profileScreen.myRerservationsButton.myReservations')}
            buttonInfo={t('profileScreen.myRerservationsButton.reservationsMessage')}
            icon="calendar"
            onPress={() => navigation.navigate(AppRoute.MY_RESERVATIONS)}
          />

          <ProfileButton
            buttonName={t('profileScreen.configureNotificationsButton.configureNotifications')}
            buttonInfo={t('profileScreen.configureNotificationsButton.notificationMessages')}
            icon="bell"
            onPress={() => navigation.navigate(AppRoute.NOTIFICATION_SETTINGS)}
          />

          {member?.role === Role.ADMIN && (
            <ProfileButton
              buttonName={t('profileScreen.requestReportButton.requestReport')}
              buttonInfo={t('profileScreen.requestReportButton.reportMessage')}
              icon="file-text"
              onPress={() => navigation.navigate(AppRoute.REQUEST_REPORT)}
            />
          )}

          {Platform.OS !== 'web' && (
            <ProfileButton
              buttonName={t('profileScreen.chooseLanguangeButton.chooseLanguage')}
              buttonInfo={t('profileScreen.chooseLanguangeButton.languageMessage')}
              icon="globe"
              onPress={() => setLanguageModalVisible(true)}
            />
          )}

          <ProfileButton
            buttonName={t('profileScreen.deleteAccount.title')}
            buttonInfo={t('profileScreen.deleteAccount.message')}
            icon="user-x"
            onPress={() => navigation.navigate(AppRoute.DELETE_ACCOUNT)}
          />

          <Button
            loading={loading}
            style={styles.logoutBtn}
            backgroundColor={colors.lightRed}
            textColor={colors.errorRed}
            onPress={() => setIsVisible(true)}
          >
            {t('logout')}
          </Button>

          <Text center color={colors.lightGrey}>
            Version: {Constants.expoConfig?.version}
          </Text>

          <ModalConfirmation
            title={t('logout')}
            description={t('profileScreen.logoutModal.logoutMessage')}
            isVisible={isVisible}
            onCloseModal={handleCloseModal}
            onConfirmModal={handleConfirmModal}
            onCancelButton={handleCloseModal}
          />
          <LanguageModal
            visible={languageModalVisible}
            handleClose={() => setLanguageModalVisible(false)}
          />
        </View>
      </ScrollView>
    </WebWrapper>
  );
}
