import { useNavigation, useNavigationState } from '@react-navigation/native';
import Amenities from '_/components/Amenities';
import Button from '_/components/Button';
import DropdownButton from '_/components/DropdownButton';
import ModalConfirmation from '_/components/ModalConfirmation';
import Text from '_/components/Text';
import TimePicker from '_/components/TimePicker';
import Title from '_/components/Title';
import { TIME_FORMAT, WEEK_DAY_FORMAT } from '_/config/date';
import { colors, fontSizes, spacing } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { useEventContext } from '_/hooks/EventContext';
import { useLanguage } from '_/hooks/LanguageContext';
import { AppRoute } from '_/navigation/types';
import { EventStatus } from '_/services/models/enums/event-status.enum';
import { EventsResponse } from '_/services/models/events.model';
import { parseISO } from 'date-fns';
import { lighten } from 'polished';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, KeyboardAvoidingView, Platform, ScrollView } from 'react-native';
import Modal from 'react-native-modal';

interface ReservationModalProps {
  event: EventsResponse;
  visible: boolean;
  setCancelModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onBackdropPress: () => void;
  onBackButtonPress: () => void;
}

export default function ReservationModal({
  event,
  visible,
  setCancelModalVisible,
  onBackdropPress,
  onBackButtonPress,
}: ReservationModalProps) {
  const { cancelEvent, cancelRecurrenceEvent, loading, setLoading } = useEventContext();
  const { language, locale } = useLanguage();
  const navigation = useNavigation<any>();
  const currentScreen = useNavigationState((state) => state.routes[state.index]?.name);
  const startAtToDateType = Date.parse(event.startAt);
  const endAtToDateType = Date.parse(event.endAt);
  const selectedStartTime = formatDate(startAtToDateType, TIME_FORMAT, { locale });
  const selectedEndTime = formatDate(endAtToDateType, TIME_FORMAT, { locale });
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  const handleCancelEvent = async () => {
    setCancelModalVisible(false);
    await cancelEvent(event.id);
  };

  async function handleCancelEventRecurrences() {
    setCancelModalVisible(false);
    await cancelRecurrenceEvent(event.id, event.recurrenceId);
    navigation.navigate(AppRoute.HOME);
  }

  const handleGoToRecurringReservations = async () => {
    navigation.navigate(AppRoute.RECURRING_RESERVATIONS, {
      recurrenceId: event.recurrenceId,
      recurrenceString: event.recurrenceString,
    });
    setCancelModalVisible(false);
  };

  const onCloseModal = () => {
    setIsVisible(false);
    setLoading(false);
  };

  const onConfirmModal = () => {
    setLoading(true);
    handleCancelEventRecurrences();
  };

  return (
    <KeyboardAvoidingView enabled behavior={Platform.OS === 'ios' ? 'position' : undefined}>
      <ScrollView>
        <Modal
          style={styles.modal}
          isVisible={visible}
          onBackdropPress={onBackdropPress}
          onBackButtonPress={onBackButtonPress}
          coverScreen
          hasBackdrop
          backdropOpacity={0.5}
        >
          <Title
            style={styles.title}
            title={event.slot?.name}
            hasCloseButton
            onPress={onBackButtonPress}
          />
          <Amenities style={styles.amenities} />
          <View style={styles.buttonGroup}>
            <DropdownButton
              icon="user"
              text={t('reservationsScreen.cancelReservationModal.forMe')}
              disabled
            />
            <DropdownButton icon="rotate-cw" text={t('recurrenceTypes.ONCE')} disabled />
          </View>
          <Text fontSize={fontSizes.lg2} fontType="bold" style={styles.text}>
            {formatDate(startAtToDateType, WEEK_DAY_FORMAT, {
              locale,
            })}
            , {formatDate(startAtToDateType, language.format?.day_month, { locale })}
          </Text>
          <TimePicker
            editable={false}
            defaultSelectedStartTime={selectedStartTime}
            defaultSelectedEndTime={selectedEndTime}
            selectedNewTime={{ start: parseISO(event.startAt), end: parseISO(event.endAt) }}
            hiddenError
          />
          <View style={styles.buttonGroup}>
            {event.recurrenceId && currentScreen !== AppRoute.RECURRING_RESERVATIONS && (
              <Button
                backgroundColor={lighten(0.57, colors.lightBlue)}
                textColor={colors.lightBlue}
                onPress={handleGoToRecurringReservations}
              >
                {t('reservationsScreen.cancelReservationModal.seeRecurrence')}
              </Button>
            )}
            {(event.status === EventStatus.PENDING || event.status === EventStatus.CONFIRMED) && (
              <>
                {event.recurrenceId && currentScreen !== AppRoute.RECURRING_RESERVATIONS ? (
                  <>
                    <Button
                      backgroundColor={colors.lightBlue}
                      textColor={colors.white}
                      onPress={() => {
                        onBackdropPress();
                        navigation.navigate(AppRoute.EDIT_EVENT, {
                          event,
                        });
                      }}
                      loading={loading}
                    >
                      {t('editEventScreen.editEvent')}
                    </Button>
                    <Button
                      backgroundColor={colors.lightRed}
                      textColor={colors.errorRed}
                      onPress={() => setIsVisible(true)}
                      loading={loading}
                    >
                      {t('reservationsScreen.cancelReservationModal.cancelRecurrenceButton')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      backgroundColor={colors.lightBlue}
                      textColor={colors.white}
                      onPress={() => {
                        onBackdropPress();
                        navigation.navigate(AppRoute.EDIT_EVENT, {
                          event,
                        });
                      }}
                      loading={loading}
                    >
                      {t('editEventScreen.editEvent')}
                    </Button>
                    <Button
                      backgroundColor={colors.lightRed}
                      textColor={colors.errorRed}
                      onPress={handleCancelEvent}
                      loading={loading}
                    >
                      {t('reservationsScreen.cancelReservationModal.cancelReservationButton')}
                    </Button>
                  </>
                )}
              </>
            )}
            <ModalConfirmation
              title={t('reservationsScreen.cancelReservationModal.cancelRecurrenceButton')}
              description={t('reservationsScreen.cancelReservationModal.cancelRecurrenceMessage')}
              isVisible={isVisible}
              onCloseModal={onCloseModal}
              onConfirmModal={onConfirmModal}
              onCancelButton={onCloseModal}
              disabledButtons={loading}
            />
          </View>
        </Modal>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    width: '100%',
    ...(Platform.OS === 'web' && { maxWidth: 550 }),
    margin: 0,
    marginTop: 80,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
    paddingTop: 32,
    paddingBottom: 32,
  },
  title: {
    marginTop: 0,
    marginBottom: spacing.lg,
  },
  buttonGroup: {
    marginHorizontal: spacing.md,
    marginBottom: 6,
  },
  text: {
    marginHorizontal: spacing.md,
    textTransform: 'capitalize',
    color: colors.darkGrey,
  },
  amenities: {
    paddingHorizontal: spacing.md,
    marginBottom: 24,
  },
});
