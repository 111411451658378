import AsyncStorage from '@react-native-async-storage/async-storage';
import authConfig from '_/config/auth';
import { membersApi, organizationsApi } from '_/services/api';
import logger from '_/services/logger';
import { MembersModel } from '_/services/models/members.model';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import {
  OrganizationContextData,
  OrganizationsModel,
} from '../services/models/organizations.model';
import { useAuth } from './AuthContext';

const OrganizationContext = createContext<OrganizationContextData>({} as OrganizationContextData);

type OrganizationType = {
  children?: React.ReactNode;
};

export const OrganizationProvider: React.FC<OrganizationType> = ({ children }) => {
  const [isLoadingOrganization, setIsLoadingOrganization] = useState<boolean>(false);
  const [organizationData, setOrganizationData] = useState<OrganizationsModel>(
    {} as OrganizationsModel
  );
  const { user } = useAuth();

  const getOrganization = useCallback(async (id: string) => {
    try {
      const result = await organizationsApi.getItem(id);

      setOrganizationData(result);

      return result;
    } catch (error) {
      logger(error);
      throw error;
    } finally {
      setIsLoadingOrganization(false);
    }
  }, []);

  const getMember = useCallback(
    async (userId: string, organizationId?: string): Promise<MembersModel[]> => {
      try {
        const result = await membersApi.getList({
          userId,
          ...(organizationId && {
            organizationId,
          }),
        });

        const { data } = result;
        return data;
      } catch (error) {
        logger(error);
        throw error;
      }
    },
    []
  );

  useEffect(() => {
    async function loadOrganization(): Promise<void> {
      const member = await AsyncStorage.getItem(authConfig.memberKey);
      if (member) {
        const memberObj = JSON.parse(member);
        const organization = await getOrganization(memberObj.organizationId);
        setOrganizationData(organization);
      } else {
        setIsLoadingOrganization(false);
        setOrganizationData({} as OrganizationsModel);
      }
    }
    setIsLoadingOrganization(true);
    loadOrganization();
  }, [getOrganization, user]);

  return (
    <OrganizationContext.Provider
      value={{ getOrganization, getMember, organizationData, isLoadingOrganization }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export function useOrganizationContext(): OrganizationContextData {
  const context = useContext(OrganizationContext);

  if (!context) {
    throw new Error('useOrganization must be used within an OrganizationProvider');
  }

  return context;
}
