import { spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  listWrapper: {
    flex: 1,
  },
  list: {
    paddingVertical: spacing.xs,
    paddingHorizontal: spacing.xs,
    flex: 1,
  },
});

export default styles;
