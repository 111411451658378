import { Feather } from '@expo/vector-icons';
import Title from '_/components/Title';
import { DATE_FORMAT } from '_/config/date';
import { colors } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { useDateContext } from '_/hooks/DateContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { calendarTheme, selectedCustomMark, todayCustomMark } from '_/screens/DatePicker/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, Platform } from 'react-native';
import { Calendar } from 'react-native-calendars';
import Modal from 'react-native-modal';

interface FinalDateCalendarModalProps {
  visible: boolean;
  setFinalDateCalendarModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface WeekDaysTypes {
  id: number;
  name: string;
  selected: boolean;
  value: string;
}
export default function FinalDateCalendarModal({
  visible,
  setFinalDateCalendarModalVisible,
}: FinalDateCalendarModalProps) {
  const { today, selectedFinalDay, setSelectedFinalDay, maxDate } = useDateContext();
  const { spaceType } = useSpaceContext();
  const { t } = useTranslation();
  const [markedDates, setMarkedDates] = useState({});

  useEffect(() => {
    const mark = {
      [formatDate(today, DATE_FORMAT)]: { selected: true, customStyles: todayCustomMark },
      [selectedFinalDay || '']: { selected: true, customStyles: selectedCustomMark },
    };
    setMarkedDates(mark);
    setFinalDateCalendarModalVisible(false);
  }, [selectedFinalDay, setFinalDateCalendarModalVisible, today]);

  return (
    <Modal
      style={styles.modal}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      isVisible={visible}
      coverScreen
      hasBackdrop
      backdropOpacity={0.5}
      onBackdropPress={() => setFinalDateCalendarModalVisible(false)}
      onBackButtonPress={() => setFinalDateCalendarModalVisible(false)}
      onSwipeStart={() => setFinalDateCalendarModalVisible(false)}
      useNativeDriver={Platform.OS !== 'web'}
    >
      <Title
        title={t('workstationScreen.customRecurrenceModal.chooseDate')}
        hasCloseButton
        onPress={() => setFinalDateCalendarModalVisible(false)}
        style={styles.title}
      />
      <Calendar
        theme={calendarTheme(spaceType)}
        minDate={formatDate(today, DATE_FORMAT)}
        maxDate={maxDate?.toISOString()}
        onDayPress={(day) => {
          setSelectedFinalDay(day.dateString);
        }}
        markingType="custom"
        markedDates={markedDates}
        renderArrow={(direction) => (
          <Feather name={`chevron-${direction}` as any} size={28} color={colors.darkGrey} />
        )}
      />
      {/* <View style={styles.wrapper}>
        <Button
          backgroundColor={colors.yellow}
          textColor={colors.white}
          disabled={selectedFinalDay === ''}
        >
          Confirmar
        </Button>
        <Button onPress={() => setFinalDateCalendarModalVisible(false)}>Cancelar</Button>
      </View> */}
    </Modal>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    bottom: 0,
    width,
    margin: 0,
    backgroundColor: '#fff',
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    paddingVertical: 40,
  },
  title: {
    marginBottom: 32,
  },
});
