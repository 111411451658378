import { Avatar, Tag, Text } from '_/components';
import { DATETAB_TIME_FORMAT } from '_/config/date';
import { colors, fontSizes } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { EventStatus } from '_/services/models/enums/event-status.enum';
import { EventsResponse } from '_/services/models/events.model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import styles from './styles';

interface WhoReservedProps {
  event: EventsResponse;
}

export default function WhoReserved({ event }: WhoReservedProps) {
  const { t } = useTranslation();

  return (
    <View style={styles.whoReservedContainer}>
      <View style={styles.userInfo}>
        <Avatar size={40} source={event.user?.pictureThumbUrl} />
        <View style={styles.userArea}>
          <Text fontType="bold" numberOfLines={1} fontSize={fontSizes.md2} style={styles.username}>
            {event.user?.name}
          </Text>
          {event.checkoutAt && (
            <Tag text="check-out" backgroundColor={colors.errorRed} style={styles.checkoutTag} />
          )}
          {event.status === EventStatus.CONFIRMED && (
            <Tag
              text={t('confirmed')}
              backgroundColor={colors.successGreen}
              style={styles.checkoutTag}
            />
          )}
          {event.status === EventStatus.PENDING && (
            <Tag text={t('pending')} backgroundColor={colors.yellow} style={styles.checkoutTag} />
          )}
        </View>
      </View>
      <View style={styles.slotInfo}>
        <Text fontSize={fontSizes.md2} numberOfLines={1} fontType="bold">
          {event.slot!.name}
        </Text>
        <Text fontSize={fontSizes.sm} color={colors.grey}>
          {formatDate(Date.parse(event.checkinAt || event.startAt), DATETAB_TIME_FORMAT)} às{' '}
          {formatDate(Date.parse(event.checkoutAt || event.endAt), DATETAB_TIME_FORMAT)}
        </Text>
      </View>
    </View>
  );
}
