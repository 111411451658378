import { EventDate } from '_/services/models/date.model';
import { add, isAfter, isBefore, isEqual } from 'date-fns';

export function isIntervalInvalidTime({
  date,
  type,
  updateEvent,
}: {
  date: EventDate;
  type?: string;
  updateEvent?: EventDate;
}) {
  const start = add(date.start, {});
  const end = add(date.end, {});
  const now = new Date();
  now.setSeconds(0, 0);

  if (updateEvent?.start) {
    const isStartEqualToUpdateEventStart = isEqual(start, updateEvent.start);
    const isStartBeforeNow = isBefore(start, now);

    if (isStartBeforeNow && isStartEqualToUpdateEventStart) {
      return false;
    } else if (isStartBeforeNow) {
      return true;
    } else {
      return false;
    }
  }

  if (type === 'isBeforeCurrentTime') {
    return isBefore(start, now);
  }

  return isAfter(start, end) || isEqual(start, end);
}
