import { createStackNavigator } from '@react-navigation/stack';
import AppProvider from '_/hooks/AppProvider';
import React from 'react';

import HomeStackScreen from './HomeStackScreen';
import ProfileStackScreen from './ProfileStackScreen';
import { AppRoute } from './types';

const AppStack = createStackNavigator();

export default function AppRoutes() {
  return (
    <AppProvider>
      <AppStack.Navigator screenOptions={{ animationEnabled: true, headerShown: false }}>
        <AppStack.Screen name={AppRoute.HOME_STACK_SCREEN} component={HomeStackScreen} />
        <AppStack.Screen name={AppRoute.PROFILE_STACK_SCREEN} component={ProfileStackScreen} />
      </AppStack.Navigator>
    </AppProvider>
  );
}
