import React, { ReactNode } from 'react';

import { DateProvider } from './DateContext';
import { EventProvider } from './EventContext';
import { LocationProvider } from './LocationContext';
import { NotificationProvider } from './NotificationsContext';
import { ReportsProvider } from './ReportsContext';
import { SlotProvider } from './SlotContext';
import { SpaceProvider } from './SpaceContext';

interface AppProviderProps {
  children: ReactNode;
}

export default function AppProvider({ children }: AppProviderProps) {
  return (
    <NotificationProvider>
      <LocationProvider>
        <SpaceProvider>
          <DateProvider>
            <SlotProvider>
              <ReportsProvider>
                <EventProvider>{children}</EventProvider>
              </ReportsProvider>
            </SlotProvider>
          </DateProvider>
        </SpaceProvider>
      </LocationProvider>
    </NotificationProvider>
  );
}
