import { useNavigation } from '@react-navigation/native';
import { useDateContext } from '_/hooks/DateContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import Button from '../Button';
import styles from './styles';

const FilterContainer: React.FC = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { setTimePickerVisible, timePickerVisible } = useDateContext();

  return (
    <View style={styles.wrapper}>
      <Button
        leftIcon="filter"
        iconSize={16}
        hasBorder
        style={styles.button}
        fontStyle={styles.buttonFont}
        onPress={() => navigation.goBack()}
      >
        {t('workstationScreen.addFilter')}
      </Button>
      <Button
        leftIcon="trash-2"
        iconSize={16}
        hasBorder
        style={[styles.button, { opacity: !timePickerVisible ? 0.3 : 1 }]}
        fontStyle={styles.buttonFont}
        onPress={() => setTimePickerVisible(false)}
        disabled={!timePickerVisible}
      >
        {t('datePickerScreen.removeSchedule')}
      </Button>
    </View>
  );
};

export default FilterContainer;
