import { NotificationIcon, Text } from '_/components';
import { colors, fontSizes, spacing } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { useLanguage } from '_/hooks/LanguageContext';
import { NotificationsModel } from '_/services/models/notifications.model';
import React from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';

interface NotificationProps {
  notification: NotificationsModel;
}

export default function Notification({ notification }: NotificationProps) {
  const { locale } = useLanguage();

  return (
    <View style={styles.container}>
      <NotificationIcon status={notification.event?.status} />
      <View style={styles.textContainer}>
        <Text numberOfLines={2} fontSize={fontSizes.md}>
          {notification.content}
        </Text>
        <Text style={styles.date} color={colors.grey} numberOfLines={1} fontSize={fontSizes.xs}>
          {formatDate(new Date(notification.createdAt), 'PPpp', { locale })}
        </Text>
      </View>
    </View>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: spacing.md,
    alignItems: 'center',
    maxWidth: width * 0.9,
    marginVertical: spacing.sm,
  },
  textContainer: {
    marginLeft: spacing.sm,
  },
  date: {
    marginTop: 4,
  },
});
