import Avatar from '_/components/Avatar';
import Text from '_/components/Text';
import { fontSizes, spacing } from '_/constants/theme';
import { UsersModel } from '_/services/models/users.model';
import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

interface MemberI {
  user: UsersModel;
  setForMember: React.Dispatch<React.SetStateAction<UsersModel | undefined>>;
  hideDropdown: () => void;
}

export default function Member({ user, setForMember, hideDropdown }: MemberI) {
  function handleMemberSelection() {
    setForMember(user);
    hideDropdown();
  }

  return (
    <TouchableOpacity style={styles.whoReservedContainer} onPress={() => handleMemberSelection()}>
      <View style={styles.userInfo}>
        <Avatar size={40} source={user.pictureThumbUrl} />
        <View style={styles.userArea}>
          <Text fontType="bold" numberOfLines={1} fontSize={fontSizes.md2} style={styles.username}>
            {user.name}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  whoReservedContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: spacing.xs,
  },
  userInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  userArea: {
    flex: 1,
    flexDirection: 'column',
  },
  username: {
    maxWidth: '70%',
    marginLeft: spacing.sm,
  },
});
