import { spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 16,
  },
  list: {
    paddingHorizontal: 16,
  },
  seeMore: {
    marginTop: spacing.md,
    marginBottom: spacing.xxs,
  },
  buttonGroup: {
    paddingHorizontal: spacing.md,
    marginBottom: spacing.xxs,
  },
  checkinButton: {
    marginRight: 20,
  },
});

export default styles;
