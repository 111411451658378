import { colors } from '_/constants/theme';
import { useDateContext } from '_/hooks/DateContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import LayoutMap from '_/screens/LayoutMap';
import { getSpaceTypeColor, SpaceType } from '_/services/models/enums/space-type.enum';
import { DisabledSpaceDateType } from '_/services/models/spaces.model';
import { isSameDay, parseISO } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Platform } from 'react-native';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';

import AvailableList from './AvailableList';
import ClosedLocation from './ClosedLocation';
import ReservedList from './ReservedList';
import styles from './styles';

interface StatusTabProps {
  spaceType: SpaceType;
  layoutMode: 'list' | 'map';
}

export default function StatusTab({ spaceType, layoutMode }: StatusTabProps) {
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();
  const { selectedEventDate } = useDateContext();
  const { currentSpace, disabledDateData } = useSpaceContext();
  const FirstRoute = useCallback(() => <AvailableList />, []);

  const SecondRoute = useCallback(() => <ReservedList />, []);
  const renderScene = SceneMap({
    first: FirstRoute,
    second: SecondRoute,
  });

  const checkIfDateIsDisabled = useMemo(() => {
    const disabledDates = disabledDateData.map((item: DisabledSpaceDateType) =>
      parseISO(item.date)
    );
    return disabledDates.some((date) => isSameDay(selectedEventDate.start, date));
  }, [disabledDateData, selectedEventDate.start]);

  const routes = [
    { key: 'first', title: t('workstationScreen.availableSlots') },
    { key: 'second', title: t('workstationScreen.reservedSlots') },
  ];

  const initialLayout = { width: Dimensions.get('window').width };

  const renderTabBar = useCallback(
    (props: any) => (
      <TabBar
        {...props}
        indicatorStyle={styles(spaceType).indicator}
        style={styles().tab}
        labelStyle={styles().label}
        inactiveColor={colors.lightGrey}
        activeColor={getSpaceTypeColor(spaceType)}
        allowFontScaling={false}
      />
    ),
    [spaceType]
  );

  const MapOrTab = useCallback(() => {
    if (checkIfDateIsDisabled) {
      return <ClosedLocation />;
    }

    if (layoutMode === 'map' && currentSpace.mapImageUrl && currentSpace.isMapVisible) {
      return <LayoutMap />;
    }

    if (Platform.OS === 'web' || layoutMode === 'list') {
      return (
        <TabView
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={setIndex}
          initialLayout={initialLayout}
          renderTabBar={renderTabBar}
        />
      );
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentSpace.mapImageUrl,
    layoutMode,
    currentSpace.isMapVisible,
    currentSpace.locationId,
    checkIfDateIsDisabled,
  ]);

  return <MapOrTab />;
}
