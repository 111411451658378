import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Tag, Text } from '_/components';
import { colors, fontSizes, spacing } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { useLanguage } from '_/hooks/LanguageContext';
import { AppRoute } from '_/navigation/types';
import { reportPriorityColor } from '_/services/models/enums/report-priority.enum';
import { ReportsModel } from '_/services/models/reports.model';
import { parseISO } from 'date-fns';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native';

interface ReportProps {
  report: ReportsModel;
}
export default function Report({ report }: ReportProps) {
  const navigation = useNavigation<any>();
  const { language, locale } = useLanguage();
  const { t } = useTranslation();

  const HandleImage = useCallback(() => {
    if (!!report.pictureUrls && report?.pictureUrls?.length > 0) {
      return (
        <View>
          <View style={styles.firstOverlay}>
            <Text style={styles.numberOfImages} color={colors.white} fontSize={fontSizes.lg}>
              {report.pictureUrls?.length > 1 ? `+${report?.pictureUrls?.length - 1}` : null}
            </Text>
          </View>
          <View style={styles.secondOverlay} />
          <Image source={{ uri: report.pictureUrls[0] }} style={styles.noImage} />
        </View>
      );
    } else {
      return (
        <View style={styles.noImage}>
          <Feather name="image" color={colors.lightGrey} size={18} />
        </View>
      );
    }
  }, [report.pictureUrls]);

  return (
    <TouchableOpacity
      style={styles.problemContainer}
      onPress={() =>
        navigation.navigate(AppRoute.CREATE_REPORT, {
          report,
        })
      }
    >
      <View style={styles.topWrapper}>
        <Tag
          text={t(`reportsScreen.${report.priority}`)}
          backgroundColor={reportPriorityColor[report.priority]}
          style={styles.tag}
        />
        <Text fontSize={fontSizes.md2} fontType="bold" style={styles.title}>
          {t(`reportsScreen.${report.type}`)}
        </Text>
      </View>
      <View style={styles.lowerWrapper}>
        <HandleImage />
        <View style={{ justifyContent: 'center', width: '90%' }}>
          <View style={styles.infoContainer}>
            <Text fontSize={fontSizes.sm} color={colors.grey}>
              {report.location?.name}
            </Text>
            <View style={styles.dotSeparator} />
            <Text fontSize={fontSizes.sm} color={colors.grey}>
              {formatDate(
                parseISO(report.createdAt as unknown as string),
                language.format?.full_date_short,
                {
                  locale,
                }
              )}
            </Text>
          </View>
          <Text fontSize={fontSizes.sm} style={styles.description} numberOfLines={2}>
            {report.description}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  problemContainer: {
    paddingHorizontal: spacing.md,
    marginTop: spacing.md,
    justifyContent: 'space-between',
  },
  description: {
    maxWidth: '85%',
    textAlign: 'justify',
    marginLeft: spacing.md,
  },
  infoContainer: {
    flexDirection: 'row',
    marginBottom: spacing.xxxs,
    marginLeft: spacing.md,
  },
  dotSeparator: {
    marginTop: 5,
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: colors.grey,
    marginHorizontal: spacing.xxs,
  },
  tag: {
    width: 70,
  },
  title: {
    marginLeft: spacing.xs,
  },
  topWrapper: {
    flexDirection: 'row',
    marginRight: spacing.md,
    marginVertical: spacing.xxs,
  },
  lowerWrapper: {
    marginRight: spacing.md,
    flexDirection: 'row',
  },
  noImage: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 70,
    width: 70,
    backgroundColor: colors.darkGrey,
    borderRadius: 10,
    zIndex: -1,
  },
  firstOverlay: {
    height: 70,
    width: 70,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  secondOverlay: {
    height: 70,
    width: 70,
    position: 'absolute',
    backgroundColor: colors.black,
    borderRadius: 10,
    opacity: 0.4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  numberOfImages: {
    zIndex: 2,
  },
});
