import { Button, Header, Text } from '_/components';
import { Checkbox } from '_/components/Checkbox';
import generalConfig from '_/config/general';
import { colors } from '_/constants/theme';
import { useAuth } from '_/hooks/AuthContext';
import { usersApi } from '_/services/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';

export const DeleteAccount = () => {
  const { t } = useTranslation();
  const { user, signOut } = useAuth();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const buttonDisabled = loading || !selected;

  const handleDelete = async () => {
    setLoading(true);
    try {
      await usersApi.delete(user.id);
      signOut();
    } catch (error) {
      setLoading(false);
      showMessage({
        message: t('error'),
        description: t('deleteAccount.tryAgain'),
        backgroundColor: colors.errorRed,
      });
    }
  };

  return (
    <>
      <Header screenName={t('deleteAccount.header')} />

      <View style={styles.content}>
        <Text fontSize={24}>{t('deleteAccount.title')}</Text>
        <Text style={styles.message}>{t('deleteAccount.message')}</Text>

        <View style={styles.emptySpace} />

        <Checkbox
          color={colors.red}
          label={t('deleteAccount.confirmation')}
          selected={selected}
          onPress={() => setSelected(!selected)}
        />

        <Button
          loading={loading}
          style={styles.button}
          backgroundColor={buttonDisabled ? colors.midGrey : colors.red}
          textColor={colors.white}
          onPress={handleDelete}
          disabled={buttonDisabled}
        >
          {t('deleteAccount.button')}
        </Button>
        <Text style={styles.footer}>
          {t('deleteAccount.footer', { email: generalConfig.email })}
        </Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 16,
    paddingVertical: 32,
    flex: 1,
  },
  message: {
    marginTop: 16,
  },
  emptySpace: {
    flex: 1,
  },
  button: {
    marginVertical: 16,
  },
  footer: {
    color: colors.grey,
  },
});
