import { useNavigation, useNavigationState, useRoute } from '@react-navigation/native';
import Text from '_/components/Text';
import { DAY_FORMAT, MONTH_FORMAT, TIME_FORMAT2 } from '_/config/date';
import { colors, fontSizes, spacing } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { useLanguage } from '_/hooks/LanguageContext';
import { useOrganizationContext } from '_/hooks/OrganizationContext';
import { AppRoute } from '_/navigation/types';
import { CheckinType } from '_/services/models/enums/checkin-type.enum';
import { EventStatus } from '_/services/models/enums/event-status.enum';
import { EventsResponse } from '_/services/models/events.model';
import { endOfDay, isBefore, isWithinInterval, parseISO, startOfDay } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TouchableOpacity, View } from 'react-native';

import Tag from '../Tag';
import CheckButton from './CheckButton';
import CheckModal from './CheckModal';
import MapModal from './CheckModal/MapModal';
import ReservationModal from './ReservationModal';
import styles from './styles';

interface ReservationProps {
  event: EventsResponse;
}

export default function Reservation({ event }: ReservationProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [mapModalVisible, setMapModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const { organizationData } = useOrganizationContext();
  const navigation = useNavigation<any>();
  const route = useRoute();
  const { locale } = useLanguage();
  const currentScreen = useNavigationState((state) => state.routes[state.index].name);
  const { t } = useTranslation();

  const isPast = useMemo(() => {
    if (event.checkoutAt !== null) {
      return true;
    } else {
      const today = startOfDay(new Date());
      return isBefore(parseISO(event.startAt), today);
    }
  }, [event]);

  const handleButtonModal = () => {
    if (!event.checkinAt) {
      if (Platform.OS === 'web') {
        navigation.navigate(AppRoute.QR_CODE_SCANNER, { event });
        return;
      }
      if (organizationData.checkinType === CheckinType.QR_CODE) {
        navigation.navigate(AppRoute.QR_CODE_SCANNER, { event });
        return;
      }
      if (organizationData.checkinType === CheckinType.BOTH) {
        setModalVisible(true);
        return;
      }
      if (organizationData.checkinType === CheckinType.LOCATION) {
        setMapModalVisible(true);
      }
    } else {
      setModalVisible(true);
    }
  };

  const CheckInButton = useCallback(() => {
    if (
      event.status === EventStatus.PENDING ||
      currentScreen === AppRoute.RECURRING_RESERVATIONS ||
      event.status === EventStatus.REJECTED ||
      currentScreen !== AppRoute.HOME
    ) {
      return null;
    }

    if (isPast) {
      return null;
    }
    const isToday = isWithinInterval(parseISO(event.startAt), {
      start: startOfDay(new Date()),
      end: endOfDay(new Date()),
    });
    if (isToday) {
      return <CheckButton checkinAt={event.checkinAt} onPress={handleButtonModal} />;
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScreen, event, isPast, navigation]);

  return (
    <View style={styles.reservationContainer}>
      <TouchableOpacity
        hitSlop={{ top: 4, bottom: 4 }}
        onPress={() => setCancelModalVisible(true)}
        style={styles.innerReservationContainer}
        disabled={isPast}
      >
        <View style={styles.date}>
          <Text
            fontSize={fontSizes.md2}
            fontType="bold"
            color={
              isPast
                ? colors.lightGrey
                : event.status === EventStatus.PENDING
                ? colors.yellow
                : colors.successGreen
            }
          >
            {formatDate(Date.parse(event.startAt), DAY_FORMAT, { locale })}
          </Text>
          <Text
            style={{ lineHeight: fontSizes.md2, textTransform: 'uppercase' }}
            fontSize={fontSizes.md2}
            fontType="bold"
            color={
              isPast
                ? colors.lightGrey
                : event.status === EventStatus.PENDING
                ? colors.yellow
                : colors.successGreen
            }
          >
            {formatDate(Date.parse(event.startAt), MONTH_FORMAT, { locale })}
          </Text>
        </View>
        <View style={styles.reservationInfo}>
          <View style={styles.tagWrapper}>
            <Text
              fontSize={fontSizes.md2}
              fontType="bold"
              style={{ marginRight: spacing.md }}
              numberOfLines={1}
            >
              {event.slot?.name}
            </Text>
            {event.recurrenceId !== null && currentScreen === AppRoute.MY_RESERVATIONS && (
              <Tag backgroundColor={colors.lightBlue} text={t('recurring')} />
            )}
            {event.status === EventStatus.CONFIRMED &&
              route.name === AppRoute.MY_RESERVATIONS &&
              !isPast && <Tag backgroundColor={colors.successGreen} text={t('confirmed')} />}
            {event.status === EventStatus.PENDING && !isPast && (
              <Tag backgroundColor={colors.yellow} text={t('pending')} />
            )}
            {event.status === EventStatus.REJECTED && !isPast && (
              <Tag backgroundColor={colors.errorRed} text={t('rejected')} />
            )}
          </View>
          <View style={styles.locationInfo}>
            <View style={styles.locationName}>
              <Text numberOfLines={1} color={colors.midGrey} fontSize={fontSizes.sm}>
                {formatDate(parseISO(event.startAt), TIME_FORMAT2)} -{' '}
                {formatDate(parseISO(event.endAt), TIME_FORMAT2)}
              </Text>
              <View style={styles.dotSeparator} />
              <Text
                numberOfLines={1}
                color={colors.midGrey}
                fontSize={fontSizes.sm}
                style={{ maxWidth: '80%' }}
              >
                {event.slot?.space?.location?.name}
              </Text>
            </View>
            {event.description && (
              <View>
                <Text numberOfLines={1} color={colors.midGrey} fontSize={fontSizes.sm}>
                  {event.description}
                </Text>
              </View>
            )}
          </View>
        </View>
        <ReservationModal
          visible={cancelModalVisible}
          onBackButtonPress={() => setCancelModalVisible(false)}
          onBackdropPress={() => setCancelModalVisible(false)}
          setCancelModalVisible={setCancelModalVisible}
          event={event}
        />
      </TouchableOpacity>
      <CheckInButton />
      <CheckModal
        visible={modalVisible}
        event={event}
        id={event.id}
        checkinAt={event.checkinAt}
        onBackButtonPress={() => setModalVisible(false)}
        onBackdropPress={() => setModalVisible(false)}
        setModalVisible={setModalVisible}
        setMapModalVisible={setMapModalVisible}
        mapModalVisible={mapModalVisible}
      />
      <MapModal
        visible={mapModalVisible}
        onBackButtonPress={() => setMapModalVisible(false)}
        onBackdropPress={() => setMapModalVisible(false)}
        setMapModalVisible={setMapModalVisible}
        setModalVisible={setModalVisible}
        event={event}
      />
    </View>
  );
}
