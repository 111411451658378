import { useNavigation } from '@react-navigation/native';
import { images } from '_/assets';
import { Title, Text, Button, Reservation, WebWrapper } from '_/components';
import { colors, fontSizes } from '_/constants/theme';
import { useEventContext } from '_/hooks/EventContext';
import { AppRoute } from '_/navigation/types';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AppState, AppStateStatus, FlatList, TouchableOpacity, View } from 'react-native';

import styles from './styles';

export default function TodayReservations() {
  const navigation = useNavigation<any>();
  const { getTodayEvents, todayReservations } = useEventContext();
  const { t } = useTranslation();

  const appState = useRef(AppState.currentState);

  const _handleAppStateChange = async (nextAppState: AppStateStatus) => {
    if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
      await getTodayEvents();
    }

    appState.current = nextAppState;
  };

  useEffect(() => {
    const subscription = AppState.addEventListener('change', _handleAppStateChange);

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    async function todayEvents() {
      await getTodayEvents();
    }

    todayEvents();
  }, []);

  return (
    <WebWrapper>
      <View style={styles.headerWrapper}>
        <Title title={t('homeScreen.myReservations')} />
        <TouchableOpacity
          style={styles.seeMore}
          onPress={() => navigation.navigate(AppRoute.MY_RESERVATIONS)}
        >
          <Text fontType="medium" fontSize={fontSizes.md2} color={colors.lightBlue}>
            {t('homeScreen.seeMore')}
          </Text>
        </TouchableOpacity>
      </View>

      {todayReservations.length === 0 && (
        <View style={styles.noReservationsForToday}>
          <Text fontSize={fontSizes.lg} style={styles.noReservationsText}>
            {t('homeScreen.noReservations')}
          </Text>
        </View>
      )}
      {false && (
        <View style={styles.buttonGroup}>
          <Button
            backgroundColor={colors.successGreen}
            textColor={colors.white}
            onPress={() => navigation.navigate(AppRoute.QR_CODE_SCANNER)}
            leftIcon={images.qrcode}
          >
            {t('homeScreen.check-in')}
          </Button>
        </View>
      )}
      <FlatList
        key="TodayReservations"
        data={todayReservations}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.id}
        style={styles.list}
        renderItem={({ item }) => <Reservation event={item} />}
      />
    </WebWrapper>
  );
}
