import general from '_/config/general';
import { colors } from '_/constants/theme';
import { reportsApi } from '_/services/api';
import logger from '_/services/logger';
import { ReportContextData, ReportsModel, LoadingType } from '_/services/models/reports.model';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

const ReportsContext = createContext<ReportContextData>({} as ReportContextData);

type ReportsType = {
  children?: React.ReactNode;
};

export const ReportsProvider: React.FC<ReportsType> = ({ children }) => {
  const [openListEndReached, setOpenListEndReached] = useState(false);
  const [openListPage, setOpenListPage] = useState(0);
  const [openReports, setOpenReports] = useState<ReportsModel[] | undefined>();
  const [closedListEndReached, setClosedListEndReached] = useState(false);
  const [closedListPage, setClosedListPage] = useState(0);
  const [closedReports, setClosedReports] = useState<ReportsModel[] | undefined>();
  const [loading, setLoading] = useState<LoadingType>();
  const { t } = useTranslation();

  const getOpenReports = useCallback(
    async (reset?: boolean) => {
      try {
        setLoading(reset ? 'open' : 'open-end');
        const page = reset ? 0 : openListPage + 1;
        setOpenListPage(page);
        const { data } = await reportsApi.getList({
          completedAt: '<null>',
          '$sort[createdAt]': -1,
          $limit: general.reportLimit,
          $skip: page * general.reportLimit,
        });

        setOpenListEndReached(data.length === 0);
        if (reset) {
          setOpenReports(data);
        } else {
          setOpenReports((prevReports) =>
            prevReports?.concat(data.filter((report: any) => prevReports.indexOf(report) === -1))
          );
        }
      } catch (error) {
        logger(error);
        showMessage({
          message: t('error'),
          description: t('reportsScreen.errorMessages.getReports'),
          backgroundColor: colors.errorRed,
        });
      }
      setLoading(undefined);
    },
    [openListPage, t]
  );

  const getClosedReports = useCallback(
    async (reset?: boolean) => {
      try {
        setLoading(reset ? 'closed' : 'closed-end');
        const page = reset ? 0 : closedListPage + 1;
        setClosedListPage(page);
        const { data } = await reportsApi.getList({
          '$sort[createdAt]': -1,
          'completedAt[$ne]': '<null>',
          $limit: general.reportLimit,
          $skip: page * general.reportLimit,
        });

        setClosedListEndReached(data.length === 0);
        if (reset) {
          setClosedReports(data);
        } else {
          setClosedReports((prevReports) =>
            prevReports?.concat(data.filter((report: any) => prevReports.indexOf(report) === -1))
          );
        }
      } catch (error) {
        logger(error);
        showMessage({
          message: t('error'),
          description: t('reportsScreen.errorMessages.getReports'),
          backgroundColor: colors.errorRed,
        });
      }
      setLoading(undefined);
    },
    [closedListPage, t]
  );

  return (
    <ReportsContext.Provider
      value={{
        getOpenReports,
        getClosedReports,
        openReports,
        closedReports,
        openListEndReached,
        closedListEndReached,
        loading,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export function useReportsContext(): ReportContextData {
  const context = useContext(ReportsContext);

  if (!context) {
    throw new Error('useReportsContext must be used within an ReportsProvider');
  }

  return context;
}
