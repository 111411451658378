import Button from '_/components/Button';
import DropdownInput from '_/components/DropdownInput';
import Text from '_/components/Text';
import Title from '_/components/Title';
import { colors, fontFamily, fontSizes, spacing } from '_/constants/theme';
import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { RecurrenceType } from '_/services/models/enums/recurrence-type.enum';
import { getSpaceTypeColor } from '_/services/models/enums/space-type.enum';
import { WeekDaysTypes } from '_/services/models/events.model';
import { getDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, View, StyleSheet, TextInput, Platform } from 'react-native';
import Modal from 'react-native-modal';
import { RRule, Weekday } from 'rrule';

import FinalDateCalendarModal from './FinalDateCalendar';
import RecurrenceFinishDateSelection from './RecurrenceFinishDate';
import WeekDays from './WeekDays';
interface CustomRecurrenceModalProps {
  visible: boolean;
  setCustomModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setRecurrenceType: (type: RecurrenceType) => void;
}

export default function CustomRecurrence({
  visible,
  setRecurrenceType,
  setCustomModalVisible,
}: CustomRecurrenceModalProps) {
  const { selectedFinalDayToDate, selectedEventDate } = useDateContext();
  const { t } = useTranslation();
  const {
    setByWeekDay,
    setRecurrenceInterval,
    recurrenceInterval,
    setFreq,
    byWeekDay,
    freq,
    setRecurrenceStr,
  } = useEventContext();
  const { spaceType } = useSpaceContext();
  const [selected, setSelected] = useState('endOfYear');
  const [finalDateCalendarModalVisible, setFinalDateCalendarModalVisible] = useState(false);
  const [selectedDays, setSelectedDay] = useState<WeekDaysTypes[]>([
    {
      id: 0,
      name: t('weekDays.mon'),
      selected: getDay(selectedEventDate.start) === 1,
      value: RRule.MO,
      day: 1,
    },
    {
      id: 1,
      name: t('weekDays.tue'),
      selected: getDay(selectedEventDate.start) === 2,
      value: RRule.TU,
      day: 2,
    },
    {
      id: 2,
      name: t('weekDays.wed'),
      selected: getDay(selectedEventDate.start) === 3,
      value: RRule.WE,
      day: 3,
    },
    {
      id: 3,
      name: t('weekDays.thu'),
      selected: getDay(selectedEventDate.start) === 4,
      value: RRule.TH,
      day: 4,
    },
    {
      id: 4,
      name: t('weekDays.fri'),
      selected: getDay(selectedEventDate.start) === 5,
      value: RRule.FR,
      day: 5,
    },
    {
      id: 5,
      name: t('weekDays.sat'),
      selected: getDay(selectedEventDate.start) === 6,
      value: RRule.SA,
      day: 6,
    },
    {
      id: 6,
      name: t('weekDays.sun'),
      selected: getDay(selectedEventDate.start) === 0,
      value: RRule.SU,
      day: 0,
    },
  ]);

  const handleCustomRecurrence = () => {
    const rule = new RRule({
      freq,
      interval: Number(recurrenceInterval),
      byweekday: byWeekDay?.map((item: { value: Weekday }) => item.value),
      dtstart: new Date(
        Date.UTC(
          selectedEventDate.start.getFullYear(),
          selectedEventDate.start.getMonth(),
          selectedEventDate.start.getDate(),
          selectedEventDate.start.getHours(),
          selectedEventDate.start.getMinutes(),
          selectedEventDate.start.getSeconds()
        )
      ),
      until: selectedFinalDayToDate,
    });
    setRecurrenceStr(rule.toString());
    setRecurrenceType(RecurrenceType.CUSTOM);
    setCustomModalVisible(false);
  };

  useEffect(() => {
    if (selectedDays) {
      const filterSelectedTrue = selectedDays.filter((item) => item.selected === true);
      setByWeekDay(filterSelectedTrue);
    }
  }, [selectedDays, setByWeekDay]);

  return (
    <>
      <Modal
        style={styles.modal}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        isVisible={visible}
        coverScreen
        hasBackdrop
        backdropOpacity={0.5}
        onBackdropPress={() => setCustomModalVisible(false)}
        onBackButtonPress={() => setCustomModalVisible(false)}
        onSwipeStart={() => setCustomModalVisible(false)}
      >
        <Title
          title={t('workstationScreen.customRecurrenceModal.custom')}
          hasCloseButton
          onPress={() => setCustomModalVisible(false)}
        />
        <Text fontSize={fontSizes.md2} fontType="bold" style={styles.text}>
          {t('daysOfWeek')}
        </Text>
        <WeekDays selectedDays={selectedDays} setSelectedDays={setSelectedDay} />
        <Text fontSize={fontSizes.md2} fontType="bold" style={styles.text}>
          {t('workstationScreen.customRecurrenceModal.repeatEvery')}
        </Text>
        <View style={[styles.repeat, Platform.OS !== 'android' && { zIndex: 99999 }]}>
          <TextInput
            style={styles.dayInput}
            keyboardType="numeric"
            value={recurrenceInterval}
            onChangeText={setRecurrenceInterval}
          />
          <DropdownInput
            value={freq}
            setValue={setFreq}
            options={[
              { label: t('day'), value: RRule.DAILY },
              { label: t('week'), value: RRule.WEEKLY },
              { label: t('month'), value: RRule.MONTHLY },
            ]}
            textStyle={styles.textStyle}
            dropDownContainerStyle={styles.dropdownContainerStyle}
            dropdownStyle={styles.dropdownStyle}
          />
        </View>
        <Text fontSize={fontSizes.md2} fontType="bold" style={styles.text}>
          {t('workstationScreen.customRecurrenceModal.endsAt')}
        </Text>
        <RecurrenceFinishDateSelection
          selected={selected}
          setSelected={setSelected}
          setFinalDateCalendarModalVisible={setFinalDateCalendarModalVisible}
        />
        <View style={styles.button}>
          <Button
            backgroundColor={getSpaceTypeColor(spaceType)}
            textColor={colors.white}
            onPress={handleCustomRecurrence}
          >
            {t('workstationScreen.customRecurrenceModal.confirmCustomization')}
          </Button>
        </View>
        <FinalDateCalendarModal
          visible={finalDateCalendarModalVisible}
          setFinalDateCalendarModalVisible={setFinalDateCalendarModalVisible}
        />
      </Modal>
    </>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  modal: {
    width: '100%',
    ...(Platform.OS === 'web' && { maxWidth: 550 }),
    margin: 0,
    marginTop: 120,
    backgroundColor: '#fff',
    alignSelf: 'center',
    justifyContent: 'flex-start',
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    paddingVertical: 40,
  },
  text: {
    marginHorizontal: spacing.md,
    marginBottom: spacing.md,
    marginTop: spacing.md,
  },
  dayInput: {
    textAlign: 'center',
    paddingVertical: spacing.sm,
    paddingHorizontal: spacing.md,
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 8,
    marginRight: spacing.xs,
    fontFamily: fontFamily.bold,
    fontSize: fontSizes.lg2,
    ...(Platform.OS === 'web' && {
      maxWidth: 550 * 0.2,
    }),
  },
  repeat: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: spacing.md,
    width,
    maxWidth: 550,
  },
  button: {
    marginHorizontal: spacing.md,
    marginTop: spacing.md,
  },
  dropdownStyle: {
    borderTopLeftRadius: 8,
    width: Platform.OS === 'web' ? 550 * 0.6 : width * 0.75,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    height: 60,
    flexDirection: 'row',
  },
  dropdownContainerStyle: {
    height: 'auto',
    width: Platform.OS === 'web' ? 550 * 0.6 : width * 0.75,
    borderRadius: 8,
    display: 'flex',
  },
  textStyle: {
    fontSize: fontSizes.lg2,
    textAlign: 'left',
    color: colors.black,
    fontFamily: fontFamily.bold,
  },
});
