import { ConfirmReserveModal, EventModal } from '_/components';
import { colors } from '_/constants/theme';
import { useEventContext } from '_/hooks/EventContext';
import { useSlotContext } from '_/hooks/SlotContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import useUpdateSlotCoordinates from '_/hooks/useUpdateSlotCoordinates';
import { SlotProps } from '_/screens/Workstation/StatusTab/AvailableList/Slot';
import { EventStatus } from '_/services/models/enums/event-status.enum';
import { RecurrenceType } from '_/services/models/enums/recurrence-type.enum';
import { SlotsModel } from '_/services/models/slots.model';
import React, { useCallback, useMemo, useState } from 'react';
import { Circle, LayerGroup, useMapEvent } from 'react-leaflet';

interface CustomMarksProps extends SlotProps {
  eventModalVisible: string | undefined;
  setEventModalVisible: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const MapMarker: React.FC<CustomMarksProps> = ({
  slot,
  eventModalVisible,
  setEventModalVisible,
}) => {
  const { currentSpace } = useSpaceContext();
  const { setIsSlotModalVisible, isSlotModalVisible } = useSlotContext();
  const { updateSlot } = useUpdateSlotCoordinates();
  const { setRecurrenceStr } = useEventContext();

  const [recurrenceType, setRecurrenceType] = useState<RecurrenceType>(RecurrenceType.ONCE);

  const events = useMemo(() => {
    return slot.events?.filter((event) => event.status !== EventStatus.CHECKEDOUT) || [];
  }, [slot.events]);

  const mapClick = useMapEvent('click', (e) => {
    const { lat, lng } = e.latlng;
    const coordinates = {
      latitude: lat,
      longitude: lng,
    };

    updateSlot(coordinates);
  });

  const handleConfirmReserve = useCallback(
    (slot: Omit<SlotsModel, 'amenities'>) => {
      setRecurrenceStr('');
      setIsSlotModalVisible(slot.id);
    },
    [setIsSlotModalVisible, setRecurrenceStr]
  );

  const handlePressMarker = useCallback(() => {
    mapClick.setView([slot.mapLocation?.latitude, slot.mapLocation?.longitude]);

    const events = slot.events?.filter((event) => event.status !== EventStatus.CHECKEDOUT) || [];

    if (events.length === 0) {
      handleConfirmReserve(slot);
    } else {
      setEventModalVisible(slot?.events?.[0].slotId);
    }
  }, [handleConfirmReserve, mapClick, setEventModalVisible, slot]);

  const HandleEventModal = useCallback(() => {
    if (events[0]) {
      return (
        <EventModal
          eventModalVisible={eventModalVisible}
          onBackdropPress={() => setEventModalVisible(undefined)}
          onBackButtonPress={() => setEventModalVisible(undefined)}
          onSwipeStart={() => setEventModalVisible(undefined)}
          id={events[0]?.slotId}
          name={events[0]?.user?.name as string}
          source={events[0].user?.pictureThumbUrl as string}
          startDate={events[0]?.startAt as unknown as string}
          endDate={events[0]?.endAt as unknown as string}
        />
      );
    } else {
      return null;
    }
  }, [eventModalVisible, setEventModalVisible, events]);

  const slotRadius = useMemo(() => {
    if (currentSpace.mapData?.slotRadiusWeb) {
      return currentSpace.mapData.slotRadiusWeb;
    }

    if (currentSpace.mapData?.slotRadius) {
      return currentSpace.mapData?.slotRadius * 0.0000075;
    }

    return 0.07;
  }, [currentSpace.mapData?.slotRadius, currentSpace.mapData?.slotRadiusWeb]);

  if (!slot.mapLocation?.latitude || !slot.mapLocation?.longitude) {
    return null;
  }

  return (
    <LayerGroup>
      <Circle
        pathOptions={{
          fillColor: events.length === 0 ? colors.successGreen : colors.errorRed,
          color: events.length === 0 ? colors.successGreen : colors.errorRed,
        }}
        radius={slotRadius}
        center={{ lat: slot.mapLocation.latitude, lng: slot.mapLocation.longitude }}
        stroke={false}
        opacity={1}
        fillOpacity={1}
        eventHandlers={{
          click: handlePressMarker,
        }}
      />

      {isSlotModalVisible === slot.id && (
        <ConfirmReserveModal
          onBackdropPress={() => setIsSlotModalVisible('')}
          onBackButtonPress={() => setIsSlotModalVisible('')}
          onSwipeStart={() => setIsSlotModalVisible('')}
          slot={slot}
          recurrenceType={recurrenceType}
          setRecurrenceType={setRecurrenceType}
        />
      )}

      <HandleEventModal />
    </LayerGroup>
  );
};

export default MapMarker;
