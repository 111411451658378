interface Dimensions {
  width: number;
  height: number;
}

export default function logoSize(widthImg: number, heightImg: number) {
  const maxWidth = 200;
  const maxHeight = 30;
  let dimensions: Dimensions;
  if (widthImg >= heightImg) {
    const ratio = maxWidth / widthImg;
    const height = Math.ceil(ratio * heightImg);

    if (height > maxHeight) {
      const ratio = maxHeight / heightImg;
      const width = Math.ceil(ratio * widthImg);
      dimensions = {
        width,
        height: maxHeight,
      };
    } else {
      dimensions = {
        width: maxWidth,
        height,
      };
    }
  } else {
    const ratio = maxHeight / heightImg;
    const width = Math.ceil(ratio * widthImg);

    if (width > maxWidth) {
      const ratio = maxWidth / widthImg;
      const height = Math.ceil(ratio * heightImg);
      dimensions = {
        width: maxWidth,
        height,
      };
    } else {
      dimensions = {
        width,
        height: maxHeight,
      };
    }
  }

  return dimensions;
}
