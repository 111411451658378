/* eslint-disable react-hooks/exhaustive-deps */
import { useRoute } from '@react-navigation/native';
import { TIME_FORMAT, TIME_FORMAT2 } from '_/config/date';
import { colors, fontFamily, fontSizes, spacing } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { AppRoute } from '_/navigation/types';
import { EventDate } from '_/services/models/date.model';
import { getSpaceTypeMinuteInterval, SpaceType } from '_/services/models/enums/space-type.enum';
import { format, getDate, getHours, getMinutes, getMonth, getYear, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Dimensions, TouchableOpacity, Platform } from 'react-native';
import DateTimePickerModal from 'react-native-modal-datetime-picker';

import Text from '../Text';
import WebPicker from './WebPicker';

interface TimePickerProps {
  editable?: boolean;
  defaultSelectedStartTime?: string;
  defaultSelectedEndTime?: string;
  setEventDate?: React.Dispatch<React.SetStateAction<EventDate>>;
  hasConflict?: boolean;
  selectedNewTime?: EventDate;
  hiddenError?: boolean;
}

export default function TimePicker({
  editable = true,
  defaultSelectedStartTime,
  defaultSelectedEndTime,
  setEventDate,
  hasConflict,
  selectedNewTime,
  hiddenError,
}: TimePickerProps) {
  const route = useRoute();
  const [isPickerVisible, setDatePickerVisibility] = useState<'start' | 'end'>();
  const {
    isIntervalInvalid,
    hasStartTimeBeforeCurrent,
    selectedEventDate,
    conflitedEvents,
    checkIfThereIsConflict,
  } = useDateContext();

  const { meetingEvents } = useEventContext();

  const { t } = useTranslation();
  const { spaceType } = useSpaceContext();

  useEffect(() => {
    if (spaceType !== SpaceType.WORK) {
      checkIfThereIsConflict(selectedEventDate, meetingEvents);
    }
  }, [meetingEvents, selectedEventDate, spaceType]);

  const handleStartHour = useCallback((hour: number) => {
    setEventDate?.((prev) => ({
      ...prev,
      start: new Date(
        getYear(prev.start),
        getMonth(prev.start),
        getDate(prev.start),
        hour,
        getMinutes(prev.start)
      ),
    }));
  }, []);

  const handleEndHour = useCallback((hour: number) => {
    setEventDate?.((prev) => ({
      ...prev,
      end: new Date(
        getYear(prev.end),
        getMonth(prev.end),
        getDate(prev.end),
        hour,
        getMinutes(prev.end)
      ),
    }));
  }, []);

  const handleStartMinute = useCallback((minute: number) => {
    setEventDate?.((prev) => ({
      ...prev,
      start: new Date(
        getYear(prev.start),
        getMonth(prev.start),
        getDate(prev.start),
        getHours(prev.start),
        minute
      ),
    }));
  }, []);

  const handleEndMinute = useCallback((minute: number) => {
    setEventDate?.((prev) => ({
      ...prev,
      end: new Date(
        getYear(prev.end),
        getMonth(prev.end),
        getDate(prev.end),
        getHours(prev.end),
        minute
      ),
    }));
  }, []);

  const handleStartDate = useCallback((start: Date) => {
    setDatePickerVisibility(undefined);
    setEventDate?.((prev) => ({
      ...prev,
      start,
    }));
  }, []);

  const handleEndDate = useCallback((end: Date) => {
    setDatePickerVisibility(undefined);
    setEventDate?.((prev) => ({
      ...prev,
      end,
    }));
  }, []);

  const selectedStartTime = useMemo(() => {
    if (defaultSelectedStartTime) {
      return defaultSelectedStartTime;
    }
    return formatDate(selectedEventDate.start, TIME_FORMAT);
  }, [defaultSelectedStartTime, selectedEventDate.start]);

  const selectedEndTime = useMemo(() => {
    if (defaultSelectedEndTime) {
      return defaultSelectedEndTime;
    }
    return formatDate(selectedEventDate.end, TIME_FORMAT);
  }, [defaultSelectedEndTime, selectedEventDate.end]);

  const selectedTimeWeb = useMemo(() => {
    if (selectedNewTime) {
      return selectedNewTime;
    }
    return selectedEventDate;
  }, [selectedNewTime, selectedEventDate]);

  const HandleWebOrMobilePicker = useCallback(
    ({
      visibility,
      timeString,
      date,
      handleHourOption,
      handleMinuteOption,
    }: {
      visibility: 'start' | 'end';
      timeString: string;
      handleHourOption: (hour: number) => void;
      handleMinuteOption: (minute: number) => void;
      date: Date;
    }) => {
      if (Platform.OS === 'web') {
        return (
          <>
            <WebPicker value={date} type="hour" setHour={handleHourOption} disabled={!editable} />
            <WebPicker
              value={date}
              type="minutes"
              setMinute={handleMinuteOption}
              disabled={!editable}
            />
          </>
        );
      }

      return (
        <TouchableOpacity
          onPress={() => setDatePickerVisibility(visibility)}
          style={styles.inputGroup}
          disabled={!editable}
        >
          <View style={styles.input}>
            <Text fontType="bold" fontSize={fontSizes.lg2} color={colors.darkGrey}>
              {timeString}
            </Text>
          </View>
        </TouchableOpacity>
      );
    },
    [editable]
  );

  const HandleDatePickerModal = useCallback(() => {
    if (Platform.OS === 'web') {
      return null;
    }
    const date = { ...(selectedNewTime || selectedEventDate) };
    return (
      <>
        <DateTimePickerModal
          isVisible={isPickerVisible === 'start'}
          mode="time"
          onConfirm={handleStartDate}
          onCancel={() => setDatePickerVisibility(undefined)}
          date={date.start}
          display={Platform.OS === 'ios' ? 'spinner' : 'default'}
          minuteInterval={getSpaceTypeMinuteInterval(spaceType)}
          is24Hour
          textColor={colors.black}
          confirmTextIOS={t('confirm')}
          cancelTextIOS={t('cancel')}
        />

        <DateTimePickerModal
          isVisible={isPickerVisible === 'end'}
          mode="time"
          onConfirm={handleEndDate}
          onCancel={() => setDatePickerVisibility(undefined)}
          date={date.end}
          display={Platform.OS === 'ios' ? 'spinner' : 'default'}
          minuteInterval={getSpaceTypeMinuteInterval(spaceType)}
          is24Hour
          textColor={colors.black}
          confirmTextIOS={t('confirm')}
          cancelTextIOS={t('cancel')}
        />
      </>
    );
  }, [
    isPickerVisible,
    selectedEventDate.end,
    selectedEventDate.start,
    spaceType,
    selectedNewTime,
    t,
  ]);

  return (
    <>
      {!hiddenError && isIntervalInvalid && (
        <Text color={colors.errorRed} style={{ padding: 16 }}>
          {t('timePicker.errorMessages.finalDateBeforeInitialDate')}
        </Text>
      )}
      {!hiddenError && hasStartTimeBeforeCurrent && (
        <Text color={colors.errorRed} style={{ padding: 16 }}>
          {t('timePicker.errorMessages.startTimeBeforeCurrentTime')}
        </Text>
      )}
      {hasConflict && route.name !== AppRoute.DATE_PICKER && (
        <>
          <Text style={{ padding: 16 }} color={colors.errorRed}>
            {t('timePicker.errorMessages.hasConflict')}
          </Text>
          <View style={styles.conflicts}>
            {conflitedEvents.map((event) => {
              return (
                <Text key={event.id} style={{ padding: 16 }} fontType="bold">
                  {format(parseISO(event.startDate), TIME_FORMAT2)} -{' '}
                  {format(parseISO(event.endDate), TIME_FORMAT2)}
                </Text>
              );
            })}
          </View>
          <Text style={{ padding: 16 }} color={colors.errorRed}>
            {t('timePicker.errorMessages.pleaseSelectAnotherTime')}
          </Text>
        </>
      )}
      <View style={styles.wrapper}>
        <HandleDatePickerModal />
        <HandleWebOrMobilePicker
          visibility="start"
          timeString={selectedStartTime}
          handleHourOption={handleStartHour}
          handleMinuteOption={handleStartMinute}
          date={selectedTimeWeb.start}
        />
        <Text fontType="medium" fontSize={fontSizes.lg2} style={{ marginHorizontal: 24 }}>
          {t('datePickerScreen.to')}
        </Text>
        <HandleWebOrMobilePicker
          visibility="end"
          timeString={selectedEndTime}
          handleHourOption={handleEndHour}
          handleMinuteOption={handleEndMinute}
          date={selectedTimeWeb.end}
        />
      </View>
    </>
  );
}

const { width } = Dimensions.get('screen');

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: spacing.md,
    marginTop: spacing.sm,
    maxWidth: 550,
    width,
  },
  inputGroup: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  input: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: spacing.sm,
    color: colors.darkGrey,
    fontSize: fontSizes.xl,
    fontFamily: fontFamily.bold,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: colors.white,
    minWidth: width * 0.35,
  },
  conflicts: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
