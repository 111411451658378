import AsyncStorage from '@react-native-async-storage/async-storage';
import generalConfig from '_/config/general';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import uuid from 'react-native-uuid';

import { usersApi } from './api';

export async function getDeviceId() {
  if (Constants.deviceId) {
    return Constants.deviceId as string;
  }

  const deviceId = await AsyncStorage.getItem(generalConfig.deviceIdKey);

  if (deviceId) {
    return deviceId;
  }

  const newDeviceId = uuid.v4().toString();

  await AsyncStorage.setItem(generalConfig.deviceIdKey, newDeviceId);

  return newDeviceId;
}

export function setNotificationsHandler() {
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: false,
    }),
  });
}

export async function userTokenExists() {
  const deviceId = await getDeviceId();

  const tokens = await usersApi.getUserTokens(deviceId);

  return tokens.data.length > 0;
}

export async function registerForPushNotificationsAsync() {
  if (Platform.OS === 'web') {
    return;
  }

  const tokenExist = await userTokenExists();
  if (tokenExist) {
    return;
  }

  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;

  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }
  if (finalStatus !== 'granted') {
    return;
  }

  const token = (await Notifications.getExpoPushTokenAsync()).data;

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
    });
  }

  const deviceId = await getDeviceId();

  return { token, deviceId };
}
