import { useNavigation } from '@react-navigation/native';
import { Button } from '_/components';
import { colors } from '_/constants/theme';
import { useReportsContext } from '_/hooks/ReportsContext';
import { AppRoute } from '_/navigation/types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, FlatList, RefreshControl, ActivityIndicator, StyleSheet } from 'react-native';

import NoReports from '../NoReports';
import Report from '../Report';

export default function OpenReports() {
  const navigation = useNavigation<any>();
  const { t } = useTranslation();
  const { getOpenReports, openReports, openListEndReached, getClosedReports, loading } =
    useReportsContext();

  useEffect(() => {
    getOpenReports(true);
  }, []); // eslint-disable-line

  return (
    <View style={styles.container}>
      <FlatList
        key="OpenReports"
        data={openReports}
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={loading ? null : <NoReports />}
        keyExtractor={(item) => item.id}
        renderItem={(report) => <Report report={report.item} />}
        contentContainerStyle={styles.flatListContainer}
        onEndReached={() => {
          if (!loading && !openListEndReached) {
            getOpenReports();
          }
        }}
        ListFooterComponent={() => {
          if (loading !== 'open-end') return null;
          return <ActivityIndicator size={20} style={{ marginTop: 20 }} color={colors.black} />;
        }}
        onEndReachedThreshold={0.1}
        refreshControl={
          <RefreshControl
            refreshing={loading === 'open'}
            onRefresh={() => {
              getOpenReports(true);
              getClosedReports(true);
            }}
          />
        }
      />
      <View style={styles.buttonContainer}>
        <Button
          backgroundColor={colors.lightBlue}
          leftIcon="plus"
          textColor={colors.clear}
          onPress={() => navigation.navigate(AppRoute.CREATE_REPORT)}
        >
          {t('reportsScreen.openReport')}
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: 24,
  },
  flatListContainer: {
    paddingBottom: 80,
  },
});
