export const answers = {
  // withAccess: 'Minha empresa já usa e tenho acesso',
  withoutAccess: 'Minha empresa usa mas não tenho acesso',
  knowMoreAbout: 'Quero saber mais sobre o produto',
};

export const answersOptions = [
  // {
  //   label: answers.withAccess,
  //   value: answers.withAccess,
  // },
  {
    label: answers.withoutAccess,
    value: answers.withoutAccess,
  },
  {
    label: answers.knowMoreAbout,
    value: answers.knowMoreAbout,
  },
];
