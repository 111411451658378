import { spacing, colors } from '_/constants/theme';
import { Dimensions, Platform, StyleSheet } from 'react-native';

const { width, height } = Dimensions.get('window');

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  map: {
    ...StyleSheet.absoluteFillObject,
  },
  list: {
    backgroundColor: colors.black,
    opacity: 0.95,
    zIndex: 999999,
  },
  result: {
    flexDirection: 'row',
    width,
    ...(Platform.OS === 'web' && {
      width: 550,
    }),
    paddingHorizontal: spacing.md,
    paddingVertical: spacing.md,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 1,
  },
  resultContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    marginLeft: spacing.sm,
  },
  backdrop: {
    backgroundColor: colors.black,
    zIndex: 999998,
    height,
    width,
    ...(Platform.OS === 'web' && {
      width: 550,
    }),
  },
});
