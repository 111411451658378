export enum AppRoute {
  LOGIN_CONTAINER = 'LoginContainer',
  LOGIN = 'Login',
  PLACEHUB_AUTH_LOADING = 'Placehub',
  HOME_STACK_SCREEN = 'PlacehubHome',
  HOME = 'Home',
  SIGNUP = 'Signup',
  NOTIFICATION_SETTINGS = 'NotificationSettings',
  NOTIFICATIONS = 'Notifications',
  MY_RESERVATIONS = 'MyReservations',
  RECURRING_RESERVATIONS = 'RecurringReservations',
  DATE_PICKER = 'DatePicker',
  PROFILE_STACK_SCREEN = 'PlacehubProfile',
  PROFILE = 'Profile',
  PROFILE_SETTINGS = 'ProfileSettings',
  WORK = 'Workstation',
  QR_CODE_SCANNER = 'QRCodeScanner',
  NO_CONTENT = 'NoContent',
  REQUEST_REPORT = 'RequestReport',
  REPORT_PROBLEM = 'ReportProblem',
  CREATE_REPORT = 'CreateReport',
  EDIT_EVENT = 'EditEvent',
  SET_SLOT_COORDINATE = 'SetSlotCoordinate',
  DELETE_ACCOUNT = 'DeleteAccount',
  ORGANIZATION_DISABLED = 'OrganizationDisabled',
}
