import { colors } from '_/constants/theme';

export enum ReportPriority {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export const reportPriorityName = {
  [ReportPriority.HIGH]: 'Alta',
  [ReportPriority.MEDIUM]: 'Média',
  [ReportPriority.LOW]: 'Baixa',
};

export const reportPriorityColor = {
  [ReportPriority.HIGH]: colors.red,
  [ReportPriority.MEDIUM]: colors.orange,
  [ReportPriority.LOW]: colors.yellow,
};
