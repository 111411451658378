import { Feather } from '@expo/vector-icons';
import { images } from '_/assets';
import { colors } from '_/constants/theme';
import React from 'react';
import { Image, View, StyleSheet, ActivityIndicator } from 'react-native';

interface AvatarProps {
  size: number;
  source?: string | undefined;
  borderRadius?: number;
  isEditable?: boolean;
  hasNoAvatar?: boolean;
  isLoading?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({
  size,
  source,
  borderRadius = size / 2,
  isEditable = false,
  isLoading = false,
  hasNoAvatar,
}) => {
  if (hasNoAvatar) {
    return <Feather name="user" size={24} />;
  }

  return (
    <View>
      <Image
        style={{ height: size, width: size, borderRadius }}
        source={source ? { uri: source } : images.user}
      />
      {isEditable && (
        <View style={styles.editPictureIcon}>
          <Feather name="camera" color={colors.black} size={16} />
        </View>
      )}
      {isLoading && (
        <View style={[styles.loadingContainer, { borderRadius }]}>
          <ActivityIndicator size="large" color={colors.clear} animating />
        </View>
      )}
    </View>
  );
};

export default Avatar;

const styles = StyleSheet.create({
  editPictureIcon: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: 30,
    borderRadius: 15,
    backgroundColor: colors.clear,
    zIndex: 2,
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colors.black,
    opacity: 0.4,
    zIndex: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
