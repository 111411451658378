import { colors, fontFamily, fontSizes } from '_/constants/theme';
import { ReportPriority } from '_/services/models/enums/report-priority.enum';
import { ReportType } from '_/services/models/enums/report-type.enum';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, ViewStyle, StyleProp, TextStyle } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { Frequency } from 'rrule';

import Text from '../Text/index';

interface DropdownInputProps {
  tag?: string;
  placeholder?: string;
  options: any;
  style?: StyleProp<ViewStyle>;
  zIndex?: number;
  disabled?: boolean;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  value: null | string | ReportType | ReportPriority | Frequency;
  dropdownStyle?: StyleProp<ViewStyle>;
  dropDownContainerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

export default function DropdownInput({
  tag,
  placeholder,
  options,
  style,
  zIndex,
  disabled,
  value,
  textStyle,
  dropDownContainerStyle,
  dropdownStyle,
  setValue,
}: DropdownInputProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState(options.map((option: any) => option));

  return (
    <View style={style}>
      {!!tag && (
        <View style={[styles.tag, { zIndex: zIndex ? zIndex + 1 : 5001 }]}>
          <Text color={colors.darkGrey} fontSize={fontSizes.md}>
            {tag}
          </Text>
        </View>
      )}
      <DropDownPicker
        open={open}
        value={value}
        items={items}
        setOpen={setOpen}
        setValue={setValue}
        setItems={setItems}
        style={[dropdownStyle, styles.containerStyle]}
        textStyle={[textStyle, styles.inputStyle]}
        placeholder={placeholder || t('selectOption')}
        zIndex={zIndex}
        disabledStyle={{
          opacity: 0.7,
        }}
        dropDownContainerStyle={[dropDownContainerStyle, styles.dropDownStyle]}
        disabled={disabled}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  tag: {
    position: 'absolute',
    top: -10,
    left: 10,
    backgroundColor: '#FFF',
    paddingHorizontal: 6,
  },
  containerStyle: {
    borderColor: colors.lightGrey,
  },
  inputStyle: {
    fontFamily: fontFamily.regular,
  },
  dropDownStyle: {
    borderColor: colors.lightGrey,
  },
});
