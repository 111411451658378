import { alphaColor, colors, fontFamily, fontSizes, spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  wrapper: {
    marginTop: spacing.md,
    paddingHorizontal: spacing.md,
  },
  radioInputWrapper: {
    marginVertical: 28,
  },
  radioInputContainer: {
    flexDirection: 'row',
    marginVertical: spacing.sm,
  },
  radioInput: {
    height: 18,
    width: 18,
    borderRadius: 18 * 0.5,
    borderWidth: 2,
    marginRight: spacing.xs,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    height: 8,
    width: 8,
    borderRadius: 8 * 0.5,
    backgroundColor: colors.black,
  },
});
export const calendarTheme = {
  todayTextColor: colors.black,
  selectedDayTextColor: colors.white,
  arrowColor: colors.black,
  textDayFontFamily: fontFamily.bold,
  textMonthFontFamily: fontFamily.bold,
  textDayHeaderFontFamily: fontFamily.bold,
  textMonthFontSize: fontSizes.md,
  textDayHeaderFontSize: fontSizes.md,
  textDisabledColor: alphaColor(colors.black, 0.1),
  monthTextColor: colors.darkGrey,
};

export const customMark = {
  container: {
    backgroundColor: colors.green,
    borderRadius: 5,
  },
};
