import { Header, Text, WebWrapper } from '_/components';
import { colors, fontSizes } from '_/constants/theme';
import { useAuth } from '_/hooks/AuthContext';
import { usersApi } from '_/services/api';
import { TokenType } from '_/services/models/enums/token-type.enum';
import {
  getDeviceId,
  registerForPushNotificationsAsync,
  userTokenExists,
} from '_/services/notifications';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Switch, ActivityIndicator, Platform } from 'react-native';
import { showMessage } from 'react-native-flash-message';

import styles from './styles';

type LoadingType = 'push' | 'email';

export default function NotificationSettings() {
  const [isEnabled, setIsEnabled] = useState(false);
  const [loading, setLoading] = useState<LoadingType>();
  const { user, updateEmailNotification } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    async function loadData() {
      const tokenExist = await userTokenExists();

      if (tokenExist) {
        setIsEnabled(true);
      }
    }

    loadData();
  }, []);

  const togglePushSwitch = async (value: boolean) => {
    setLoading('push');
    const tokenExist = await userTokenExists();

    if (value && !tokenExist) {
      const data = await registerForPushNotificationsAsync();

      if (!data) {
        setLoading(undefined);
        return;
      }

      await usersApi.createUserToken({
        userId: user.id,
        deviceId: data.deviceId,
        token: data.token,
        type: TokenType.EXPO,
      });
    } else {
      if (!tokenExist) return;

      const deviceId = await getDeviceId();
      await usersApi.deleteUserToken(deviceId);
    }

    setLoading(undefined);
    setIsEnabled(value);
  };

  const toggleEmailSwitch = async (value: boolean) => {
    setLoading('email');
    try {
      await updateEmailNotification(value);
    } catch (error) {
      showMessage({
        message: t('error'),
        description: t('configureNotificationsScreen.errorMessages.updateNotificationsFail'),
        backgroundColor: colors.errorRed,
      });
    }
    setLoading(undefined);
  };

  return (
    <WebWrapper>
      <Header screenName={t('notifications')} />
      {Platform.OS !== 'web' && (
        <View style={[styles.wrapper, styles.upperContainer]}>
          <View style={styles.switchContainer}>
            <Text fontSize={fontSizes.lg}>
              {t('configureNotificationsScreen.enableNotifications')}
            </Text>
            {loading === 'push' ? (
              <ActivityIndicator color={colors.lightBlue} size={30} />
            ) : (
              <Switch
                trackColor={{ false: colors.darkGrey, true: colors.lightBlue }}
                thumbColor={colors.clear}
                ios_backgroundColor={colors.darkGrey}
                onValueChange={togglePushSwitch}
                value={isEnabled}
              />
            )}
          </View>
          <Text>{t('configureNotificationsScreen.enableNotificationsMessage')}</Text>
        </View>
      )}
      <View style={styles.wrapper}>
        <View style={styles.switchContainer}>
          <Text fontSize={fontSizes.lg}>
            {t('configureNotificationsScreen.enableEmailNotifications')}
          </Text>
          {loading === 'email' ? (
            <ActivityIndicator color={colors.lightBlue} size={30} />
          ) : (
            <Switch
              trackColor={{ false: colors.darkGrey, true: colors.lightBlue }}
              thumbColor={colors.clear}
              ios_backgroundColor={colors.darkGrey}
              onValueChange={toggleEmailSwitch}
              value={user.emailNotification}
            />
          )}
        </View>
        <Text>{t('configureNotificationsScreen.enableEmailNotificationsMessage')}</Text>
      </View>
    </WebWrapper>
  );
}
