import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { colors, spacing } from '_/constants/theme';
import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, TouchableOpacity } from 'react-native';

import Text from '../Text';

interface ArrowBackHeaderProps {
  children: string | undefined;
  textColor?: string | undefined;
  style?: StyleProp<ViewStyle>;
}

export default function ArrowBackHeader({ children, textColor, style }: ArrowBackHeaderProps) {
  const navigation = useNavigation<any>();

  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity
        hitSlop={{ top: 8, right: 8, bottom: 8, left: 8 }}
        onPress={() => navigation.goBack()}
      >
        <Feather name="arrow-left" size={24} color={textColor || colors.black} />
      </TouchableOpacity>
      <Text fontType="medium" fontSize={20} color={textColor} style={styles.text}>
        {children}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginLeft: spacing.xs,
  },
});
