import { Feather } from '@expo/vector-icons';
import Text from '_/components/Text';
import Title from '_/components/Title';
import { colors, fontSizes, spacing } from '_/constants/theme';
import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { RecurrenceType } from '_/services/models/enums/recurrence-type.enum';
import { endOfYear } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View, StyleSheet, Platform } from 'react-native';
import Modal from 'react-native-modal';
import { RRule } from 'rrule';

interface SelectRecurrenceModalProps {
  visible: boolean;
  setRecurrenceModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setRecurrenceType: (type: RecurrenceType) => void;
  recurrenceType: RecurrenceType;
}

export default function SelectRecurrenceModal({
  visible,
  setRecurrenceType,
  recurrenceType,
  setRecurrenceModalVisible,
  setCustomModalVisible,
}: SelectRecurrenceModalProps) {
  const [customRecurrence, setCustomRecurrence] = useState(false);
  const { selectedEventDate } = useDateContext();
  const { setRecurrenceStr } = useEventContext();
  const { t } = useTranslation();

  const handleJustOnce = () => {
    setRecurrenceType(RecurrenceType.ONCE);
    setRecurrenceModalVisible(false);
    setRecurrenceStr('');
  };

  const handleDaily = () => {
    setRecurrenceType(RecurrenceType.DAILY);
    setRecurrenceModalVisible(false);
    const rule = new RRule({
      freq: RRule.DAILY,
      dtstart: selectedEventDate.start,
      until: endOfYear(new Date()),
    });
    setRecurrenceStr(rule.toString());
  };

  const handleCustom = () => {
    setRecurrenceType(RecurrenceType.CUSTOM);
    setCustomRecurrence(true);
    setRecurrenceModalVisible(false);
  };

  return (
    <Modal
      style={styles.modal}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      isVisible={visible}
      coverScreen
      hasBackdrop
      backdropOpacity={0.5}
      onBackdropPress={() => setRecurrenceModalVisible(false)}
      onBackButtonPress={() => setRecurrenceModalVisible(false)}
      onSwipeStart={() => setRecurrenceModalVisible(false)}
      onModalHide={() => {
        if (customRecurrence) {
          setCustomModalVisible(true);
          setCustomRecurrence(false);
        }
      }}
    >
      <Title
        title={t('workstationScreen.recurrenceModal.recurrence')}
        hasCloseButton
        style={styles.title}
        onPress={() => setRecurrenceModalVisible(false)}
      />
      <TouchableOpacity onPress={handleJustOnce} style={styles.wrapper}>
        <Text fontSize={fontSizes.md2} fontType="bold">
          {t('recurrenceTypes.ONCE')}
        </Text>
        {recurrenceType === RecurrenceType.ONCE ? (
          <Feather name="check" size={24} />
        ) : (
          <View style={styles.checkCircle} />
        )}
      </TouchableOpacity>
      <TouchableOpacity onPress={handleDaily} style={styles.wrapper}>
        <Text fontSize={fontSizes.md2} fontType="bold">
          {t('recurrenceTypes.DAILY')}
        </Text>
        {recurrenceType === RecurrenceType.DAILY ? (
          <Feather name="check" size={24} />
        ) : (
          <View style={styles.checkCircle} />
        )}
      </TouchableOpacity>
      <TouchableOpacity onPress={handleCustom} style={styles.wrapper}>
        <Text fontSize={fontSizes.md2} fontType="bold">
          {t('recurrenceTypes.CUSTOM')}
        </Text>
        {recurrenceType === RecurrenceType.CUSTOM ? (
          <Feather name="check" size={24} />
        ) : (
          <View style={styles.checkCircle} />
        )}
      </TouchableOpacity>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modal: {
    width: '100%',
    ...(Platform.OS === 'web' && { maxWidth: 550 }),
    margin: 0,
    marginTop: 80,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    paddingVertical: 40,
  },
  title: {
    marginBottom: 32,
  },
  wrapper: {
    padding: spacing.md,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: spacing.xs,
  },
  checkCircle: {
    borderRadius: 24 * 0.5,
    width: 24,
    height: 24,
    backgroundColor: colors.white,
  },
});
