import { Button, TimePicker, Title, Text } from '_/components';
import { colors } from '_/constants/theme';
import { useDateContext } from '_/hooks/DateContext';
import { SpaceType, getSpaceTypeColor } from '_/services/models/enums/space-type.enum';
import { SpacesModel } from '_/services/models/spaces.model';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import React, { View } from 'react-native';

import styles from '../styles';

interface IHandleTimePicker {
  space: SpacesModel;
}
const HandleTimePicker: FC<IHandleTimePicker> = ({ space }) => {
  const { t } = useTranslation();
  const { setTimePickerVisible, timePickerVisible, setSelectedEventDate } = useDateContext();
  const isSpaceTypeWork = space.type === SpaceType.WORK;

  useEffect(() => {
    if (space.type === SpaceType.WORK) {
      setTimePickerVisible(true);
    }
  }, [setTimePickerVisible, space.type]);

  if (isSpaceTypeWork || timePickerVisible) {
    return (
      <>
        <View style={!isSpaceTypeWork && timePickerVisible ? styles.timePickerTitle : {}}>
          <Title title={t('datePickerScreen.schedule')} />
          {!isSpaceTypeWork && (
            <Text onPress={() => setTimePickerVisible(false)} color={colors.red}>
              {t('datePickerScreen.removeSchedule')}
            </Text>
          )}
        </View>
        <TimePicker editable setEventDate={setSelectedEventDate} />
      </>
    );
  }

  return (
    <View style={styles.wrapper}>
      <Button
        rightIcon="plus"
        textColor={getSpaceTypeColor(space.type)}
        onPress={() => setTimePickerVisible(true)}
      >
        {t('datePickerScreen.addSchedule')}
      </Button>
    </View>
  );
};

export default HandleTimePicker;
