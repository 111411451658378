import { images } from '_/assets';
import { Button } from '_/components';
import generalConfig from '_/config/general';
import { useAuth } from '_/hooks/AuthContext';
import {
  exchangeCodeAsync,
  makeRedirectUri,
  Prompt,
  useAuthRequest,
  useAutoDiscovery,
} from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import React from 'react';
import { useTranslation } from 'react-i18next';

WebBrowser.maybeCompleteAuthSession({ skipRedirectCheck: true });

const OAuthMicrosoft = () => {
  const { signInMicrosoft } = useAuth();
  const { t } = useTranslation();
  const discovery = useAutoDiscovery('https://login.microsoftonline.com/common/v2.0');
  const redirectUri = makeRedirectUri({
    scheme: undefined,
    path: 'redirect',
  });

  const [request, _response, promptAsync] = useAuthRequest(
    {
      ...generalConfig.microsoftLoginConfig,
      prompt: Prompt.SelectAccount,
      redirectUri,
    },
    discovery
  );

  const handleMicrosoftSignIn = () => {
    promptAsync().then((codeResponse) => {
      if (request && codeResponse?.type === 'success' && discovery) {
        exchangeCodeAsync(
          {
            clientId: generalConfig.microsoftLoginConfig.clientId,
            code: codeResponse.params.code,
            extraParams: request.codeVerifier ? { code_verifier: request.codeVerifier } : undefined,
            redirectUri,
          },
          discovery
        ).then((response) => {
          signInMicrosoft(response);
        });
      }
    });
  };

  return (
    <Button leftIcon={images.microsoftLogo} onPress={handleMicrosoftSignIn} disabled={!request}>
      {t('signinScreen.signInWithMicrosoft')}
    </Button>
  );
};

export default OAuthMicrosoft;
