import { Feather } from '@expo/vector-icons';
import { colors, spacing } from '_/constants/theme';
import React, { forwardRef, useState } from 'react';
import {
  TextInput,
  View,
  StyleSheet,
  TouchableOpacity,
  TextInputProps,
  StyleProp,
  ViewStyle,
  TouchableWithoutFeedback,
} from 'react-native';

import Text from '../Text';

interface InputInterface extends TextInputProps {
  tag?: string;
  placeholder?: string;
  isHidden?: boolean;
  isPassword?: boolean;
  icon?: string;
  containerStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
}

const Input = forwardRef<TextInput, InputInterface>(
  (
    { tag, placeholder, isHidden, isPassword, icon, containerStyle, disabled, style, ...rest },
    ref
  ) => {
    const [hiddenPassword, setHiddenPassword] = useState(isPassword);

    const togglePasswordVisibility = () => {
      setHiddenPassword((p) => !p);
    };

    return (
      <TouchableWithoutFeedback>
        <View
          style={[
            styles.inputGroup,
            containerStyle,
            {
              display: isHidden ? 'none' : 'flex',
              backgroundColor: disabled ? colors.white : 'transparent',
            },
          ]}
        >
          {!!tag && (
            <View pointerEvents="none" style={styles.tag}>
              <Text color={colors.darkGrey} fontSize={14}>
                {tag}
              </Text>
            </View>
          )}
          <TextInput
            ref={ref}
            style={[styles.textInput, style]}
            placeholder={placeholder}
            secureTextEntry={hiddenPassword}
            placeholderTextColor={colors.lightGrey}
            allowFontScaling={false}
            {...rest}
          />
          {isPassword && (
            <TouchableOpacity
              style={styles.icon}
              hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
              onPress={togglePasswordVisibility}
            >
              <Feather size={18} name={hiddenPassword ? 'eye-off' : 'eye'} />
            </TouchableOpacity>
          )}
          {!!icon && <Feather size={18} name={icon as any} style={styles.icon} />}
        </View>
      </TouchableWithoutFeedback>
    );
  }
);

export default Input;

const styles = StyleSheet.create({
  textInput: {
    width: '100%',
    fontSize: 16,
    height: 54,
    paddingLeft: spacing.md,
    paddingRight: spacing.md,
    flex: 1,
  },

  inputGroup: {
    borderColor: colors.white,
    width: '100%',
    borderWidth: 1,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: spacing.md,
    position: 'relative',
  },

  tag: {
    position: 'absolute',
    top: -10,
    left: 10,
    backgroundColor: colors.clear,
    paddingHorizontal: 6,
  },
  icon: {
    position: 'absolute',
    right: 10,
  },
});
