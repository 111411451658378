const DATE_FORMAT = 'yyyy-MM-dd';
const DAY_MONTH_FORMAT = "dd 'de' MMMM";
const IN_FULL_FORMAT = "dd 'de' MMMM 'de' yyyy";
const DAY_MONTH_YEART_FORMAT = 'dd/MM/yyyy';
const DATETAB_FORMAT = 'dd LLL';
const DATETAB_TIME_FORMAT = "HH'h'mm";
const WEEK_DAY_FORMAT = 'EEEE';
const MONTH_FORMAT = 'LLL';
const DAY_FORMAT = 'dd';
const HOUR_FORMAT = 'HH';
const MINUTE_FORMAT = 'mm';
const TIME_FORMAT = "HH' : 'mm";
const TIME_FORMAT2 = "HH':'mm";

export {
  DATE_FORMAT,
  DAY_MONTH_FORMAT,
  WEEK_DAY_FORMAT,
  MONTH_FORMAT,
  DAY_FORMAT,
  DATETAB_FORMAT,
  DATETAB_TIME_FORMAT,
  HOUR_FORMAT,
  MINUTE_FORMAT,
  DAY_MONTH_YEART_FORMAT,
  IN_FULL_FORMAT,
  TIME_FORMAT,
  TIME_FORMAT2,
};
