import general from '_/config/general';
import { colors } from '_/constants/theme';
import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { useSlotContext } from '_/hooks/SlotContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { SlotsModel } from '_/services/models/slots.model';
import { endOfDay, startOfDay } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, FlatList, RefreshControl, View } from 'react-native';

import NoAvailableSlots from './NoAvailableSlots';
import Slot from './Slot';
import styles from './styles';
import ConfirmReserveModal from '_/components/ConfirmReserveModal';
import { RecurrenceType } from '_/services/models/enums/recurrence-type.enum';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import { getSpaceTypeName } from '_/services/models/enums/space-type.enum';

export default function AvailableList() {
  const {
    availableList: slotsAvailable,
    availableListEndReached,
    isSlotModalVisible,
    setIsSlotModalVisible,
    slotAvailable,
  } = useSlotContext();
  const { getAvailableList, availableListLoading, maxCapacityReached } = useEventContext();
  const { spaceId, currentSpace } = useSpaceContext();
  const { selectedEventDate, selectedDay } = useDateContext();
  const { singleSlotEventPerDay } = currentSpace;
  const [recurrenceType, setRecurrenceType] = useState<RecurrenceType>(RecurrenceType.ONCE);
  const { t } = useTranslation();

  const fetchData = async () => {
    if (spaceId) {
      if (singleSlotEventPerDay) {
        await getAvailableList({
          limit: general.apiSlotLimitList,
          reset: true,
          spaceId,
          startAtTime: startOfDay(selectedEventDate.start),
          endAtTime: endOfDay(selectedEventDate.end),
        });
      } else {
        await getAvailableList({
          limit: general.apiSlotLimitList,
          reset: true,
          spaceId,
          startAtTime: selectedEventDate.start,
          endAtTime: selectedEventDate.end,
        });
      }
    }
  };

  const renderItem = ({ item }: { item: SlotsModel }) => {
    return <Slot slot={item} />;
  };

  const listData = useMemo(() => {
    if (maxCapacityReached) {
      return [];
    }
    return slotsAvailable;
  }, [maxCapacityReached, slotsAvailable]);

  useEffect(() => {
    const getData = async () => {
      await fetchData();
    };
    getData();
  }, [selectedEventDate.start, selectedEventDate.end, selectedDay, spaceId, singleSlotEventPerDay]);

  useEffect(() => {
    if (isSlotModalVisible && !slotAvailable) {
      const start = selectedEventDate.start.toTimeString().slice(0, 5);
      const end = selectedEventDate.end.toTimeString().slice(0, 5);
      showMessage({
        message: t('error'),
        description: `${t(`slotNamePerSpaceType.${getSpaceTypeName(currentSpace.type)}`)} ${t(
          `unavailableAtTheTime`,
          { start, end }
        )}`,
        backgroundColor: colors.errorRed,
      });
      setIsSlotModalVisible('');
      setRecurrenceType(RecurrenceType.ONCE);
    }
  }, [slotAvailable]);

  const handleCloseConfirmationModal = () => {
    setIsSlotModalVisible('');
    setRecurrenceType(RecurrenceType.ONCE);
  };

  return (
    <View style={styles.listWrapper}>
      <FlatList
        ListEmptyComponent={
          availableListLoading === 'loadData' ? null : (
            <NoAvailableSlots maxCapacityReached={maxCapacityReached} />
          )
        }
        style={styles.list}
        data={listData}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
        numColumns={4}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 60 }}
        onEndReached={() => {
          if (!availableListEndReached) {
            getAvailableList({
              limit: general.apiSlotLimitList,
              spaceId,
              startAtTime: selectedEventDate.start,
              endAtTime: selectedEventDate.end,
            });
          }
        }}
        scrollEventThrottle={16}
        onEndReachedThreshold={0.2}
        refreshControl={
          <RefreshControl
            refreshing={availableListLoading === 'loadData'}
            onRefresh={() => {
              getAvailableList({
                limit: general.apiSlotLimitList,
                reset: true,
                spaceId,
                startAtTime: selectedEventDate.start,
                endAtTime: selectedEventDate.end,
              });
            }}
          />
        }
        ListFooterComponent={() => {
          if (availableListLoading !== 'endReached') return null;

          return <ActivityIndicator size={20} style={{ marginTop: 20 }} color={colors.black} />;
        }}
      />
      {isSlotModalVisible && (
        <ConfirmReserveModal
          onBackdropPress={handleCloseConfirmationModal}
          onBackButtonPress={handleCloseConfirmationModal}
          onSwipeStart={handleCloseConfirmationModal}
          slot={
            listData.find((slot) => slot.id === isSlotModalVisible) || ([] as unknown as SlotsModel)
          }
          recurrenceType={recurrenceType}
          setRecurrenceType={setRecurrenceType}
        />
      )}
    </View>
  );
}
