import { alphaColor, colors, fontFamily, fontSizes } from '_/constants/theme';
import { getSpaceTypeColor, SpaceType } from '_/services/models/enums/space-type.enum';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    backgroundColor: '#FFFF',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  // modalContent: {
  //   height: height * 0.9,
  // },
  wrapper: {
    paddingHorizontal: 16,
  },
  scrollView: {
    flex: 1,
  },
  scrollViewContainer: {
    paddingBottom: 40,
  },
  timePickerTitle: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: 16,
  },
});

export default styles;

export const calendarTheme = (spaceType?: SpaceType) => ({
  todayTextColor: colors.black,
  selectedDayTextColor: colors.white,
  arrowColor: colors.black,
  textDayFontFamily: fontFamily.bold,
  textMonthFontFamily: fontFamily.bold,
  textDayHeaderFontFamily: fontFamily.bold,
  textMonthFontSize: fontSizes.md,
  textDayHeaderFontSize: fontSizes.md,
  textDisabledColor: alphaColor(colors.black, 0.1),
  monthTextColor: colors.darkGrey,
  textSectionTitleColor: getSpaceTypeColor(spaceType),
});

export const todayCustomMark = {
  container: {
    backgroundColor: colors.lightGrey,
    borderRadius: 5,
  },
};

export const customDisabledDates = {
  container: {
    backgroundColor: colors.clear,
  },
  text: {
    color: alphaColor(colors.black, 0.1),
  },
};

export const selectedCustomMark = (spaceType?: SpaceType) => ({
  container: {
    backgroundColor: getSpaceTypeColor(spaceType),
    borderRadius: 5,
  },
});
