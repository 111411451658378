import { disabledSpaceDateApi, spacesApi } from '_/services/api';
import logger from '_/services/logger';
import { SpaceType } from '_/services/models/enums/space-type.enum';
import { startOfDay } from 'date-fns';
import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  SpaceContextData,
  SpacesModel,
  SpaceMapData,
  DisabledSpaceDateType,
} from '../services/models/spaces.model';

const SpaceContext = createContext<SpaceContextData>({} as SpaceContextData);
type SpaceProviderType = {
  children?: React.ReactNode;
};

export const SpaceProvider: React.FC<SpaceProviderType> = ({ children }) => {
  const [currentSpace, setCurrentSpace] = useState<SpacesModel>({} as SpacesModel);
  const [spaceId, setSpaceId] = useState('');
  const [spaceLayout, setSpaceLayout] = useState<string | undefined>();
  const [layoutMode, setLayoutMode] = useState<'list' | 'map'>('list');
  const [selectorOrSearch, setSelectorOrSearch] = useState<'selector' | 'search'>('selector');
  const [mapData, setMapData] = useState<SpaceMapData | null>({} as SpaceMapData);
  const [spaceType, setSpaceType] = useState<SpaceType>(SpaceType.WORK);
  const [disabledDateData, setDisabledDateData] = useState<DisabledSpaceDateType[]>([]);

  useEffect(() => {
    if (currentSpace.id) {
      getDisabledSpaceDates(currentSpace?.id);
    } else {
      setDisabledDateData([]);
    }
  }, [currentSpace?.id]);

  useEffect(() => {
    if (currentSpace.type === SpaceType.WORK && currentSpace.isMapVisible) {
      setLayoutMode('map');
    } else {
      setLayoutMode('list');
    }
  }, [currentSpace.isMapVisible, currentSpace.type]);

  const getSpacesbyLocationId = async ({ locationId }: Partial<SpacesModel>) => {
    try {
      const result = await spacesApi.getList({
        locationId,
      });
      const { data } = result;
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  const getSpaces = async () => {
    try {
      const result = await spacesApi.getList();
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  const getDisabledSpaceDates = async (id: string) => {
    const result = await disabledSpaceDateApi.getDatesBySpaceId({
      spaceId: id,
      'date[$gte]': startOfDay(new Date()),
    });
    setDisabledDateData(result.data);
  };

  return (
    <SpaceContext.Provider
      value={{
        spaceId,
        setSpaceId,
        getSpaces,
        getSpacesbyLocationId,
        spaceType,
        setSpaceType,
        spaceLayout,
        setSpaceLayout,
        layoutMode,
        setLayoutMode,
        mapData,
        setMapData,
        currentSpace,
        setCurrentSpace,
        selectorOrSearch,
        setSelectorOrSearch,
        disabledDateData,
      }}
    >
      {children}
    </SpaceContext.Provider>
  );
};

export function useSpaceContext(): SpaceContextData {
  const context = useContext(SpaceContext);

  if (!context) {
    throw new Error('useSpace must be used within an SpaceProvider');
  }

  return context;
}
