import { format } from 'date-fns';

export function formatDate(
  d: Date | number,
  f: string,
  o?:
    | {
        locale?: globalThis.Locale | undefined;
        weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
        firstWeekContainsDate?: number | undefined;
        useAdditionalWeekYearTokens?: boolean | undefined;
        useAdditionalDayOfYearTokens?: boolean | undefined;
      }
    | undefined
): string {
  try {
    return format(d, f, o);
  } catch (_) {
    return '';
  }
}
