import { colors, spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: spacing.md,
    borderColor: colors.lightGrey,
    marginHorizontal: spacing.md,
    borderWidth: 1,
    borderRadius: 8,
    alignItems: 'center',
    marginTop: spacing.md,
  },
  list: {
    paddingHorizontal: 16,
  },
  weekDays: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginVertical: spacing.sm,
  },
  lastDay: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: spacing.sm,
  },
  day: {
    paddingVertical: 10,
    paddingHorizontal: 8.75,
    borderRadius: 5,
  },
  tag: {
    position: 'absolute',
    top: -10,
    backgroundColor: '#FFF',
    paddingHorizontal: 6,
  },
});

export default styles;
