import { colors, spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  headerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 16,
  },
  list: {
    paddingHorizontal: 16,
  },
  seeMore: {
    marginTop: spacing.md,
    marginBottom: spacing.xxs,
  },
  buttonGroup: {
    paddingHorizontal: spacing.md,
    marginBottom: spacing.xxs,
  },
  checkinButton: {
    marginRight: 20,
  },
  separator: {
    backgroundColor: colors.lightGrey,
    opacity: 0.2,
    height: 8,
    width: '100%',
  },
  filters: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginVertical: spacing.sm,
  },
  filter: {
    paddingVertical: 10,
    borderRadius: 5,
    paddingHorizontal: 8.75,
  },
});

export default styles;
