import { Dimensions, StyleSheet } from 'react-native';

const { height } = Dimensions.get('window');

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    margin: 0,
    width: '100%',
    maxWidth: 550,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    marginTop: height * 0.14,
    backgroundColor: '#fff',
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 9,
    paddingVertical: 40,
  },
});

export default styles;
