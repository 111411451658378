import L from 'leaflet';
import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';

interface WebLayoutImageProps {
  slotImage: string;
  bounds: L.LatLngBoundsExpression;
}

const WebLayoutImage: React.FC<WebLayoutImageProps> = ({ slotImage, bounds }) => {
  const map = useMap();

  useEffect(() => {
    const image = L.imageOverlay(slotImage, bounds as L.LatLngBoundsExpression).addTo(map);

    map.fitBounds(image.getBounds());
  }, [bounds, map, slotImage]);

  return <></>;
};

export default WebLayoutImage;
