import { spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    paddingVertical: spacing.lg,
    paddingHorizontal: 36,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    textAlign: 'center',
  },
  topText: {
    marginBottom: spacing.md,
  },
});

export default styles;
