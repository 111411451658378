import { colors } from '_/constants/theme';
import React from 'react';
import { ActivityIndicator, View } from 'react-native';

const AuthLoading: React.FC = () => {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <ActivityIndicator size="large" color={colors.black} />
    </View>
  );
};

export default AuthLoading;
