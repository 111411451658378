import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useAuth } from '_/hooks/AuthContext';
import { useOrganizationContext } from '_/hooks/OrganizationContext';
import AuthLoading from '_/screens/Signin/AuthLoading';
import React from 'react';

import AppRoutes from './AppRoutes';
import AuthRoutes from './AuthRoutes';
import LoginStackScreen from './LoginStackScreen';
import OrganizationDisabledStackScreen from './OrganizationDisabledStackScreen';
import { AppRoute } from './types';

export const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: '#FFFF',
  },
};

const RootStack = createStackNavigator();

export default function Routes() {
  const { user, member, authLoading } = useAuth();
  const { organizationData, isLoadingOrganization } = useOrganizationContext();
  function getRoute() {
    if (authLoading || isLoadingOrganization) {
      return (
        <RootStack.Navigator screenOptions={{ animationEnabled: true, headerShown: false }}>
          <RootStack.Screen name={AppRoute.PLACEHUB_AUTH_LOADING} component={AuthLoading} />
        </RootStack.Navigator>
      );
    }
    if (user) {
      if (member && organizationData.active) {
        return <AppRoutes />;
      }
      if (organizationData.id && !organizationData.active) {
        return <OrganizationDisabledStackScreen />;
      }
      return <AuthRoutes />;
    }

    return (
      <RootStack.Navigator
        screenOptions={{ animationEnabled: true, headerShown: false, presentation: 'card' }}
      >
        <RootStack.Screen name={AppRoute.LOGIN_CONTAINER} component={LoginStackScreen} />
      </RootStack.Navigator>
    );
  }

  return <NavigationContainer theme={MyTheme}>{getRoute()}</NavigationContainer>;
}
