import { colors, spacing } from '_/constants/theme';
import { Dimensions, Platform, StyleSheet } from 'react-native';

const { height, width } = Dimensions.get('window');

const styles = StyleSheet.create({
  wrapper: {
    height,
    maxWidth: 550,
    alignSelf: 'center',
    width,
    // position: 'absolute',
    // bottom: 0,
    marginBottom: 0,
  },
  background: {
    flex: 1,
  },
  modal: {
    height,
    backgroundColor: '#fff',
    alignItems: 'center',
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: -10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 6,
    elevation: 9,
    marginBottom: 0,
    padding: spacing.md,
  },
  loginButton: {
    marginTop: 20,
  },
  logo: {
    ...(Platform.OS === 'web' && {
      height: 74,
      width: 300,
    }),
    alignSelf: 'center',
    marginVertical: 40,
  },
  bottomImage: {
    ...(Platform.OS === 'web' && {
      width: 250,
      height: 600,
    }),
  },
  topImage: {
    alignSelf: 'flex-end',
    ...(Platform.OS === 'web' && {
      width: 250,
      height: 200,
    }),
  },
});

export default styles;
