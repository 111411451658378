import { Lang } from '_/services/models/enums/lang.enum';
import { t } from 'i18next';
import { LocaleConfig } from 'react-native-calendars';
export function calendarLanguage(userLang: Lang) {
  LocaleConfig.locales[userLang] = {
    monthNames: [
      t('months.january'),
      t('months.february'),
      t('months.march'),
      t('months.april'),
      t('months.may'),
      t('months.june'),
      t('months.july'),
      t('months.august'),
      t('months.september'),
      t('months.october'),
      t('months.november'),
      t('months.december'),
    ],
    monthNamesShort: [
      t('months.jan'),
      t('months.feb'),
      t('months.mar'),
      t('months.apr'),
      t('months.mayShort'),
      t('months.jun'),
      t('months.jul'),
      t('months.aug'),
      t('months.sep'),
      t('months.oct'),
      t('months.nov'),
      t('months.dec'),
    ],
    dayNames: [
      t('weekDays.sunday'),
      t('weekDays.monday'),
      t('weekDays.tuesday'),
      t('weekDays.wednesday'),
      t('weekDays.thursday'),
      t('weekDays.friday'),
      t('weekDays.saturday'),
    ],
    dayNamesShort: [
      t('weekDays.SU'),
      t('weekDays.M'),
      t('weekDays.T'),
      t('weekDays.W'),
      t('weekDays.TH'),
      t('weekDays.F'),
      t('weekDays.S'),
    ],
  };

  LocaleConfig.defaultLocale = userLang;
}
