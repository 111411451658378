import { spacing, colors } from '_/constants/theme';
import { SpaceType, getSpaceTypeColor } from '_/services/models/enums/space-type.enum';
import { StyleSheet } from 'react-native';

export const styles = (spaceType?: SpaceType) => {
  return StyleSheet.create({
    wrapper: {
      alignItems: 'flex-start',
      paddingHorizontal: spacing.md,
    },
    item: {
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: 4,
    },
    radioInput: {
      backgroundColor: colors.white,
      borderRadius: 24 * 0.5,
      width: 24,
      height: 24,
      marginRight: spacing.xs,
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      marginRight: spacing.sm,
    },
    selected: {
      backgroundColor: getSpaceTypeColor(spaceType),
      borderRadius: 8,
      width: 16,
      height: 16,
    },
    borderButton: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: spacing.md,
      paddingVertical: spacing.sm,
      borderWidth: 1,
      borderColor: colors.lightGrey,
      borderRadius: 8,
    },
  });
};
