import { images } from '_/assets';
import { colors } from '_/constants/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useLanguage } from '_/hooks/LanguageContext';
import logger from '_/services/logger';
import { Lang } from '_/services/models/enums/lang.enum';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import Modal from 'react-native-modal';

import Button from '../Button';
import DropdownInput from '../DropdownInput';
import Title from '../Title';
import { ChangeLanguageModalProps } from './interface';
import styles from './styles';

export default function ChangeLanguageModal({
  visible,
  handleClose,
  selectedLanguage,
  setSelectedLanguage,
}: ChangeLanguageModalProps) {
  const { changeLanguage, language: defaultLanguage } = useLanguage();
  const [language, setLanguage] = useState<Lang>(defaultLanguage.code);
  const [loading, setLoading] = useState(false);
  const { updateUser, user } = useAuth();
  const { t } = useTranslation();

  const languageOptions = [
    {
      label: t('language.pt-BR'),
      value: Lang.PTBR,
      icon: () => <Image source={images.brazilFlag} style={styles.flags} />,
    },
    {
      label: t('language.en'),
      value: Lang.EN,
      icon: () => <Image source={images.usFlag} style={styles.flags} />,
    },
  ];

  const handleChangeLanguage = useCallback(async () => {
    try {
      setLoading(true);

      if (!selectedLanguage) {
        if (user) {
          const data = {
            id: user.id,
            lang: language,
          };
          await updateUser(data);
        }
      }
      changeLanguage(selectedLanguage || language);
      showMessage({
        message: t('success'),
        description: t('changeLanguageModal.successMessage'),
        backgroundColor: colors.successGreen,
      });
      handleClose();
    } catch (error) {
      showMessage({
        message: t('error'),
        description: t('changeLanguageModal.errorMessage'),
        backgroundColor: colors.errorRed,
      });
      logger(error);
    }
    setLoading(false);
  }, [changeLanguage, handleClose, language, selectedLanguage, t, updateUser, user]);

  return (
    <Modal
      style={styles.modal}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      isVisible={visible}
      coverScreen
      hasBackdrop
      backdropOpacity={0.5}
      onBackdropPress={handleClose}
      onBackButtonPress={handleClose}
      onSwipeStart={handleClose}
    >
      <Title title={t('profileScreen.chooseLanguangeButton.chooseLanguage')} />
      <View style={styles.wrapper}>
        <DropdownInput
          options={languageOptions}
          setValue={setSelectedLanguage || setLanguage}
          value={selectedLanguage || language}
        />
        <Button
          backgroundColor={colors.lightBlue}
          textColor={colors.clear}
          onPress={handleChangeLanguage}
          loading={loading}
        >
          {t('confirm')}
        </Button>
      </View>
    </Modal>
  );
}
