import { slotsApi } from '_/services/api';
import logger from '_/services/logger';
import { SlotStatus } from '_/services/models/enums/slot-status.enum';
import { EventsResponse } from '_/services/models/events.model';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { SlotContextData, SlotsModel } from '../services/models/slots.model';

const SlotContext = createContext<SlotContextData>({} as SlotContextData);

type SlotType = {
  children?: React.ReactNode;
};
export const SlotProvider: React.FC<SlotType> = ({ children }) => {
  const [availableList, setAvailableList] = useState<SlotsModel[]>([]);
  const [reservedList, setReservedList] = useState<EventsResponse[]>([]);
  const [availableListPage, setAvailableListPage] = useState(0);
  const [availableListEndReached, setAvailableListEndReached] = useState(false);
  const [slotsList, setSlotsList] = useState<SlotsModel[]>([]);
  const [slotSearchValue, setSlotSearchValue] = useState<string | undefined>();
  const [isSlotModalVisible, setIsSlotModalVisible] = useState('');

  const getAvailableSlotsbySpaceId = useCallback(async (spaceId: string) => {
    try {
      const result = await slotsApi.getList({
        spaceId,
        '$sort[name]': 1,
      });

      const { data } = result;
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  }, []);

  const getAvailableSlots = async (spaceId: string) => {
    try {
      const result = await slotsApi.getList({
        spaceId,
        status: SlotStatus.AVAILABLE,
      });

      const { data } = result;
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  const getSlotAmenities = useCallback(async (slotId: string) => {
    try {
      const result = await slotsApi.getItem(slotId);
      const sortedAmenities = result?.amenities?.sort((a, b) => a?.name.localeCompare(b?.name));
      return sortedAmenities ?? [];
    } catch (error) {
      logger(error);
      throw error;
    }
  }, []);

  const slotAvailable = useMemo(() => {
    return availableList.some((slotAvailable) => slotAvailable.id === isSlotModalVisible);
  }, [availableList, isSlotModalVisible]);

  return (
    <SlotContext.Provider
      value={{
        getAvailableSlotsbySpaceId,
        getAvailableSlots,
        getSlotAmenities,
        availableList,
        setAvailableList,
        availableListPage,
        setAvailableListPage,
        availableListEndReached,
        setAvailableListEndReached,
        reservedList,
        setReservedList,
        slotsList,
        setSlotsList,
        slotSearchValue,
        setSlotSearchValue,
        isSlotModalVisible,
        setIsSlotModalVisible,
        slotAvailable,
      }}
    >
      {children}
    </SlotContext.Provider>
  );
};

export function useSlotContext(): SlotContextData {
  const context = useContext(SlotContext);

  if (!context) {
    throw new Error('useSlotContext must be used within an SlotProvider');
  }

  return context;
}
