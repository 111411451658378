import { fontFamily, fontSizes } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 2,
    borderBottomColor: 'rgba(200, 205, 208, 0.2)',
  },
  hourTextStyle: {
    fontFamily: fontFamily.regular,
    fontSize: fontSizes.md,
  },
  whoReserved: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  whoReservedName: {
    marginLeft: 8,
  },
  headerTextStyle: {
    fontFamily: fontFamily.regular,
    fontSize: fontSizes.md,
  },
});

export default styles;
