import { spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: spacing.md,
    marginTop: isIphoneX() ? 50 : 40,
    paddingVertical: spacing.sm,
    display: 'flex',
  },
  buttonWrapper: {
    paddingHorizontal: spacing.md,
    marginVertical: spacing.md,
    marginBottom: 12,
  },
});

export default styles;
