import { spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  whoReservedContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: spacing.md,
    paddingVertical: spacing.xs,
  },
  userInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  userArea: {
    flex: 1,
    flexDirection: 'column',
  },
  checkoutTag: {
    maxWidth: 90,
    marginLeft: spacing.sm,
    marginTop: spacing.xxxs,
  },
  slotInfo: {
    alignItems: 'flex-end',
    maxWidth: '40%',
  },
  username: {
    maxWidth: '70%',
    marginLeft: spacing.sm,
  },
});

export default styles;
