import { Text } from '_/components';
import { colors } from '_/constants/theme';
import { useSlotContext } from '_/hooks/SlotContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { SlotsModel } from '_/services/models/slots.model';
import React from 'react';
import { TouchableOpacity } from 'react-native';

import styles from './styles';

export interface SlotProps {
  slot: Omit<SlotsModel, 'amenities'>;
}

export default function Slot({ slot }: SlotProps) {
  const { setIsSlotModalVisible } = useSlotContext();
  const { spaceType } = useSpaceContext();

  return (
    <>
      <TouchableOpacity
        style={styles(spaceType).slot}
        onPress={() => setIsSlotModalVisible(slot.id)}
      >
        <Text
          fontSize={18}
          color={colors.black}
          fontType="medium"
          style={styles().slotName}
          numberOfLines={3}
        >
          {slot.name}
        </Text>
      </TouchableOpacity>
    </>
  );
}
