import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { useSlotContext } from '_/hooks/SlotContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import React from 'react';
import { FlatList, RefreshControl, View } from 'react-native';

import NoReservedList from './NoReservedList';
import WhoReserved from './WhoReserved';

export default function ReservedList() {
  const { reservedList } = useSlotContext();
  const { getReservedList, availableListLoading } = useEventContext();
  const { spaceId } = useSpaceContext();
  const { selectedEventDate } = useDateContext();

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        style={{ flex: 1 }}
        data={reservedList}
        ListEmptyComponent={<NoReservedList />}
        keyExtractor={(item) => item.id}
        renderItem={({ item: event }) => <WhoReserved event={event} />}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={availableListLoading === 'loadData'}
            onRefresh={() => {
              getReservedList({
                spaceId,
                startAtTime: selectedEventDate.start,
                endAtTime: selectedEventDate.end,
              });
            }}
          />
        }
      />
    </View>
  );
}
