import { createStackNavigator } from '@react-navigation/stack';
import { useAuth } from '_/hooks/AuthContext';
import { DeleteAccount } from '_/screens/DeleteAccount';
import MyReservations from '_/screens/MyReservations';
import NotificationSettings from '_/screens/NotificationSettings';
import Profile from '_/screens/Profile';
import ProfileSettings from '_/screens/ProfileSettings';
import RecurringReservations from '_/screens/RecurringReservations';
import RequestReport from '_/screens/Reports/RequestReport';
import { Role } from '_/services/models/enums/role.enum';
import React from 'react';

import { AppRoute } from './types';

const ProfileStack = createStackNavigator();

export default function ProfileStackScreen() {
  const { member } = useAuth();
  return (
    <ProfileStack.Navigator
      screenOptions={{ animationEnabled: true, headerShown: false, presentation: 'card' }}
    >
      <ProfileStack.Screen name={AppRoute.PROFILE} component={Profile} />
      <ProfileStack.Screen name={AppRoute.PROFILE_SETTINGS} component={ProfileSettings} />
      <ProfileStack.Screen name={AppRoute.NOTIFICATION_SETTINGS} component={NotificationSettings} />
      {member?.role === Role.ADMIN && (
        <ProfileStack.Screen name={AppRoute.REQUEST_REPORT} component={RequestReport} />
      )}
      <ProfileStack.Screen name={AppRoute.MY_RESERVATIONS} component={MyReservations} />
      <ProfileStack.Screen
        name={AppRoute.RECURRING_RESERVATIONS}
        component={RecurringReservations}
      />
      <ProfileStack.Screen name={AppRoute.DELETE_ACCOUNT} component={DeleteAccount} />
    </ProfileStack.Navigator>
  );
}
