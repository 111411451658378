import 'leaflet/dist/leaflet.css';

import { PHLoading } from '_/components';
import general from '_/config/general';
import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { useSlotContext } from '_/hooks/SlotContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { endOfDay, startOfDay } from 'date-fns';
import { CRS } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer } from 'react-leaflet';
import { View } from 'react-native';

import NoAvailableSlots from '../Workstation/StatusTab/AvailableList/NoAvailableSlots';
import MapMarker from './WebLayoutMap/CustomMarker.web';
import WebLayoutImage from './WebLayoutMap/LayoutImage.web';
import SearchList from './WebLayoutMap/Search.web';
import { styles } from './styles';

type Coordinate = [number, number];

const LayoutMap: React.FC = () => {
  const { t } = useTranslation();
  const { currentSpace, spaceId } = useSpaceContext();
  const { slotsList } = useSlotContext();
  const [eventModalVisible, setEventModalVisible] = useState<string | undefined>();
  const { selectedEventDate, selectedDay } = useDateContext();
  const { getAvailableList, maxCapacityReached } = useEventContext();
  const OVERLAY_TOP_LEFT_COORDINATE: Coordinate = currentSpace.mapData?.overlayTopLeft
    ? currentSpace.mapData.overlayTopLeft
    : [0.102, 0];
  const OVERLAY_BOTTOM_RIGHT_COORDINATE: Coordinate = currentSpace.mapData?.overlayBottomRight
    ? currentSpace.mapData.overlayBottomRight
    : [20.102, 20];
  const bounds = [OVERLAY_TOP_LEFT_COORDINATE, OVERLAY_BOTTOM_RIGHT_COORDINATE];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let startAtTime = selectedEventDate.start;
    let endAtTime = selectedEventDate.end;

    if (currentSpace.singleSlotEventPerDay) {
      startAtTime = startOfDay(selectedEventDate.start);
      endAtTime = endOfDay(selectedEventDate.end);
    }

    getAvailableList({
      limit: general.apiSlotLimitMap,
      reset: true,
      spaceId,
      startAtTime,
      endAtTime,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEventDate.start, selectedEventDate.end, selectedDay]);

  if (maxCapacityReached) {
    return <NoAvailableSlots maxCapacityReached={maxCapacityReached} />;
  }

  return (
    <View style={{ ...styles.container, flexGrow: 2 }}>
      <PHLoading message={t('mapScreen.loadingMap')} loading={loading} />
      <MapContainer
        style={{ ...styles.map, height: 500 }}
        center={[10.102, 10]}
        zoom={4.5}
        minZoom={currentSpace.mapData?.minZoomLevel || 4.5}
        maxZoom={currentSpace.mapData?.maxZoomLevel || 7}
        maxBounds={[
          [20.102, 0],
          [0.102, 20],
        ]}
        crs={CRS.Simple}
        whenReady={() => setLoading(false)}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {slotsList?.map((slot) => (
          <MapMarker
            slot={slot}
            key={slot.id}
            eventModalVisible={eventModalVisible}
            setEventModalVisible={setEventModalVisible}
          />
        ))}
        <WebLayoutImage
          slotImage={currentSpace.mapImageUrl as string}
          bounds={bounds as [Coordinate, Coordinate]}
        />
        <SearchList />
      </MapContainer>
    </View>
  );
};

export default LayoutMap;
