import { ReportsModel } from '../models/reports.model';
import RequestCreator from './helpers/requestCreator';

class Report extends RequestCreator<ReportsModel> {
  private uploadReportPictures = '/uploads/organization-assets';

  uploadPictures = async (images: string[]) => {
    if (!images.length) {
      return [];
    }

    const formData = new FormData();

    images.forEach((image, index) => {
      formData.append('file', {
        type: 'image/jpeg',
        name: `${index}.jpg`,
        uri: image,
      } as any);
    });

    const { data } = await this.api.post(this.uploadReportPictures, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data.assetsUrl;
  };
}

const api = new Report('/reports');
export default api;
