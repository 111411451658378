import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Header, Text, Button, WebWrapper } from '_/components';
import Select from '_/components/Select';
import { colors, fontSizes } from '_/constants/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useOrganizationContext } from '_/hooks/OrganizationContext';
import { calendarLanguage } from '_/hooks/calendarLanguage';
import useFirebaseAnalytics from '_/hooks/useFirebaseAnalytics';
import { eventsApi } from '_/services/api/';
import { addMonths, endOfMonth, startOfDay, sub, subMonths } from 'date-fns';
import { endOfDay } from 'date-fns/esm';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import { Calendar, DateData } from 'react-native-calendars';
import { MarkedDates } from 'react-native-calendars/src/types';
import { showMessage } from 'react-native-flash-message';

import { customMark, styles, calendarTheme } from './styles';
type ReportType = 'today' | 'custom' | 'full' | 'month/year';

interface SelectedRange {
  start?: string;
  end?: string;
}

export default function RequestReport() {
  const navigation = useNavigation<any>();
  const { organizationData } = useOrganizationContext();
  const { user } = useAuth();
  const { t } = useTranslation();
  const { analyticsLogEvent } = useFirebaseAnalytics();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<ReportType>('today');
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [selectedRange, setSelectedRange] = useState<SelectedRange>({});
  const [markedDates, setMarkedDates] = useState<MarkedDates>({});
  const months = useMemo(() => {
    return [
      { text: t('months.january'), value: 0, key: 'january' },
      { text: t('months.february'), value: 1, key: 'february' },
      { text: t('months.march'), value: 2, key: 'march' },
      { text: t('months.april'), value: 3, key: 'april' },
      { text: t('months.may'), value: 4, key: 'may' },
      { text: t('months.june'), value: 5, key: 'june' },
      { text: t('months.july'), value: 6, key: 'july' },
      { text: t('months.august'), value: 7, key: 'august' },
      { text: t('months.september'), value: 8, key: 'september' },
      { text: t('months.october'), value: 9, key: 'octuber' },
      { text: t('months.november'), value: 10, key: 'november' },
      { text: t('months.december'), value: 11, key: 'december' },
    ];
  }, [t]);

  const yearsForSelect = useMemo(() => {
    const createdAtOrganization = new Date(organizationData.createdAt).getFullYear();
    const maxReportingYear = new Date().getFullYear() + 1;
    const years = Array.from(
      { length: maxReportingYear - createdAtOrganization + 1 },
      (_, index) => {
        const year = createdAtOrganization + index;
        return { text: String(year), value: year, key: year };
      }
    );
    return years;
  }, [organizationData]);

  useEffect(() => {
    calendarLanguage(user.lang);
  }, [user.lang]);

  const disableButton = useMemo(() => {
    return selected === 'custom' && (!selectedRange.start || !selectedRange.end);
  }, [selected, selectedRange]);

  const start = useMemo(() => {
    if (selected === 'today') {
      return startOfDay(new Date());
    }
    if (selected === 'month/year') {
      return startOfDay(new Date(year, month));
    }
    if (selected === 'custom' && selectedRange.start) {
      return startOfDay(new Date(selectedRange.start + 'T00:00:00'));
    }
    return sub(startOfDay(new Date()), { days: Number(selected) });
  }, [selected, month, year, selectedRange]);

  const end = useMemo(() => {
    if (selected === 'today') {
      return endOfDay(new Date());
    }
    if (selected === 'month/year') {
      return endOfMonth(new Date(year, month));
    }
    if (selected === 'custom' && selectedRange.end) {
      return endOfDay(new Date(selectedRange.end + 'T00:00:00'));
    }
  }, [selected, month, year, selectedRange]);

  const getDatesBetweenRange = (start: string, end: string) => {
    const dates: MarkedDates = {};
    const startDate = new Date(start);
    const endDate = new Date(end);
    while (startDate <= endDate) {
      const dateString = startDate.toISOString().slice(0, 10);
      dates[dateString] = {
        selected: true,
        textColor: 'white',
        customStyles: customMark,
      };
      startDate.setDate(startDate.getDate() + 1);
    }
    return dates;
  };

  const onDayPress = (day: DateData) => {
    if (!selectedRange.start || (selectedRange.start && selectedRange.end)) {
      setMarkedDates({
        [day.dateString]: {
          startingDay: true,
          selected: true,
          textColor: 'white',
          customStyles: customMark,
        },
      });
      setSelectedRange({ start: day.dateString, end: undefined });
    } else if (selectedRange.start && !selectedRange.end) {
      if (day.dateString < selectedRange.start) {
        setSelectedRange({ start: day.dateString, end: selectedRange.start });
        setMarkedDates({ ...getDatesBetweenRange(day.dateString, selectedRange.start) });
      } else {
        setSelectedRange({ ...selectedRange, end: day.dateString });
        setMarkedDates({ ...getDatesBetweenRange(selectedRange.start, day.dateString) });
      }
    }
  };

  const { startLimit, endLimit } = useMemo(() => {
    let startLimit;
    let endLimit;

    if (start && !end) {
      startLimit = subMonths(start as Date, 3).toDateString();
      endLimit = addMonths(start, 3).toDateString();
      return { startLimit, endLimit };
    }

    return { startLimit, endLimit };
  }, [start, end]);

  const handleRequestReport = async () => {
    setLoading(true);
    try {
      await eventsApi.requestReport({
        start,
        end,
      });
      showMessage({
        message: t('success'),
        description: t('requestReportScreen.successMessages.resquesSent'),
        backgroundColor: colors.successGreen,
      });
      analyticsLogEvent({
        name: 'requestReport',
        properties: {
          name: 'requestReport',
          screen: 'Request Report',
          purpose: 'Request Report',
        },
      });
      navigation.goBack();
    } catch (error) {
      showMessage({
        message: t('error'),
        description: t('requestReportScreen.errorMessages.requestFailed'),
        backgroundColor: colors.errorRed,
      });
    }
    setLoading(false);
  };

  return (
    <WebWrapper>
      <Header screenName={t('requestReportScreen.requestReport')} />
      <View style={styles.wrapper}>
        <Text fontSize={fontSizes.md2} fontType="medium">
          {t('requestReportScreen.requestReportMessage')}
        </Text>
        <View style={styles.radioInputWrapper}>
          <TouchableOpacity style={styles.radioInputContainer} onPress={() => setSelected('today')}>
            <View style={styles.radioInput}>
              {selected === 'today' && <View style={styles.selected} />}
            </View>
            <Text fontSize={fontSizes.md} fontType="medium">
              {t('requestReportScreen.reportToday')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.radioInputContainer}
            onPress={() => setSelected('month/year')}
          >
            <View style={styles.radioInput}>
              {selected === 'month/year' && <View style={styles.selected} />}
            </View>
            <Text fontSize={fontSizes.md} fontType="medium">
              {t('requestReportScreen.reportMonthYear')}
            </Text>
          </TouchableOpacity>
          {selected === 'month/year' && (
            <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <Select
                text={t('month')}
                options={months}
                defaultValue={month}
                onChangeSelect={(value: string | number) => setMonth(Number(value))}
              />
              <Select
                text={t('year')}
                options={yearsForSelect}
                defaultValue={year}
                onChangeSelect={(value: string | number) => setYear(Number(value))}
              />
            </View>
          )}
          <TouchableOpacity
            style={styles.radioInputContainer}
            onPress={() => setSelected('custom')}
          >
            <View style={styles.radioInput}>
              {selected === 'custom' && <View style={styles.selected} />}
            </View>
            <Text fontSize={fontSizes.md} fontType="medium">
              {t('requestReportScreen.reportCustomCalendar')}
            </Text>
          </TouchableOpacity>
          {selected === 'custom' && (
            <Calendar
              theme={calendarTheme}
              minDate={startLimit}
              maxDate={endLimit}
              onDayPress={onDayPress}
              markingType="custom"
              markedDates={markedDates}
              renderArrow={(direction) => (
                <Feather name={`chevron-${direction}` as any} size={28} color={colors.darkGrey} />
              )}
            />
          )}
        </View>
        <Button
          backgroundColor={colors.successGreen}
          textColor={colors.white}
          onPress={handleRequestReport}
          loading={loading}
          disabled={disableButton}
          style={{ opacity: disableButton ? 0.4 : 1 }}
        >
          {t('request')}
        </Button>
      </View>
    </WebWrapper>
  );
}
