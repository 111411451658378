import { Feather } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  ArrowBackHeader,
  DropdownInput,
  Button,
  TextArea,
  Tag,
  Text,
  ModalConfirmation,
} from '_/components';
import { colors, spacing } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { useLanguage } from '_/hooks/LanguageContext';
import { useLocationContext } from '_/hooks/LocationContext';
import { useOrganizationContext } from '_/hooks/OrganizationContext';
import { useReportsContext } from '_/hooks/ReportsContext';
import useFirebaseAnalytics from '_/hooks/useFirebaseAnalytics';
import { reportsApi } from '_/services/api';
import logger from '_/services/logger';
import { ReportPriority, reportPriorityColor } from '_/services/models/enums/report-priority.enum';
import { ReportType } from '_/services/models/enums/report-type.enum';
import { LocationsModel } from '_/services/models/locations.model';
import { ReportsModel } from '_/services/models/reports.model';
import { parseISO } from 'date-fns';
import * as ImagePicker from 'expo-image-picker';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  StyleSheet,
  ScrollView,
  Platform,
  TouchableOpacity,
  Image,
  Dimensions,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { isIphoneX } from 'react-native-iphone-x-helper';

import ImageModal from './ImageModal';

interface ParamsProps {
  report?: ReportsModel;
}

export default function CreateReport() {
  const { t } = useTranslation();
  const { language, locale } = useLanguage();
  const route = useRoute();
  const params = route.params as ParamsProps;
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<ReportType | null>(null);
  const [location, setLocation] = useState<string | null>(null);
  const [priority, setPriority] = useState<ReportPriority | null>(null);
  const [reportImages, setReportImages] = useState<string[]>([]);
  const [pickImageOptionsModal, setPickImageOptionsModal] = useState(false);
  const [imageCarouselModal, setImageCarouselModal] = useState(false);
  const [description, setDescription] = useState('');
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const { locations } = useLocationContext();
  const { organizationData } = useOrganizationContext();
  const navigation = useNavigation<any>();
  const { getOpenReports } = useReportsContext();
  const { analyticsLogEvent } = useFirebaseAnalytics();
  const report = params?.report;

  const locationsOptions = useMemo(() => {
    const locationsAvailable = locations?.data?.filter(
      (location: LocationsModel) => location.isReportEnabled === true
    );

    const options =
      locationsAvailable?.map((location: LocationsModel) => ({
        label: location.name,
        value: location.id,
      })) || [];

    return options;
  }, [locations?.data]);

  const handleCreateReport = async () => {
    if (!type) {
      return showMessage({
        message: t('error'),
        description: t('reportsScreen.errorMessages.typeRequired'),
        backgroundColor: colors.errorRed,
      });
    }

    if (!location) {
      return showMessage({
        message: t('error'),
        description: t('reportsScreen.errorMessages.locationRequired'),
        backgroundColor: colors.errorRed,
      });
    }

    if (!priority) {
      return showMessage({
        message: t('error'),
        description: t('reportsScreen.errorMessages.priorityRequired'),
        backgroundColor: colors.errorRed,
      });
    }

    if (!description) {
      return showMessage({
        message: t('error'),
        description: t('reportsScreen.errorMessages.descriptionRequired'),
        backgroundColor: colors.errorRed,
      });
    }

    if (organizationData.isReportImageRequired && reportImages.length === 0) {
      return showMessage({
        message: t('error'),
        description: t('reportsScreen.errorMessages.imagesRequired'),
        backgroundColor: colors.errorRed,
      });
    }

    try {
      setLoading(true);

      const pictureUrls = await reportsApi.uploadPictures(reportImages);

      await reportsApi.insert({
        type,
        locationId: location,
        priority,
        description: description.trim(),
        pictureUrls,
      });
      showMessage({
        message: t('success'),
        description: t('reportsScreen.successMessages.ticketOpened'),
        backgroundColor: colors.successGreen,
      });
      analyticsLogEvent({
        name: 'createReport',
        properties: {
          name: 'ceateReport',
          screen: 'Reports Page',
          purpose: 'Register a report',
        },
      });
      navigation.goBack();
      await getOpenReports(true);
    } catch (error: any) {
      setLoading(false);
      logger(error);
      analyticsLogEvent({
        name: 'errorCreateReport',
        properties: {
          name: 'errorCreateReport',
          screen: 'Reports Page',
          purpose: 'Register a report',
        },
      });
      showMessage({
        message: t('error'),
        description: t('reportsScreen.errorMessages.createTicketFailed'),
        backgroundColor: colors.errorRed,
      });
    }
  };

  const pickImage = useCallback(
    async (type: 'library' | 'camera') => {
      const libraryPermission = (await ImagePicker.requestMediaLibraryPermissionsAsync()).status;
      const cameraPermissons = (await ImagePicker.requestCameraPermissionsAsync()).status;
      if (libraryPermission !== 'granted') {
        showMessage({
          message: t('error'),
          description: t('cameraErrors.noLibraryPermission'),
          backgroundColor: colors.errorRed,
        });
      }
      if (cameraPermissons !== 'granted') {
        showMessage({
          message: t('error'),
          description: t('cameraErrors.noCameraPermission'),
          backgroundColor: colors.errorRed,
        });
      }

      if (type === 'camera') {
        const result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: false,
          aspect: [4, 3],
          quality: 0.1,
        });
        if (!result.canceled && result.assets.length) {
          const uris = result.assets.map((asset) => asset.uri);
          setReportImages((prevImages) => prevImages.concat(...uris));
        }
      } else {
        const result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: false,
          aspect: [4, 3],
          quality: 0.1,
        });

        if (!result.canceled && result.assets.length) {
          const uris = result.assets.map((asset) => asset.uri);
          setReportImages((prevImages) => prevImages.concat(...uris));
        }
      }
      setPickImageOptionsModal(false);
    },
    [t]
  );

  const handleRemoveImage = useCallback((index: number) => {
    setReportImages((prevImages) => prevImages.filter((_, i) => i !== index));
  }, []);

  const handleOpenImageCarousel = useCallback((index: number) => {
    setImageCarouselModal(true);
    setSelectedImageIndex(index);
  }, []);

  const HandleReportImages = useCallback(() => {
    if (!report) {
      return null;
    }

    return (
      <View style={styles.imageRow}>
        {report.pictureUrls?.map((image, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={[
                styles.addPictureButton,
                { marginRight: index !== report?.pictureUrls?.length ? spacing.sm : 0 },
              ]}
              onPress={() => handleOpenImageCarousel(index)}
            >
              <Image source={{ uri: image }} style={styles.addPictureButton} />
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }, [handleOpenImageCarousel, report]);

  const handleSelectImage = useCallback(
    (index: number) => {
      if (report?.pictureUrls) {
        const image = report.pictureUrls.find((_, i) => i === index);
        return image;
      }
    },
    [report?.pictureUrls]
  );

  const HandleAddImage = useCallback(() => {
    if (reportImages.length === 0 && !report) {
      return (
        <Button
          iconSize={18}
          backgroundColor={colors.transparent}
          hasBorder
          textColor={colors.lightGrey}
          leftIcon="camera"
          onPress={() => setPickImageOptionsModal(true)}
        >
          {t('reportsScreen.addPictureButton')}
        </Button>
      );
    }

    if (reportImages.length > 0) {
      return (
        <View style={styles.imageRow}>
          {reportImages.map((image, index) => {
            return (
              <View key={index}>
                <Image
                  source={{ uri: image }}
                  style={[
                    styles.addPictureButton,
                    { marginRight: index !== reportImages.length ? spacing.sm : 0 },
                  ]}
                />
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => handleRemoveImage(index)}
                >
                  <Feather name="x" size={18} color={colors.white} />
                </TouchableOpacity>
              </View>
            );
          })}
          {reportImages.length >= 3 ? null : (
            <TouchableOpacity
              style={styles.addPictureButton}
              onPress={() => setPickImageOptionsModal(true)}
            >
              <Feather name="camera" size={24} color={colors.lightGrey} />
            </TouchableOpacity>
          )}
        </View>
      );
    }

    return null;
  }, [handleRemoveImage, report, reportImages, t]);

  return (
    <>
      <ScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={styles.scrollview}>
        <View style={styles.wrapper}>
          <ArrowBackHeader style={styles.header}>{t('reportsScreen.openReport')}</ArrowBackHeader>
          <View>
            {!!report && (
              <View style={styles.dateContainer}>
                <Tag
                  text={t(`reportsScreen.${report?.priority}`)}
                  backgroundColor={reportPriorityColor[report?.priority as ReportPriority]}
                  style={styles.tag}
                />
                <Text>
                  {t('reportsScreen.openedAt')}:{' '}
                  <Text fontType="bold">
                    {formatDate(
                      parseISO(report?.createdAt as unknown as string),
                      language.format?.full_date,
                      {
                        locale,
                      }
                    )}
                  </Text>
                </Text>

                {!!report?.completedAt && (
                  <Text style={styles.completedAtText}>
                    {t('reportsScreen.closedAt')}:{' '}
                    <Text fontType="bold">
                      {formatDate(
                        parseISO(report.completedAt as unknown as string),
                        language.format?.full_date,
                        {
                          locale,
                        }
                      )}
                    </Text>
                  </Text>
                )}
              </View>
            )}
            <View style={Platform.OS !== 'android' && { zIndex: 999999 }}>
              <DropdownInput
                value={report?.type || type}
                disabled={!!report?.type || loading}
                setValue={setType}
                zIndex={5000}
                tag={`${t('type')} *`}
                style={styles.input}
                options={[
                  { label: `${t(`reportsScreen.${ReportType.DEFECT}`)}`, value: ReportType.DEFECT },
                  {
                    label: `${t(`reportsScreen.${ReportType.IMPROVEMENT}`)}`,
                    value: ReportType.IMPROVEMENT,
                  },
                  {
                    label: `${t(`reportsScreen.${ReportType.CLEANING}`)}`,
                    value: ReportType.CLEANING,
                  },
                  {
                    label: `${t(`reportsScreen.${ReportType.JUSTIFICATION_OF_ABSENCE}`)}`,
                    value: ReportType.JUSTIFICATION_OF_ABSENCE,
                  },
                  { label: `${t(`reportsScreen.${ReportType.OTHER}`)}`, value: ReportType.OTHER },
                ]}
              />
            </View>
            <View style={Platform.OS !== 'android' && { zIndex: 999998 }}>
              <DropdownInput
                value={report?.location?.name || location}
                placeholder={report?.location?.name}
                disabled={!!report?.location || loading}
                setValue={setLocation}
                zIndex={4000}
                tag={`${t('location')} *`}
                style={styles.input}
                options={locationsOptions || []}
              />
            </View>
            <View style={Platform.OS !== 'android' && { zIndex: 999997 }}>
              <DropdownInput
                value={report?.priority || priority}
                disabled={!!report?.priority || loading}
                setValue={setPriority}
                zIndex={3000}
                tag={`${t('priority')} *`}
                style={styles.input}
                options={[
                  {
                    label: `${t(`reportsScreen.${ReportPriority.HIGH}`)}`,
                    value: ReportPriority.HIGH,
                  },
                  {
                    label: `${t(`reportsScreen.${ReportPriority.MEDIUM}`)}`,
                    value: ReportPriority.MEDIUM,
                  },
                  {
                    label: `${t(`reportsScreen.${ReportPriority.LOW}`)}`,
                    value: ReportPriority.LOW,
                  },
                ]}
              />
            </View>
            <TextArea
              placeholder={t('reportsScreen.descriptionPlaceholder')}
              tag={`${t('description')} *`}
              onChangeText={setDescription}
              value={report?.description || description}
              editable={!report?.description && !loading}
              constainerStyle={styles.input}
            />
          </View>
          <HandleReportImages />
          <HandleAddImage />
          {!report && (
            <Button
              loading={loading}
              backgroundColor={colors.lightBlue}
              textColor={colors.white}
              onPress={handleCreateReport}
            >
              {t('send')}
            </Button>
          )}
        </View>
      </ScrollView>
      <ModalConfirmation
        title="Adicionar imagem"
        description="Gostaria de tirar uma foto ou escolher da biblioteca?"
        isVisible={pickImageOptionsModal}
        rightButtonText="Tirar Foto"
        leftButtonText="Biblioteca"
        onCloseModal={() => setPickImageOptionsModal(false)}
        onConfirmModal={() => pickImage('camera')}
        onCancelButton={() => pickImage('library')}
      />
      <ImageModal
        isVisible={imageCarouselModal}
        onCloseModal={() => setImageCarouselModal(false)}
        selectedImage={handleSelectImage(selectedImageIndex)}
      />
    </>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: spacing.md,
    marginTop: isIphoneX() ? 50 : 40,
    paddingVertical: spacing.sm,
  },
  scrollview: {
    paddingBottom: 40,
  },
  input: {
    marginTop: spacing.md,
  },
  header: {
    paddingBottom: 21,
  },
  tag: {
    maxWidth: 60,
    marginBottom: spacing.xs,
  },
  completedAtText: {
    marginTop: 6,
  },
  dateContainer: {
    marginBottom: 8,
  },
  addPictureButton: {
    height: (width - 62) / 3,
    width: (width - 62) / 3,
    borderRadius: 10,
    borderColor: colors.lightGrey,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageRow: {
    flexDirection: 'row',
    marginTop: spacing.md,
  },
  closeButton: {
    position: 'absolute',
    padding: spacing.xxs,
    borderRadius: 100,
    backgroundColor: colors.red,
    right: 4,
    top: -8,
  },
});
