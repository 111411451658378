import { createStackNavigator } from '@react-navigation/stack';
import OnBoarding from '_/screens/OnBoarding';
import React from 'react';

import ProfileStackScreen from './ProfileStackScreen';
import { AppRoute } from './types';

const AuthStack = createStackNavigator();

export default function AuthRoutes() {
  return (
    <AuthStack.Navigator screenOptions={{ animationEnabled: true, headerShown: false }}>
      <AuthStack.Screen name={AppRoute.NO_CONTENT} component={OnBoarding} />
      <AuthStack.Screen name={AppRoute.PROFILE_STACK_SCREEN} component={ProfileStackScreen} />
    </AuthStack.Navigator>
  );
}
