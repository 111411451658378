import { useNavigation } from '@react-navigation/native';
import Amenities from '_/components/Amenities';
import { TIME_FORMAT } from '_/config/date';
import { colors, fontFamily, fontSizes } from '_/constants/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { useOrganizationContext } from '_/hooks/OrganizationContext';
import { useSlotContext } from '_/hooks/SlotContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import useFirebaseAnalytics from '_/hooks/useFirebaseAnalytics';
import { AppRoute } from '_/navigation/types';
import logger from '_/services/logger';
import { AmenitiesModel } from '_/services/models/amenities.model';
import { DropdownFieldOptionsRequest } from '_/services/models/dropdown-field-options.model';
import { ErrorCode } from '_/services/models/enums/error-code.enum';
import { RecurrenceType } from '_/services/models/enums/recurrence-type.enum';
import { getSpaceTypeColor, spaceTypeName } from '_/services/models/enums/space-type.enum';
import { SlotsModel } from '_/services/models/slots.model';
import { UsersModel } from '_/services/models/users.model';
import { addMinutes, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import FlashMessage from 'react-native-flash-message';
import Modal from 'react-native-modal';

import Button from '../Button';
import DropdownButton from '../DropdownButton';
import Text from '../Text';
import TimePicker from '../TimePicker';
import Title from '../Title';
import CustomRecurrenceModal from './CustomRecurrenceModal';
import CustomTextFieldModal from './CustomTextFieldModal';
import RecurrentReservationModal from './RecurrentReservationModal';
import SearchForMemberModal from './SearchForMembersModal';
import SelectRecurrenceModal from './SelectRecurrenceModal';
import styles from './styles';
import { useLocationContext } from '_/hooks/LocationContext';
import QuestionsField from '../QuestionsField';
import { CustomModalType, FieldValuesState, TCustomSlotType } from './interface';
import { fieldsToCheck } from '../QuestionsField/utils';
import CostCentersOptions from '../CostCentersOptions';

interface ModalInterface {
  onBackdropPress: () => void;
  onBackButtonPress: () => void;
  onSwipeStart: () => void;
  recurrenceType: RecurrenceType;
  setRecurrenceType: React.Dispatch<React.SetStateAction<RecurrenceType>>;
  slot: TCustomSlotType;
  pickerDisabled?: boolean;
}

export default function ConfirmReserveModal({
  onBackdropPress,
  onBackButtonPress,
  slot,
  recurrenceType: recurrenceTypeProps,
  setRecurrenceType: setRecurrenceTypeProps,
  pickerDisabled,
}: ModalInterface) {
  const navigation = useNavigation<any>();
  const { analyticsLogEvent } = useFirebaseAnalytics();
  const { t } = useTranslation();
  const [recurrenceType, setRecurrenceType] = useState<RecurrenceType>(
    recurrenceTypeProps || RecurrenceType.ONCE
  );

  const internalSetReccurenceType = (value: RecurrenceType) => {
    if (setRecurrenceTypeProps) {
      setRecurrenceType(value);
      return setRecurrenceTypeProps(value);
    }
    return setRecurrenceType(value);
  };

  const [recurrenceModalVisible, setRecurrenceModalVisible] = useState(false);
  const [recurrentReservationModalVisible, setRecurrentReservationModalVisible] = useState(false);
  const [customRecurrenceModalVisible, setCustomRecurrenceModalVisible] = useState(false);
  const [memberModalVisible, setMemberModalVisible] = useState(false);
  const [customModalVisible, setCustomModalVisible] = useState<CustomModalType>();

  const [fieldValues, setFieldValues] = useState<FieldValuesState>({
    dropdownField1: undefined,
    dropdownField2: undefined,
    textField1: undefined,
    textField2: undefined,
  });

  const [amenities, setAmenities] = useState<AmenitiesModel[]>([]);
  const [title, setTitle] = useState<string>();
  const [forMember, setForMember] = useState<UsersModel>();
  const [costCenter, setCostCenter] = useState<DropdownFieldOptionsRequest>({ id: '', value: '' });

  const {
    dayOfWeek,
    dayOfMonth,
    selectedEventDate,
    isIntervalInvalid,
    hasStartTimeBeforeCurrent,
    slotWithConflicts,
    setSelectedEventDate,
    checkOpenCloseHourMin,
  } = useDateContext();

  const hasConflict = (slotWithConflicts ?? [])?.includes(slot.id);

  const {
    createReservationEvent,
    getTodayEvents,
    recurrenceStr,
    setRecurrenceStr,
    loading,
    modalMessage,
  } = useEventContext();

  const { spaceType, currentSpace } = useSpaceContext();
  const { organizationData } = useOrganizationContext();
  const { member, user } = useAuth();
  const { getSlotAmenities, isSlotModalVisible } = useSlotContext();
  const { getCostCenterByLocationId } = useLocationContext();

  const showCustomFields = Boolean(member?.showCustomFields);

  const costCentersArray = getCostCenterByLocationId(currentSpace.locationId)?.costCenters ?? [];
  const showCostCenter =
    slot.costCenterEnabled && !!costCentersArray.length && member?.showCustomFields;

  useEffect(() => {
    let mounted = true;

    async function getData() {
      const amenitiesResponse = await getSlotAmenities(slot.id);
      if (mounted) {
        setAmenities(amenitiesResponse);
      }
    }

    getData();

    return () => {
      mounted = false;
      internalSetReccurenceType(RecurrenceType.ONCE);
    };
  }, [getSlotAmenities, slot.id]);

  const fieldsMetadata = slot.fieldsMetadata ?? {};

  async function handleCreateReservationEvent(createEvent: boolean, slotId?: string) {
    if (checkOpenCloseHourMin()) {
      return modalMessage.current.showMessage({
        message: t('error'),
        description: t('workstationScreen.errorMessages.checkOpenCloseHourMinError', {
          openAt: currentSpace.openAtHourMin ?? '00:00',
          closeAt: currentSpace.closeAtHourMin ?? '23:59',
        }),
        backgroundColor: colors.errorRed,
      });
    }

    if (slot.isDescriptionRequired && !title) {
      return modalMessage.current.showMessage({
        message: t('error'),
        description: t('workstationScreen.errorMessages.titleRequired'),
        backgroundColor: colors.errorRed,
      });
    }

    if (showCostCenter && !costCenter.value) {
      return modalMessage.current.showMessage({
        message: t('error'),
        description: t('workstationScreen.errorMessages.costCenterRequired'),
        backgroundColor: colors.errorRed,
      });
    }

    const showError = fieldsToCheck({ slot, fieldValues, fieldsMetadata, showCustomFields });

    if (showError) {
      return modalMessage.current.showMessage({
        message: t('error'),
        description: t('workstationScreen.errorMessages.quizRequired'),
        backgroundColor: colors.errorRed,
      });
    }

    try {
      await createReservationEvent({
        memberId: forMember?.members?.[0]?.id || member?.id,
        userId: member?.userId,
        slotId: slotId || slot.id,
        startAtTime: selectedEventDate.start,
        endAtTime: selectedEventDate.end,
        description: title,
        dropdownField1OptionId: fieldValues.dropdownField1?.id,
        dropdownField2OptionId: fieldValues.dropdownField2?.id,
        textField1Value: fieldValues.textField1,
        textField2Value: fieldValues.textField2,
        recurrenceStr,
        createEvent,
        costCenter: costCenter.value,
      });
      analyticsLogEvent({
        name: 'createEvent',
        properties: {
          name: 'ceateEvent',
          screen: 'Reserve Modal',
          purpose: 'Make a reservation',
        },
      });
      if (createEvent === true) {
        setRecurrenceStr('');
        onBackButtonPress();
        navigation.navigate(AppRoute.HOME);
      }
      await getTodayEvents();
    } catch (error: any) {
      const errorMessages: { [key: string]: string } = {
        [ErrorCode.HAS_CONFLICT]: t('workstationScreen.errorMessages.scheduleConflict'),
        [ErrorCode.HAS_USER_CONFLICT]: t('workstationScreen.errorMessages.alreadyScheduled'),
        [ErrorCode.RECURRENCE_LIMIT]: t('workstationScreen.errorMessages.noOvertime'),
        [ErrorCode.NO_ACCESS]: t('workstationScreen.errorMessages.noAccess'),
        [ErrorCode.NO_PAST_EVENT_SCHEDULE]: t('workstationScreen.errorMessages.noPastReservations'),
        [ErrorCode.HAS_MAX_CAPACITY_REACHED]: t(
          'workstationScreen.noAvailableSlots.maxCapacityReached'
        ),
        [ErrorCode.OPEN_CLOSE_SPACE_ERROR]: t(
          'workstationScreen.errorMessages.checkOpenCloseHourMinError',
          {
            openAt: currentSpace.openAtHourMin ?? '00:00',
            closeAt: currentSpace.closeAtHourMin ?? '23:59',
          }
        ),
        [ErrorCode.ORGANIZATION_DISABLED]: t(
          'workstationScreen.errorMessages.organizationDisabled'
        ),
        [ErrorCode.START_BEFORE_CURRENT_TIME]: t(
          'workstationScreen.errorMessages.startBeforeCurrentTime'
        ),
      };

      let errorMessage = t('workstationScreen.errorMessages.reservationFail');

      const errorCode = error?.response?.data?.data?.code;

      if (errorCode && errorMessages[errorCode]) {
        errorMessage = errorMessages[errorCode];
      }

      logger(error);
      modalMessage.current.showMessage({
        message: t('error'),
        description: errorMessage,
        backgroundColor: colors.errorRed,
      });
    }
  }

  return (
    <Modal
      style={styles.modal}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      isVisible={isSlotModalVisible === slot.id}
      coverScreen
      hasBackdrop
      propagateSwipe
      backdropOpacity={0.5}
      onBackdropPress={onBackButtonPress}
      onBackButtonPress={onBackButtonPress}
    >
      <FlashMessage
        floating
        position="top"
        duration={5000}
        titleStyle={{ fontFamily: fontFamily.bold }}
        textStyle={{ fontFamily: fontFamily.regular }}
        ref={modalMessage}
      />

      <ScrollView
        contentContainerStyle={styles.scrollViewContainer}
        showsVerticalScrollIndicator={false}
      >
        <Title
          style={styles.title}
          title={slot.name ? slot.name : `${spaceTypeName[spaceType]}`}
          hasCloseButton
          onPress={onBackdropPress}
        />
        <Amenities amenities={amenities} style={styles.amenities} />
        <View style={styles.wrapper}>
          <DropdownButton
            text={
              (forMember?.name === user.name
                ? t('workstationScreen.confirmModal.forMe')
                : forMember?.name) || t('workstationScreen.confirmModal.forMe')
            }
            icon="user"
            disabled={!organizationData.canCreateEventToFriend}
            onPress={() => setMemberModalVisible(true)}
          />
          {currentSpace.recurringEnabled && (
            <DropdownButton
              text={t(`recurrenceTypes.${recurrenceType}`)}
              icon="rotate-cw"
              onPress={() => setRecurrenceModalVisible(true)}
            />
          )}
          {slot.isDescriptionEnabled && (
            <DropdownButton
              text={title || t('workstationScreen.confirmModal.addATitle')}
              icon="edit"
              tag={title && t('workstationScreen.confirmModal.addATitle')}
              onPress={() => setCustomModalVisible('title')}
            />
          )}
          <CostCentersOptions
            locationId={currentSpace.location?.id as string}
            slotCostCenterEnabled={slot.costCenterEnabled}
            memberShowCustomFields={showCustomFields}
            costCenter={costCenter}
            setCostCenter={setCostCenter}
            customModalVisible={customModalVisible}
            setCustomModalVisible={setCustomModalVisible}
            spaceType={spaceType}
          />
          <QuestionsField
            slot={slot}
            fieldValues={fieldValues}
            setFieldValues={setFieldValues}
            memberShowCustomFields={showCustomFields}
            customModalVisible={customModalVisible}
            setCustomModalVisible={setCustomModalVisible}
          />
          <Text fontSize={fontSizes.lg2} fontType="bold" style={styles.dateInfo}>
            {dayOfWeek}, {dayOfMonth}
          </Text>
        </View>
        <TimePicker
          editable={!pickerDisabled}
          setEventDate={setSelectedEventDate}
          hasConflict={hasConflict}
        />
        {Number(currentSpace?.toleranceInMinutes) > 0 && (
          <View style={styles.cancelationMessage}>
            <Text>
              <Text fontSize={fontSizes.md2} fontType="bold">
                {t('workstationScreen.confirmModal.reservationCancel.important').concat(': ')}
              </Text>
              <Text fontSize={fontSizes.md2}>
                {t('workstationScreen.confirmModal.reservationCancel.messageBeforeTime').concat(
                  ' '
                )}
              </Text>
              <Text fontSize={fontSizes.md2} fontType="bold">
                {format(
                  addMinutes(
                    new Date(selectedEventDate.start),
                    Number(currentSpace?.toleranceInMinutes)
                  ),
                  TIME_FORMAT
                ).concat(' ')}
              </Text>
              <Text fontSize={fontSizes.md2}>
                {t('workstationScreen.confirmModal.reservationCancel.toDoCheck-in').concat(' ')}
              </Text>
              <Text fontSize={fontSizes.md2} fontType="bold">
                {t('workstationScreen.confirmModal.reservationCancel.check-in').concat(' ')}
              </Text>
              <Text fontSize={fontSizes.md2}>
                {t('workstationScreen.confirmModal.reservationCancel.or').concat(' ')}
              </Text>
              <Text fontSize={fontSizes.md2} fontType="bold">
                {t('workstationScreen.confirmModal.reservationCancel.canceled').concat(' ')}
              </Text>
              <Text fontSize={fontSizes.md2}>
                {t('workstationScreen.confirmModal.reservationCancel.automatically')}
              </Text>
            </Text>
          </View>
        )}
        <View style={styles.wrapper}>
          <Button
            style={{
              opacity: isIntervalInvalid || hasConflict || hasStartTimeBeforeCurrent ? 0.2 : 1,
            }}
            loading={loading}
            backgroundColor={getSpaceTypeColor(spaceType)}
            textColor={colors.white}
            onPress={
              recurrenceType === RecurrenceType.ONCE
                ? () => handleCreateReservationEvent(true)
                : () => setRecurrentReservationModalVisible(true)
            }
            disabled={isIntervalInvalid || hasConflict || hasStartTimeBeforeCurrent}
          >
            {recurrenceType === RecurrenceType.ONCE
              ? t('workstationScreen.confirmModal.reserve')
              : t('workstationScreen.confirmModal.seeDates')}
          </Button>
          <Button onPress={onBackdropPress}>{t('cancel')}</Button>
        </View>
        <SearchForMemberModal
          visible={memberModalVisible}
          hideDropdown={() => setMemberModalVisible(false)}
          slotId={slot.id}
          setForMember={setForMember}
        />
        <SelectRecurrenceModal
          recurrenceType={recurrenceType}
          setRecurrenceType={internalSetReccurenceType}
          visible={recurrenceModalVisible}
          setRecurrenceModalVisible={setRecurrenceModalVisible}
          setCustomModalVisible={setCustomRecurrenceModalVisible}
        />
        <CustomRecurrenceModal
          visible={customRecurrenceModalVisible}
          setCustomModalVisible={setCustomRecurrenceModalVisible}
          setRecurrenceType={internalSetReccurenceType}
        />
        <CustomTextFieldModal
          spaceType={spaceType}
          hideDropdown={() => setCustomModalVisible(undefined)}
          label={t('workstationScreen.confirmModal.addATitle')}
          setTextFieldValue={setTitle}
          value={title}
          visible={customModalVisible === 'title'}
        />
        <RecurrentReservationModal
          handleCreateEvent={handleCreateReservationEvent}
          setRecurrentReservationModalVisible={setRecurrentReservationModalVisible}
          visible={recurrentReservationModalVisible}
          selectedSlot={slot as SlotsModel}
        />
      </ScrollView>
    </Modal>
  );
}
