import { fontFamily } from '_/constants/theme';
import React, { useState, useRef, useEffect } from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TouchableWithoutFeedback,
} from 'react-native';
import FlashMessage from 'react-native-flash-message';
import Modal from 'react-native-modal';

import ChangePassword from './ChangePassword';
import RecoveryCode from './RecoveryCode';
import SendEmail from './SendEmail';
import styles from './styles';

enum Step {
  SendEmail = 'SendEmail',
  RecoveryCode = 'RecoveryCode',
  ChangePassword = 'ChangePassword',
}

interface ModalInterface {
  visible: boolean;
  onClose: () => void;
  selectedEmail: string;
}

export interface ForgotPasswordProps {
  userEmail: string;
  pin?: string;
  token?: any;
  setPin?: (pin: string) => void;
  setToken?: (token: any) => void;
  setUserEmail?: (email: string) => void;
  setNextStep: () => void;
  modalRef: React.RefObject<FlashMessage>;
}

export default function ForgotPasswordModal({ visible, onClose, selectedEmail }: ModalInterface) {
  const [step, setStep] = useState(Step.SendEmail);
  const [userEmail, setUserEmail] = useState('');
  const [pin, setPin] = useState('');
  const [token, setToken] = useState<any>();
  const modalRef = useRef<FlashMessage>(null);

  const handleClose = () => {
    setStep(Step.SendEmail);
    setUserEmail('');
    setPin('');
    setToken(undefined);
    onClose();
  };

  useEffect(() => {
    if (selectedEmail) {
      setUserEmail(selectedEmail);
    }
  }, [selectedEmail, visible]);

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <KeyboardAvoidingView enabled behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <Modal
          style={styles.modal}
          animationIn="slideInUp"
          animationOut="slideOutDown"
          isVisible={visible}
          coverScreen
          hasBackdrop
          backdropOpacity={0.5}
          onBackdropPress={handleClose}
          onBackButtonPress={handleClose}
          onSwipeStart={handleClose}
        >
          <FlashMessage
            floating
            position="top"
            duration={5000}
            titleStyle={{ fontFamily: fontFamily.bold }}
            textStyle={{ fontFamily: fontFamily.regular }}
            ref={modalRef}
          />
          <ScrollView keyboardShouldPersistTaps="always">
            {step === Step.SendEmail && (
              <SendEmail
                setNextStep={() => setStep(Step.RecoveryCode)}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                modalRef={modalRef}
              />
            )}
            {step === Step.RecoveryCode && (
              <RecoveryCode
                setNextStep={() => setStep(Step.ChangePassword)}
                userEmail={userEmail}
                pin={pin}
                setPin={setPin}
                token={token}
                setToken={setToken}
                modalRef={modalRef}
              />
            )}
            {step === Step.ChangePassword && (
              <ChangePassword
                setNextStep={handleClose}
                userEmail={userEmail}
                token={token}
                modalRef={modalRef}
              />
            )}
          </ScrollView>
        </Modal>
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  );
}
