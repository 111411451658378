import { Feather } from '@expo/vector-icons';
import Text from '_/components/Text';
import { colors, fontSizes, spacing } from '_/constants/theme';
import React, { useState } from 'react';
import {
  TouchableOpacity,
  View,
  StyleSheet,
  TouchableOpacityProps,
  LayoutChangeEvent,
} from 'react-native';

interface DropdownButtonProps extends TouchableOpacityProps {
  text: string | null | undefined;
  icon: 'user' | 'rotate-cw' | 'help-circle' | 'edit' | 'map-pin' | 'dollar-sign' | undefined;
  tag?: string | null;
}

export default function DropdownButton({ text, icon, tag, ...props }: DropdownButtonProps) {
  const { disabled, ...rest } = props;
  const [tagHeight, setTagHeight] = useState(0);

  const handleTagLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setTagHeight(+height.toFixed(0));
  };

  return (
    <TouchableOpacity style={{ ...styles.dropdownButton }} {...props} {...rest}>
      {!!tag && (
        <View style={{ maxWidth: '95%' }}>
          <View style={styles.tag} onLayout={handleTagLayout}>
            <Text color={colors.darkGrey} fontSize={fontSizes.sm}>
              {tag}
            </Text>
          </View>
        </View>
      )}
      <View
        style={{ ...styles.dropdownContent, opacity: text ? 1 : 0.4, marginTop: tagHeight / 2 }}
      >
        <View style={[styles.iconBox, { opacity: disabled ? 0.4 : 1 }]}>
          <Feather name={icon} size={24} color={colors.darkGrey} />
          {text ? (
            <Text style={styles.text} fontType="bold" fontSize={fontSizes.lg2} numberOfLines={1}>
              {text}
            </Text>
          ) : (
            <View style={styles.line} />
          )}
        </View>
        <Feather name="chevron-down" size={24} style={{ opacity: disabled ? 0.4 : 1 }} />
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  dropdownButton: {
    borderRadius: 8,
    marginBottom: spacing.md,
    borderWidth: 1,
    borderColor: colors.lightGrey,
    position: 'relative',
  },
  dropdownContent: {
    width: '100%',
    padding: spacing.sm,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconBox: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginRight: spacing.sm,
  },
  text: {
    width: '100%',
    marginLeft: spacing.sm,
    color: colors.darkGrey,
  },
  tag: {
    position: 'absolute',
    top: -10,
    left: 10,
    backgroundColor: '#fff',
    paddingHorizontal: 4,
  },
  line: {
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
    flex: 1,
    alignSelf: 'flex-end',
    marginLeft: spacing.sm,
  },
});
