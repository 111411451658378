import { Feather } from '@expo/vector-icons';
import { spacing } from '_/constants/theme';
import React from 'react';
import { StyleSheet, ImageSourcePropType, Image, Platform } from 'react-native';

interface ButtonIconProps {
  icon?: string | ImageSourcePropType;
  iconSize?: number;
  textColor?: string;
}

const ButtonIcon = ({ icon, textColor, iconSize = 24 }: ButtonIconProps) => {
  if (Platform.OS === 'web' && typeof icon === 'string') {
    return <Image source={{ uri: icon }} style={{ width: iconSize, height: iconSize }} />;
  }
  if (icon && typeof icon === 'number') {
    return <Image source={icon} style={{ width: iconSize, height: iconSize }} />;
  }
  if (icon && typeof icon === 'string') {
    return <Feather name={icon as any} size={iconSize} color={textColor} style={styles.icon} />;
  } else {
    return null;
  }
};

const styles = StyleSheet.create({
  icon: {
    marginHorizontal: spacing.xs,
  },
});

export default ButtonIcon;
