export enum ErrorCode {
  RECURRENCE_LIMIT = 'RECURRENCE_LIMIT',
  HAS_CONFLICT = 'HAS_CONFLICT',
  HAS_USER_CONFLICT = 'HAS_USER_CONFLICT',
  NO_ACCESS = 'NO_ACCESS',
  NO_PAST_EVENT_SCHEDULE = 'NO_PAST_EVENT_SCHEDULE',
  HAS_MAX_CAPACITY_REACHED = 'HAS_MAX_CAPACITY_REACHED',
  OPEN_CLOSE_SPACE_ERROR = 'OPEN_CLOSE_SPACE_ERROR',
  ORGANIZATION_DISABLED = 'ORGANIZATION_DISABLED',
  ONLY_ADMIN = 'ONLY_ADMIN',
  START_BEFORE_CURRENT_TIME = 'START_BEFORE_CURRENT_TIME',
}
