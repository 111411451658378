import { fontFamily, colors, scaleFont, fontSizes, ColorType, FontType } from '_/constants/theme';
import React from 'react';
import { Animated, StyleProp, StyleSheet, TextStyle, TextProps } from 'react-native';

interface CustomTextProps extends TextProps {
  children: any;
  fontType?: FontType;
  fontSize?: number;
  uppercase?: boolean;
  center?: boolean;
  color?: ColorType;
  marginTop?: number;
  marginBottom?: number;
  numberOfLines?: number;
  style?: StyleProp<TextStyle>;
}

const Text: React.FC<CustomTextProps> = ({
  children,
  fontType = 'regular',
  fontSize = fontSizes.md,
  color = colors.black,
  marginTop,
  marginBottom,
  center,
  uppercase,
  numberOfLines,
  style,
  ...props
}) => {
  return (
    <Animated.Text
      allowFontScaling={false}
      numberOfLines={numberOfLines}
      {...props}
      style={[
        {
          fontFamily: fontFamily[fontType],
          fontSize: scaleFont(fontSize),
          marginTop,
          marginBottom,
          color,
        },
        center && styles.center,
        uppercase && styles.uppercase,
        style && style,
      ]}
    >
      {children}
    </Animated.Text>
  );
};

export default Text;

const styles = StyleSheet.create({
  center: {
    textAlign: 'center',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});
