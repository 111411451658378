import { useNavigation } from '@react-navigation/native';
import generalConfig from '_/config/general';
import { useAuth } from '_/hooks/AuthContext';
import { AppRoute } from '_/navigation/types';
import { Role } from '_/services/models/enums/role.enum';

export default function useUpdateSlotCoordinates() {
  const { user, member } = useAuth();
  const navigation = useNavigation<any>();

  const updateSlot = (coordinates: any) => {
    if (member?.role === Role.ADMIN && user.email?.includes(generalConfig.superAdminEmail)) {
      navigation.navigate(AppRoute.SET_SLOT_COORDINATE, { coordinates });
    }
  };

  return { updateSlot };
}
