import { Feather } from '@expo/vector-icons';
import { alphaColor, colors, fontFamily, fontSizes, spacing } from '_/constants/theme';
import React from 'react';
import { View, TextInput, StyleSheet, StyleProp, ViewStyle, TextInputProps } from 'react-native';

interface SearchBarProps extends TextInputProps {
  searchValue: string | undefined;
  setSearchValue: ((text: string) => void) | undefined;
  style?: StyleProp<ViewStyle>;
}

export default function SearchBar({ searchValue, setSearchValue, style, ...rest }: SearchBarProps) {
  return (
    <View style={[styles.inputGroup, style]}>
      <TextInput
        {...rest}
        autoCorrect={false}
        style={styles.textInput}
        placeholder={rest.placeholder || 'Pesquisar'}
        clearButtonMode="while-editing"
        value={searchValue}
        onChangeText={setSearchValue}
      />
      <Feather name="search" size={24} color={colors.darkGrey} />
    </View>
  );
}

const styles = StyleSheet.create({
  textInput: {
    flex: 1,
    fontFamily: fontFamily.medium,
    fontSize: fontSizes.md,
  },
  inputGroup: {
    borderColor: alphaColor(colors.lightGrey, 0.5),
    borderWidth: 1,
    borderRadius: 12,
    flexDirection: 'row',
    alignItems: 'center',
    padding: spacing.sm,
    justifyContent: 'space-between',
  },
});
