import { useNavigation, useRoute } from '@react-navigation/native';
import { colors } from '_/constants/theme';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { slotsApi } from '_/services/api';
import { SlotsModel } from '_/services/models/slots.model';
import React, { useEffect, useState } from 'react';
import { FlatList, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { showMessage } from 'react-native-flash-message';

interface ParamsProps {
  coordinates?: any;
}

export default function SetSlotCoordinate() {
  const route = useRoute();
  const navigation = useNavigation<any>();
  const { currentSpace } = useSpaceContext();
  const [slots, setSlots] = useState<SlotsModel[]>([]);
  const params = route.params as ParamsProps;

  useEffect(() => {
    async function getSlots() {
      const { data } = await slotsApi.getList({
        spaceId: currentSpace.id,
        mapLocation: '<null>' as any,
        '$sort[name]': 1,
        $limit: 500,
        $select: ['id', 'name', 'mapLocation'],
      });
      setSlots(data);
    }

    getSlots();
  }, [currentSpace.id]);

  const handleSetCoordinates = async (slotId: string) => {
    if (!params.coordinates) {
      showMessage({
        message: 'Error',
        description: 'Missing coordinates',
        backgroundColor: colors.errorRed,
      });
      navigation.goBack();
    }
    try {
      await slotsApi.update(slotId, { mapLocation: params.coordinates });
      showMessage({
        message: 'Success',
        backgroundColor: colors.successGreen,
      });
      navigation.goBack();
    } catch (error) {
      showMessage({
        message: 'Error',
        description: error.message,
        backgroundColor: colors.errorRed,
      });
    }
  };

  return (
    <FlatList
      style={{ flex: 1 }}
      contentContainerStyle={{ paddingBottom: 40 }}
      keyExtractor={(item) => item.id}
      data={slots}
      renderItem={({ item }) => (
        <TouchableOpacity style={styles.item} onPress={() => handleSetCoordinates(item.id)}>
          <Text style={styles.text}>{item.name}</Text>
        </TouchableOpacity>
      )}
    />
  );
}

const styles = StyleSheet.create({
  item: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderBottomColor: '#ddd',
    borderBottomWidth: 1,
  },
  text: {
    fontSize: 18,
  },
});
