import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  modal: {
    backgroundColor: '#fff',
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 9,
    paddingVertical: 40,
  },
  wrapper: {
    padding: 16,
  },
  text: {
    marginTop: 16,
    marginBottom: 32,
  },
  title: {
    marginTop: 0,
  },
});

export default styles;
