import { colors, fontFamily, fontSizes, spacing } from '_/constants/theme';
import { getSpaceTypeColor, SpaceType } from '_/services/models/enums/space-type.enum';
import { StyleSheet } from 'react-native';

const styles = (spaceType?: SpaceType) =>
  StyleSheet.create({
    scene: {
      flex: 1,
    },
    tab: {
      backgroundColor: 'transparent',
      elevation: 0,
      borderBottomWidth: 2,
      borderColor: colors.white,
      paddingTop: spacing.xs,
    },
    indicator: {
      backgroundColor: getSpaceTypeColor(spaceType),
      height: 2,
      marginBottom: -2,
      borderRadius: 2,
    },
    label: {
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: fontFamily.bold,
      fontSize: fontSizes.md,
      textTransform: 'capitalize',
    },
  });

export default styles;
