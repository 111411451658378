import { spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  officeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingHorizontal: spacing.md,
    marginVertical: spacing.xs,
  },
  infoContainer: {
    flexDirection: 'row',
    maxWidth: '60%',
    alignItems: 'center',
  },
});

export default styles;
