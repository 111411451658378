import { AxiosRequestConfig } from 'axios';

import logger from '../../logger';
import { GetListType } from '../types/GetList';
import { ReqQueryType } from '../types/ReqQuery';
import API from './base';

class RequestCreator<T, P = T> extends API {
  protected path = '/';

  constructor(path: string) {
    super();

    this.path = path;
  }

  insert = async (values: Partial<T>, config?: AxiosRequestConfig) => {
    try {
      const { data } = await this.api.post<P>(`${this.path}`, values, config);
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  getList = async (params?: ReqQueryType<T>, config?: AxiosRequestConfig) => {
    try {
      const { data } = await this.api.get<GetListType<P>>(`${this.path}`, {
        ...config,
        params,
      });
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  getItem = async (id: string, config?: AxiosRequestConfig) => {
    try {
      const { data } = await this.api.get<P>(`${this.path}/${id}`, config);
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  update = async (
    id: string | null,
    values: Omit<Partial<T>, 'id'>,
    config?: AxiosRequestConfig
  ) => {
    try {
      const { data } = await this.api.patch<P>(`${this.path}/${id}`, values, config);
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  delete = async (id: string, config?: AxiosRequestConfig) => {
    try {
      const { data } = await this.api.delete<P>(`${this.path}/${id}`, config);
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };
}

export default RequestCreator;
