import { Feather } from '@expo/vector-icons';
import { Button, ConfirmReserveModal, Text } from '_/components/';
import Amenities from '_/components/Amenities';
import { colors, fontSizes } from '_/constants/theme';
import { normalize } from '_/helpers/normalize';
import { useDateContext } from '_/hooks/DateContext';
import { useSlotContext } from '_/hooks/SlotContext';
import { AmenitiesModel } from '_/services/models/amenities.model';
import { RecurrenceType } from '_/services/models/enums/recurrence-type.enum';
import { SlotsModel } from '_/services/models/slots.model';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity, Platform } from 'react-native';

import styles from './styles';

interface MeetingRoomProps {
  slot: SlotsModel;
  amenities: AmenitiesModel[];
  setCalendarVisibleId(id: string): void;
  calendarVisibleId: string;
}

export default function MeetingRoom({
  slot,
  amenities,
  setCalendarVisibleId,
  calendarVisibleId,
}: MeetingRoomProps) {
  const { timePickerVisible, slotWithConflicts } = useDateContext();

  const [recurrenceType, setRecurrenceType] = useState<RecurrenceType>(RecurrenceType.ONCE);
  const { isSlotModalVisible, setIsSlotModalVisible } = useSlotContext();
  const { t } = useTranslation();

  const toggleCalendar = useCallback(() => {
    if (calendarVisibleId === slot.id) {
      setCalendarVisibleId('');
      return;
    }
    setCalendarVisibleId(slot.id);
  }, [calendarVisibleId, setCalendarVisibleId, slot.id]);

  const handleOpenModal = useCallback(() => {
    setIsSlotModalVisible(slot.id);
    setRecurrenceType(RecurrenceType.ONCE);
  }, [setIsSlotModalVisible, slot.id]);

  const hasConflict = slotWithConflicts?.includes(slot.id);

  const HandleButton = useCallback(() => {
    if (timePickerVisible) {
      return (
        <Button
          hasBorder
          textColor={hasConflict ? colors.lightGrey : colors.green}
          style={[styles.button, { ...(!hasConflict && { borderColor: colors.green }) }]}
          fontStyle={styles.buttonFont}
          onPress={handleOpenModal}
          disabled={hasConflict}
        >
          {hasConflict ? t('reserved') : t('reserve')}
        </Button>
      );
    }
    return calendarVisibleId !== slot.id ? (
      <Feather name="chevron-down" size={20} />
    ) : (
      <Feather name="chevron-up" size={20} />
    );
  }, [timePickerVisible, calendarVisibleId, slot.id, hasConflict, handleOpenModal, t]);
  const handleCloseConfirmationModal = () => {
    setIsSlotModalVisible('');
    setRecurrenceType(RecurrenceType.ONCE);
  };
  return (
    <>
      <TouchableOpacity
        style={[styles.wrapper, { ...(hasConflict && timePickerVisible && { opacity: 0.5 }) }]}
        onPress={toggleCalendar}
      >
        <View style={styles.header}>
          <View style={{ flex: 1, paddingRight: 6 }}>
            <Text
              fontSize={Platform.OS !== 'web' ? normalize(fontSizes.md2) : fontSizes.md2}
              fontType="bold"
              numberOfLines={1}
            >
              {slot.name}
            </Text>
          </View>

          <HandleButton />
        </View>
        <Amenities amenities={amenities} />
      </TouchableOpacity>
      {isSlotModalVisible === slot.id && (
        <ConfirmReserveModal
          onBackdropPress={handleCloseConfirmationModal}
          onBackButtonPress={handleCloseConfirmationModal}
          onSwipeStart={handleCloseConfirmationModal}
          slot={slot}
          recurrenceType={recurrenceType}
          pickerDisabled
          setRecurrenceType={setRecurrenceType}
        />
      )}
    </>
  );
}
