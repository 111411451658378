import { spacing, fontSizes, colors } from '_/constants/theme';
import { AmenitiesModel } from '_/services/models/amenities.model';
import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, ViewStyle, StyleProp, FlatList, Dimensions } from 'react-native';

import Text from '../Text';

interface AmenitiesProps {
  amenities?: AmenitiesModel[];
  style?: StyleProp<ViewStyle>;
}

const Amenities = ({ amenities, style }: AmenitiesProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [stopSlide, setStopSlide] = useState(true);
  const flatlistRef = useRef<FlatList>(null);
  const { width } = Dimensions.get('window');

  useEffect(() => {
    const goToNextAmenity = () => {
      flatlistRef.current?.scrollToOffset({
        offset: currentSlide,
        animated: true,
      });
      if (amenities?.length && currentSlide < width - 16) {
        setCurrentSlide(currentSlide + 3);
      }
      if (amenities?.length && currentSlide === width - 15) {
        setCurrentSlide(0);
        setStopSlide(true);
        flatlistRef.current?.scrollToOffset({
          offset: currentSlide,
        });
      }
    };
    if (!stopSlide) {
      const timerId = setInterval(goToNextAmenity, 10);
      if (timerId) {
        return () => clearInterval(timerId);
      }
    }
  }, [amenities?.length, currentSlide, stopSlide, width]);

  if (!amenities?.length) {
    return null;
  }
  return (
    <View style={[styles.container, style]}>
      <FlatList
        key="amenitiesList"
        data={amenities}
        horizontal
        ref={flatlistRef}
        ItemSeparatorComponent={() => <View style={styles.dotSeparator} />}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => (
          <Text fontSize={fontSizes.sm} fontType="medium" color={colors.grey}>
            {item.name}
          </Text>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: spacing.md,
  },
  dotSeparator: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: colors.grey,
    alignSelf: 'center',
    marginHorizontal: spacing.xxs,
  },
});

export default Amenities;
