import { Reservation, Text } from '_/components';
import { colors, fontSizes } from '_/constants/theme';
import { useEventContext } from '_/hooks/EventContext';
import { endOfDay, endOfMonth, endOfWeek, endOfYear } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  View,
  TouchableOpacity,
  RefreshControl,
  Platform,
  ActivityIndicator,
} from 'react-native';

import styles from './styles';

export default function ActiveReservations() {
  const { activeReservations, getActiveEvents, activeReservationsResponseTotal, eventsLoading } =
    useEventContext();
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState('');
  const [loading, setLoading] = useState(false);

  const mounted = useRef(true);

  const filters = [
    {
      key: 'today',
      label: t('weekDays.today'),
    },
    {
      key: 'week',
      label: t('filters.thisWeek'),
    },
    {
      key: 'month',
      label: t('filters.thisMonth'),
    },
    {
      key: 'year',
      label: t('filters.thisYear'),
    },
  ];

  const filterEndAt = useMemo(() => {
    let endAt: Date | null = null;

    switch (selectedFilter) {
      case 'today':
        endAt = endOfDay(new Date());
        break;
      case 'week':
        endAt = endOfWeek(new Date());
        break;
      case 'month':
        endAt = endOfMonth(new Date());
        break;
      case 'year':
        endAt = endOfYear(new Date());
        break;
      default:
        endAt = null;
    }

    return endAt;
  }, [selectedFilter]);

  async function getFilteredEvents() {
    setLoading(true);
    if (filterEndAt) {
      await getActiveEvents(0, filterEndAt).finally(() => setLoading(false));
    } else {
      await getActiveEvents().finally(() => setLoading(false));
    }
  }

  useEffect(() => {
    if (!mounted.current) {
      getFilteredEvents();
    }

    return () => {
      mounted.current = false;
    };
  }, [filterEndAt]);

  function onChangeFilter(filter: string) {
    if (filter === selectedFilter) {
      setSelectedFilter('');
      return;
    }
    setSelectedFilter(filter);
  }

  async function loadMore() {
    if (selectedFilter !== '') {
      await getActiveEvents(activeReservations.length, filterEndAt, true);
      return;
    }

    await getActiveEvents(activeReservations.length, null, true);
  }

  if (activeReservations?.length === 0) {
    return null;
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.filters}>
        {filters.map((filter) => {
          return (
            <TouchableOpacity
              onPress={() => onChangeFilter(filter.key)}
              style={[
                styles.filter,
                {
                  backgroundColor: selectedFilter === filter.key ? colors.lightBlue : colors.white,
                },
              ]}
              key={filter.key}
            >
              <Text
                fontType="bold"
                fontSize={fontSizes.sm}
                color={selectedFilter === filter.key ? colors.white : colors.black}
              >
                {filter.label}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>

      {loading ? (
        <ActivityIndicator color={colors.lightBlue} size="large" style={{ marginTop: 32 }} />
      ) : (
        <FlatList
          key="ActiveReservations"
          data={activeReservations}
          showsVerticalScrollIndicator={Platform.OS === 'web'}
          refreshControl={
            <RefreshControl
              refreshing={false}
              onRefresh={async () => {
                if (filterEndAt) {
                  await getActiveEvents(0, filterEndAt);
                } else {
                  await getActiveEvents();
                }
              }}
            />
          }
          keyExtractor={(item) => item.id}
          style={styles.list}
          renderItem={({ item }) => <Reservation event={item} />}
          onEndReachedThreshold={0.1}
          onEndReached={() =>
            activeReservations.length === activeReservationsResponseTotal ? null : loadMore()
          }
          ListFooterComponent={
            eventsLoading ? <ActivityIndicator color={colors.lightBlue} size="large" /> : <></>
          }
        />
      )}
    </View>
  );
}
