import { Companies } from '_/constants/companies';
import { Prompt } from 'expo-auth-session';
import Constants from 'expo-constants';

const company = Constants.expoConfig?.extra?.company as string;
const scheme = Constants.expoConfig?.scheme || 'placehub';
const appName = Constants.expoConfig?.name || 'Placehub';
const website = Constants.expoConfig?.extra?.website || 'https://placehub.io';
const email = Constants.expoConfig?.extra?.email || 'contact@placehub.io';
const apiUrl = Constants.expoConfig?.extra?.apiUrl || 'https://prod.api.placehub.io';

const generalConfig = {
  appName,
  website,
  email,
  apiUrl,
  appScheme: `${scheme}://`,
  sentryDsn: 'https://e7d17789cab8405aa168e4660c9cd1b6@o462764.ingest.sentry.io/5761522',
  apiLimit: 200,
  notificationLimit: 30,
  reportLimit: 20,
  superAdminEmail: '@placehub.io',
  apiSlotLimitMap: 1000,
  apiSlotLimitList: 100,
  languageKey: '@app:language',
  deviceIdKey: '@app:deviceId',
  googleLoginConfig: {
    iosClientId: '266163438090-06iif3vn8fkj3tna45osu3sh014gtqd2.apps.googleusercontent.com',
    iosStandaloneAppClientId:
      '266163438090-ds176c1r0k09t915lknal40clears08l.apps.googleusercontent.com',
    androidClientId: '266163438090-hcq9mevtlcrljtsekn27fg9ekoh57kff.apps.googleusercontent.com',
    androidStandaloneAppClientId:
      '266163438090-pstv3t8592klppaionk80gco4283s6gv.apps.googleusercontent.com',
    webClientId: '266163438090-876v4k1sol1r5aa9mpjso1saq8g5gp0u.apps.googleusercontent.com',
    expoClientId: '266163438090-876v4k1sol1r5aa9mpjso1saq8g5gp0u.apps.googleusercontent.com',
    scopes: ['profile', 'email'],
    prompt: Prompt.SelectAccount,
    ...(company === Companies.DEZKER && {
      iosStandaloneAppClientId:
        '266163438090-7jfmvbcre3aekcjm2k5idu6h60h6u5jm.apps.googleusercontent.com',
      androidStandaloneAppClientId:
        '266163438090-tf51hgd4jq6qe5jv1m11vfsktl7nmbm2.apps.googleusercontent.com',
    }),
  },
  microsoftLoginConfig: {
    clientId: 'e0f3f51e-b2df-4453-b872-1c3a8cbde5b4',
    scopes: ['User.Read', 'openid', 'profile', 'email'],
  },
};

export default generalConfig;
