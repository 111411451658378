import { colors, spacing } from '_/constants/theme';
import { Dimensions, StyleSheet } from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';
const { height: screenHeight } = Dimensions.get('window');
const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    paddingHorizontal: spacing.md,
    maxWidth: 550,
    width: '100%',
    alignSelf: 'center',
    paddingVertical: isIphoneX() ? 50 : 40,
  },
  goToProfile: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
    paddingVertical: spacing.xs,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: colors.lightGrey,
    marginTop: spacing.sm,
  },
  goToProfileText: {
    marginRight: spacing.sm,
  },
  text: {
    marginVertical: spacing.md,
    textAlign: 'center',
    paddingHorizontal: spacing.xs,
  },
  wrapperOrganizationDisabled: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '20%',
    paddingBottom: '20%',
  },
  imageOrganizationDisabled: {
    flex: 1,
    maxWidth: 550,
    width: '100%',
    justifyContent: 'center',
  },
  img: {
    height: screenHeight * 0.5,
    width: '100%',
  },
});

export default styles;
