import { colors } from '_/constants/theme';
import { useReportsContext } from '_/hooks/ReportsContext';
import React, { useEffect } from 'react';
import { ActivityIndicator, FlatList, RefreshControl } from 'react-native';

import NoReports from '../NoReports';
import Report from '../Report';

export default function ClosedReports() {
  const { getClosedReports, closedReports, closedListEndReached, loading } = useReportsContext();

  useEffect(() => {
    getClosedReports(true);
  }, []); // eslint-disable-line

  return (
    <FlatList
      key="ClosedReports"
      data={closedReports}
      ListEmptyComponent={loading ? null : <NoReports />}
      showsVerticalScrollIndicator={false}
      keyExtractor={(item) => item.id}
      renderItem={(report) => <Report report={report.item} />}
      contentContainerStyle={{ paddingBottom: 40 }}
      onEndReached={() => {
        if (!loading && !closedListEndReached) {
          getClosedReports();
        }
      }}
      ListFooterComponent={() => {
        if (loading !== 'closed-end') return null;
        return <ActivityIndicator size={20} style={{ marginTop: 20 }} color={colors.black} />;
      }}
      onEndReachedThreshold={0.1}
      refreshControl={
        <RefreshControl
          refreshing={loading === 'closed'}
          onRefresh={() => {
            getClosedReports(true);
          }}
        />
      }
    />
  );
}
