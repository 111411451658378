import { Button, Input, Text, Title } from '_/components';
import { colors } from '_/constants/theme';
import { usersApi } from '_/services/api';
import logger from '_/services/logger';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { ForgotPasswordProps } from '..';
import styles from '../SendEmail/styles';

export default function ChangePassword({
  setNextStep,
  token,
  userEmail,
  modalRef,
}: ForgotPasswordProps) {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { t } = useTranslation();

  const handleChangePassword = async () => {
    if (password === '') {
      modalRef?.current?.showMessage({
        message: t('error'),
        description: t('signinScreen.errorMessages.passwordRequired'),
        backgroundColor: colors.errorRed,
      });
      return;
    }

    if (password !== confirmPassword) {
      modalRef?.current?.showMessage({
        message: t('error'),
        description: t('signupScreen.errorMessages.passwordConfirmation'),
        backgroundColor: colors.errorRed,
      });
      return;
    }

    try {
      setLoading(true);
      await usersApi.resetPassword({ password, token, email: userEmail });
      modalRef?.current?.showMessage({
        message: t('success'),
        description: t('signinScreen.successMessages.passwordChanged'),
        backgroundColor: colors.successGreen,
      });
      setNextStep();
    } catch (error: any) {
      setLoading(false);
      logger(error);
      if (error?.response?.data?.message === 'Weak password') {
        modalRef?.current?.showMessage({
          message: t('error'),
          description: t('signupScreen.errorMessages.strongPassword'),
          backgroundColor: colors.errorRed,
        });
      } else {
        modalRef?.current?.showMessage({
          message: 'Erro',
          description: t('genericError'),
          backgroundColor: colors.errorRed,
        });
      }
    }
  };

  return (
    <>
      <Title title={t('signinScreen.forgotPasswordModal.newPassword')} style={styles.title} />
      <View style={styles.wrapper}>
        <Text fontSize={16} marginBottom={32}>
          {t('signinScreen.forgotPasswordModal.newPasswordMessage')}
        </Text>
        <Input
          isPassword
          tag={t('signinScreen.password')}
          placeholder="*********"
          autoCapitalize="none"
          textContentType="password"
          value={password}
          onChangeText={setPassword}
        />
        <Input
          isPassword
          tag={t('signinScreen.confirmPassword')}
          placeholder="*********"
          autoCapitalize="none"
          textContentType="password"
          value={confirmPassword}
          onChangeText={setConfirmPassword}
        />
        <Button
          loading={loading}
          backgroundColor={colors.lightBlue}
          textColor={colors.white}
          onPress={handleChangePassword}
        >
          {t('confirm')}
        </Button>
      </View>
    </>
  );
}
