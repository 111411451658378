import { useRoute } from '@react-navigation/native';
import { FilterContainer, SearchBar, SpaceTypeHeader, WebWrapper } from '_/components';
import { useSlotContext } from '_/hooks/SlotContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { SpaceType } from '_/services/models/enums/space-type.enum';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CardProps } from '../Home/OfficesList/Office/OfficeModal/SpacesCard';
import RoomList from '../Meeting/RoomList';
import DateSelector from './DateSelector';
import StatusTab from './StatusTab';
import styles from './styles';

interface ParamsProps extends CardProps {
  selectedDay: string;
  availableSlotsCount: string;
  spaceType: SpaceType;
}

export default function Workstation() {
  const route = useRoute();
  const params = route.params as ParamsProps;
  const { currentSpace, layoutMode, setLayoutMode, selectorOrSearch, setSelectorOrSearch } =
    useSpaceContext();
  const { slotSearchValue, setSlotSearchValue } = useSlotContext();
  const { t } = useTranslation();

  const WorkstationContent = useCallback(() => {
    if (
      params.spaceType === SpaceType.MEETING ||
      params.spaceType === SpaceType.CALL ||
      params.spaceType === SpaceType.STUDIO
    ) {
      return <RoomList />;
    } else {
      return <StatusTab spaceType={params.spaceType} layoutMode={layoutMode} />;
    }
  }, [layoutMode, params.spaceType]);

  const HandleDateSelector = useCallback(() => {
    if (currentSpace.isMapVisible && layoutMode === 'map') {
      if (selectorOrSearch === 'search') {
        return (
          <SearchBar
            searchValue={slotSearchValue}
            setSearchValue={setSlotSearchValue}
            style={styles.searchBar}
            placeholder={t('workstationScreen.searchBarPlaceholder')}
          />
        );
      }
    }
    if (selectorOrSearch === 'selector') {
      return <DateSelector spaceType={params.spaceType} />;
    } else {
      return null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpace.isMapVisible, layoutMode, params.spaceType, selectorOrSearch]);

  return (
    <WebWrapper>
      <SpaceTypeHeader
        availableSlotsCount={params.availableSlotsCount}
        spaceType={params.spaceType}
        locationName={params.locationName}
        setLayoutMode={setLayoutMode}
        layoutMode={layoutMode}
        isMapVisible={currentSpace.isMapVisible}
        selectorOrSearch={selectorOrSearch}
        setSelectorOrSearch={setSelectorOrSearch}
      />
      <HandleDateSelector />
      {params.spaceType !== SpaceType.WORK && <FilterContainer />}
      <WorkstationContent />
    </WebWrapper>
  );
}
