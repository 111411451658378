import { colors } from '_/constants/theme';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import Text from '../Text';

interface CheckboxProps {
  label: string;
  selected?: boolean;
  onPress?: () => void;
  color?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  selected,
  color = colors.successGreen,
  onPress,
}) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.checkbox}
        onPress={onPress}
        hitSlop={{ top: 8, right: 8, bottom: 8, left: 8 }}
      >
        {selected && <View style={[styles.selectedCheckbox, { backgroundColor: color }]} />}
      </TouchableOpacity>
      <Text>{label}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectedCheckbox: {
    width: 18,
    height: 18,
    borderRadius: 2,
  },
  checkbox: {
    width: 26,
    height: 26,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: colors.darkGrey,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
  },
});
