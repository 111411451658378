import { Header, WebWrapper } from '_/components';
import { colors } from '_/constants/theme';
import { useEventContext } from '_/hooks/EventContext';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Dimensions, Platform } from 'react-native';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';

import ActiveReservations from './ActiveReservations';
import NoReservation from './NoReservation';
import PastReservations from './PastReservations';
import styles from './styles';

export default function MyReservations() {
  const { activeReservations, pastReservations, getPastEvents, getActiveEvents } =
    useEventContext();
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getEvents() {
      setLoading(true);
      try {
        await getActiveEvents();
        await getPastEvents();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    getEvents();
  }, []);

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      indicatorStyle={styles.indicator}
      style={styles.tab}
      labelStyle={styles.label}
      inactiveColor={colors.lightGrey}
      activeColor={colors.lightBlue}
      allowFontScaling={false}
    />
  );

  const routes = [
    { key: 'first', title: t('reservationsScreen.inProgress') },
    { key: 'second', title: t('reservationsScreen.past') },
  ];

  const initialLayout = { width: Dimensions.get('window').width };

  const FirstRoute = useCallback(() => <ActiveReservations />, []);

  const SecondRoute = useCallback(() => <PastReservations />, []);
  const renderScene = SceneMap({
    first: FirstRoute,
    second: SecondRoute,
  });

  return (
    <WebWrapper
      webWrapperStyes={Platform.OS === 'web' && { height: Dimensions.get('window').height }}
    >
      <Header screenName={t('homeScreen.myReservations')} />

      {!activeReservations?.length && !pastReservations?.length ? (
        <>
          {loading ? (
            <ActivityIndicator color={colors.lightBlue} size="large" style={{ marginTop: 32 }} />
          ) : (
            <NoReservation />
          )}
        </>
      ) : (
        <TabView
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={setIndex}
          initialLayout={initialLayout}
          renderTabBar={renderTabBar}
        />
      )}
    </WebWrapper>
  );
}
