import { colors, fontSizes, spacing } from '_/constants/theme';
import { Dimensions, StyleSheet } from 'react-native';

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width,
    zIndex: 9999999,
    backgroundColor: colors.clear,
  },
  message: {
    fontSize: fontSizes.lg,
    marginBottom: spacing.xxl,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

export default styles;
