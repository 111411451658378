import { useLocationContext } from '_/hooks/LocationContext';
import React from 'react';
import { View } from 'react-native';

import SpacesCard, { SpacesProps } from './SpacesCard';
import styles from './styles';

interface ModalProps {
  visible: boolean;
  locationId: string;
  locationName: string;
  spacesData: SpacesProps[];
}

export default function OfficeModal({ visible, locationName, spacesData }: ModalProps) {
  const { locations } = useLocationContext();

  return (
    <View style={{ display: (locations.data.length === 1 && 'flex') || visible ? 'flex' : 'none' }}>
      <View style={styles.container}>
        {spacesData.map((item) => (
          <SpacesCard key={item.id} space={item} locationName={locationName} />
        ))}
      </View>
      {locations.data.length > 1 && <View style={styles.separator} />}
    </View>
  );
}
