import { addLocale } from '@geisweiller/react-native-week-view';
// add customized localed before using locale prop.

export const locale = addLocale('br', {
  months:
    'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split(
      '_'
    ),
  monthsShort: 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez.'.split('_'),
  weekdays: 'domingo_segunda_terça_quarta_quinta_sexta_sábado'.split('_'),
  weekdaysShort: 'dom_seg_ter_qua_qua_sex_sáb'.split('_'),
});
