import { useNavigation } from '@react-navigation/native';
import { images } from '_/assets';
import { Avatar, Text } from '_/components';
import { fontSizes } from '_/constants/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useOrganizationContext } from '_/hooks/OrganizationContext';
import { AppRoute } from '_/navigation/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Image,
  View,
  SafeAreaView,
  TouchableOpacity,
  ScrollView,
  RefreshControl,
} from 'react-native';

import styles from './styles';

export default function OrganizationDisabled() {
  const { user, member, updateMember } = useAuth();
  const { organizationData, isLoadingOrganization, getOrganization, getMember } =
    useOrganizationContext();
  const navigation = useNavigation<any>();
  const { t } = useTranslation();

  return (
    <ScrollView
      refreshControl={
        <RefreshControl
          refreshing={isLoadingOrganization}
          onRefresh={async () => {
            if (member) {
              await getOrganization(member?.organizationId);
              const [reloadMember] = await getMember(user.id, user.currentOrganizationId as string);
              await updateMember(reloadMember);
            }
          }}
        />
      }
    >
      <View style={[styles.wrapper, { justifyContent: 'space-between' }]}>
        <SafeAreaView>
          <TouchableOpacity
            style={styles.goToProfile}
            onPress={() => navigation.navigate(AppRoute.PROFILE_STACK_SCREEN)}
          >
            <Text fontSize={fontSizes.md2} fontType="medium" style={styles.goToProfileText}>
              {t('profileScreen.profile')}
            </Text>
            <Avatar size={24} />
          </TouchableOpacity>
        </SafeAreaView>
        <View style={styles.wrapperOrganizationDisabled}>
          <Text style={[styles.text, { fontWeight: '600' }]} fontSize={fontSizes.md2}>
            {t('onBoarding.organizationDisabled', { organizationName: organizationData.name })}
          </Text>
          <View style={styles.imageOrganizationDisabled}>
            <Image resizeMode="contain" source={images.notFound} style={styles.img} />
          </View>
          <Text style={styles.text} fontSize={fontSizes.md2}>
            {t('onBoarding.moreInfo')}
          </Text>
        </View>
      </View>
    </ScrollView>
  );
}
