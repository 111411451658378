import * as Updates from 'expo-updates';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppState, Platform } from 'react-native';

import ModalConfirmation from '../ModalConfirmation';

export default function UpdateModal() {
  const [isVisible, setVisible] = useState(false);
  const { t } = useTranslation();
  const appState = useRef(AppState.currentState);

  Updates.addListener((event) => {
    if (event.type === Updates.UpdateEventType.UPDATE_AVAILABLE) {
      setVisible(true);
    }
  });

  const handleAppStateChange = useCallback(async (nextAppState: any) => {
    if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
      const result = await Updates.checkForUpdateAsync();
      if (result.isAvailable) {
        Updates.fetchUpdateAsync();
      }
    }

    appState.current = nextAppState;
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web') {
      return;
    }

    const subscription = AppState.addEventListener('change', handleAppStateChange);

    return () => {
      subscription.remove();
    };
  }, [handleAppStateChange]);

  if (Platform.OS === 'web') {
    return null;
  }

  return (
    <ModalConfirmation
      title={t('updateModal.updateAvailable')}
      description={t('updateModal.updateAvailableMessage')}
      rightButtonText={t('updateModal.update')}
      isVisible={isVisible}
      onCloseModal={() => null}
      onConfirmModal={() => Updates.reloadAsync()}
      onCancelButton={() => null}
      oneOptionOnly
    />
  );
}
