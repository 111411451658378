import RadioInput from '_/components/RadioInput';
import SearchBar from '_/components/SearchBar';
import Title from '_/components/Title';
import { colors, spacing } from '_/constants/theme';
import {
  DropdownFieldOptionsModel,
  DropdownFieldOptionsRequest,
} from '_/services/models/dropdown-field-options.model';
import { SpaceType } from '_/services/models/enums/space-type.enum';
import Fuse from 'fuse.js';
import React, { useState, useEffect, useCallback } from 'react';
import { FlatList, Platform, StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';

interface CustomDropDownFieldValuesModalProps {
  label: string | undefined;
  options: DropdownFieldOptionsModel[] | undefined;
  visible: boolean;
  value: DropdownFieldOptionsRequest | undefined;
  spaceType: SpaceType;
  hideDropdown: () => void;
  setDropdownField: (value: DropdownFieldOptionsRequest) => void;
}

export default function CustomDropDownFieldValuesModal({
  label,
  options,
  visible,
  spaceType,
  value,
  hideDropdown,
  setDropdownField,
}: CustomDropDownFieldValuesModalProps) {
  const [search, setSearch] = useState<string | undefined>();
  const [searchData, setSearchData] = useState<DropdownFieldOptionsModel[]>([]);

  function handleSelectOption(option: DropdownFieldOptionsModel) {
    setDropdownField({ id: option.id, value: option.value });
    hideDropdown();
  }

  useEffect(() => {
    function cleanData() {
      if (!visible) {
        setSearchData([]);
        setSearch('');
      }
    }
    cleanData();
  }, [visible]);

  const searchItem = (query?: string) => {
    setSearch(query);
    if (!query) {
      setSearchData([]);
      return;
    }

    const fuse: DropdownFieldOptionsModel[] = new Fuse(options || [], {
      isCaseSensitive: false,
      threshold: 0.2,
      location: 0,
      distance: 100,
      keys: ['value'],
    })
      .search(query)
      .map((result) => result.item);

    setSearchData(fuse);
  };

  const RenderOptions = useCallback(() => {
    const data = searchData.length ? searchData : options;
    if (Platform.OS === 'web') {
      return (
        <View style={styles.modalContent}>
          {data?.map((option) => (
            <RadioInput
              key={option.id}
              text={option.value}
              spaceType={spaceType}
              selected={option.id === value?.id}
              onPress={() => handleSelectOption(option)}
            />
          ))}
        </View>
      );
    }

    return (
      <FlatList
        decelerationRate="fast"
        key="OptionsList"
        data={!search ? options : searchData}
        style={styles.modalContent}
        keyExtractor={(item) => item.id}
        contentContainerStyle={styles.scrollViewContainer}
        renderItem={({ item }) => (
          <RadioInput
            key={item.id}
            text={item.value}
            spaceType={spaceType}
            selected={item.id === value?.id}
            onPress={() => handleSelectOption(item)}
          />
        )}
      />
    );
  }, [searchData]);

  return (
    <Modal
      style={styles.modal}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      isVisible={!!visible}
      coverScreen
      hasBackdrop
      propagateSwipe
      backdropOpacity={0.5}
      onBackdropPress={() => hideDropdown()}
      onBackButtonPress={() => hideDropdown()}
    >
      <Title
        title={label}
        hasCloseButton
        onPress={() => hideDropdown()}
        style={{ maxWidth: '90%' }}
        numberOfLines={20}
      />
      {(options || []).length > 10 && (
        <SearchBar searchValue={search} setSearchValue={searchItem} style={styles.textInput} />
      )}
      <RenderOptions />
    </Modal>
  );
}

const styles = StyleSheet.create({
  textInput: {
    marginHorizontal: spacing.md,
    marginTop: 10,
  },
  modal: {
    flex: 1,
    width: '100%',
    ...(Platform.OS === 'web' && { maxWidth: 550 }),
    margin: 0,
    marginTop: 80,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    backgroundColor: colors.clear,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    paddingTop: 20,
  },
  scrollViewContainer: {
    paddingBottom: 40,
  },
  modalContent: {
    flex: 1,
    ...(Platform.OS === 'web' && { overflow: 'visible', overflowY: 'auto' }),
    paddingHorizontal: spacing.md,
    marginTop: spacing.lg,
  },
});
