import { colors, spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: spacing.md,
    borderBottomColor: colors.white,
    borderBottomWidth: 1,
    paddingBottom: spacing.sm,
  },
  button: {
    flex: 1,
    height: 40,
    minHeight: 40,
    padding: 8,
    borderRadius: 10,
    marginHorizontal: 4,
  },
  buttonFont: {
    fontSize: 12,
    fontWeight: '600',
  },
});

export default styles;
