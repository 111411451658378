import { spacing } from '_/constants/theme';
import { Dimensions, StyleSheet } from 'react-native';

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    borderBottomWidth: 2,
    width,
    borderBottomColor: 'rgba(200, 205, 208, 0.2)',
    paddingVertical: spacing.xs,
  },
  header: {
    minHeight: 44,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: spacing.md,
    width,
    flexDirection: 'row',
    maxWidth: 550,
  },
  button: {
    width: 120,
    borderRadius: 4,
    paddingVertical: 8,
    minHeight: 0,
    marginTop: 0,
  },
  buttonFont: {
    fontWeight: 'bold',
    fontSize: 14,
  },
});

export default styles;
