import Button from '_/components/Button';
import Text from '_/components/Text';
import Title from '_/components/Title';
import { colors, fontFamily, fontSizes, spacing } from '_/constants/theme';
import { networkErrorMessage } from '_/helpers/networkError';
import { useEventContext } from '_/hooks/EventContext';
import { useLocationContext } from '_/hooks/LocationContext';
import { eventsApi } from '_/services/api';
import { EventsResponse } from '_/services/models/events.model';
import { rgba } from 'polished';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, View, StyleSheet, ScrollView, Linking, Platform } from 'react-native';
import FlashMessage from 'react-native-flash-message';
import MapView, { Marker, PROVIDER_GOOGLE, Circle, LatLng } from 'react-native-maps';
import Modal from 'react-native-modal';

import NoMap from './NoMap';
import { mapStyle } from './mapStyle';

interface MapModalProps {
  visible: boolean;
  setMapModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onBackdropPress: () => void;
  onBackButtonPress: () => void;
  event: EventsResponse;
}

export default function MapModal({
  visible,
  setMapModalVisible,
  setModalVisible,
  onBackdropPress,
  onBackButtonPress,
  event,
}: MapModalProps) {
  const [locationPosition, setLocationPosition] = useState<LatLng>({
    latitude: 0,
    longitude: 0,
  });
  const [currentPosition, setCurrentPosition] = useState<LatLng>({
    latitude: 0,
    longitude: 0,
  });
  const { permission, requestPermission } = useLocationContext();
  const { t } = useTranslation();
  const { getEvents } = useEventContext();
  const mapRef = useRef<MapView>(null);
  const modalRef = useRef<any>(null);

  useEffect(() => {
    if (visible && permission === undefined) {
      requestPermission();
    }
  }, [visible, permission, requestPermission]);

  function isLocationWithinRadius(
    checkinPoint: { latitude: number; longitude: number },
    locationCoordinates: { latitude: number; longitude: number },
    checkinRadius = 0
  ): boolean {
    const ky = 40000 / 360;
    const kx = Math.cos((Math.PI * locationCoordinates.latitude) / 180.0) * ky;
    const dx = Math.abs(locationCoordinates.longitude - checkinPoint.longitude) * kx;
    const dy = Math.abs(locationCoordinates.latitude - checkinPoint.latitude) * ky;
    return Math.sqrt(dx * dx + dy * dy) <= checkinRadius / 1000;
  }

  const fitPadding = useCallback(() => {
    const userMarker = { latitude: currentPosition.latitude, longitude: currentPosition.longitude };
    const locationMarker = {
      latitude: locationPosition.latitude,
      longitude: locationPosition.longitude,
    };
    mapRef?.current?.fitToCoordinates([userMarker, locationMarker], {
      edgePadding: { top: 100, right: 100, bottom: 100, left: 100 },
      animated: true,
    });
  }, [currentPosition, locationPosition]);

  useEffect(() => {
    fitPadding();
  }, [fitPadding]);

  useEffect(() => {
    if (permission) {
      setLocationPosition({
        latitude: Number(event.slot?.space?.location?.latitude) || 0,
        longitude: Number(event.slot?.space?.location?.longitude) || 0,
      });
    }
  }, [
    visible,
    event.slot?.space?.location?.latitude,
    event.slot?.space?.location?.longitude,
    permission,
  ]);

  const isButtonDisabled = useMemo(() => {
    const canMakeCheckin = isLocationWithinRadius(
      {
        latitude: locationPosition.latitude,
        longitude: locationPosition.longitude,
      },
      { latitude: currentPosition.latitude, longitude: currentPosition.longitude },
      event.slot?.space?.location?.checkinRadius as number
    );
    return !canMakeCheckin;
  }, [
    currentPosition.latitude,
    currentPosition.longitude,
    event.slot?.space?.location?.checkinRadius,
    locationPosition.latitude,
    locationPosition.longitude,
  ]);

  const handleLocationCheckin = async () => {
    try {
      await eventsApi.checkinByLocation({
        id: event.id,
        latitude: currentPosition.latitude,
        longitude: currentPosition.longitude,
      });
      setMapModalVisible(false);
      setModalVisible(false);
      getEvents();
      modalRef?.current?.showMessage({
        message: t('success'),
        description: t('qrCodeScreen.successMessages.checkinSuccess'),
        backgroundColor: colors.successGreen,
      });
    } catch (error) {
      networkErrorMessage(error, modalRef);
      modalRef?.current?.showMessage({
        message: t('error'),
        description: t('qrCodeScreen.errorMessages.tooFar'),
        backgroundColor: colors.errorRed,
      });
    }
  };

  const openSettings = () => {
    if (permission === undefined || Platform.OS === 'android') {
      requestPermission();
    } else if (!permission) {
      Linking.openSettings();
    }
  };

  return (
    <Modal
      style={styles.modal}
      isVisible={visible}
      onBackdropPress={onBackdropPress}
      onBackButtonPress={onBackButtonPress}
      coverScreen
      hasBackdrop
      backdropOpacity={0.5}
    >
      <FlashMessage
        floating
        position="top"
        duration={5000}
        titleStyle={{ fontFamily: fontFamily.bold }}
        textStyle={{ fontFamily: fontFamily.regular }}
        ref={modalRef}
      />
      <ScrollView style={styles.scrollViewContainer}>
        <View style={styles.container}>
          <Title
            style={styles.title}
            title={t('qrCodeScreen.checkinByLocation')}
            hasCloseButton
            onPress={onBackButtonPress}
          />
          {permission ? (
            <View style={styles.mapWrap}>
              <MapView
                provider={PROVIDER_GOOGLE}
                style={styles.map}
                customMapStyle={mapStyle}
                ref={mapRef}
                showsMyLocationButton
                showsUserLocation
                loadingEnabled
                userLocationUpdateInterval={15000}
                onUserLocationChange={({ nativeEvent }) =>
                  nativeEvent.coordinate &&
                  setCurrentPosition({
                    latitude: nativeEvent.coordinate?.latitude,
                    longitude: nativeEvent.coordinate?.longitude,
                  })
                }
                initialRegion={{
                  latitude: currentPosition.latitude,
                  longitude: currentPosition.longitude,
                  latitudeDelta: 0.0014,
                  longitudeDelta: 0.0014,
                }}
              >
                <Marker
                  coordinate={{
                    latitude: locationPosition.latitude,
                    longitude: locationPosition.longitude,
                  }}
                  tracksViewChanges={false}
                />
                <Circle
                  strokeColor="transparent"
                  fillColor={rgba(15, 195, 149, 0.6)}
                  style={{ opacity: 1 }}
                  center={{
                    latitude: locationPosition.latitude,
                    longitude: locationPosition.longitude,
                  }}
                  radius={event.slot?.space?.location?.checkinRadius || 50}
                />
              </MapView>
            </View>
          ) : (
            <NoMap />
          )}
          {isButtonDisabled && (
            <Text fontSize={fontSizes.lg} color={colors.errorRed} style={{ textAlign: 'center' }}>
              {t('qrCodeScreen.buttonDisabled')}
            </Text>
          )}

          {permission ? (
            <Button
              style={{ opacity: isButtonDisabled ? 0.2 : 1 }}
              disabled={isButtonDisabled}
              onPress={handleLocationCheckin}
              textColor={isButtonDisabled ? colors.darkGrey : colors.white}
              backgroundColor={colors.successGreen}
            >
              {t('qrCodeScreen.checkin')}
            </Button>
          ) : (
            <Button
              onPress={openSettings}
              textColor={isButtonDisabled ? colors.darkGrey : colors.white}
              backgroundColor={colors.successGreen}
            >
              {t('qrCodeScreen.openSettings')}
            </Button>
          )}
        </View>
      </ScrollView>
    </Modal>
  );
}
const { height } = Dimensions.get('window');

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    position: 'absolute',
    bottom: 0,
    maxHeight: height,
    left: 0,
    width: '100%',
    margin: 0,
    backgroundColor: colors.clear,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    paddingTop: 10,
  },
  map: {
    borderRadius: 8,
    height: height / 2,
    width: '100%',
  },
  mapWrap: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    alignSelf: 'center',
    height: height / 2,
    width: '100%',
    borderRadius: 8,
    marginBottom: spacing.lg,
  },
  scrollViewContainer: {
    paddingBottom: 40,
  },
  title: {
    marginTop: 0,
    marginBottom: spacing.lg,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: spacing.md,
    paddingBottom: spacing.lg,
  },
});
