import { Reservation } from '_/components';
import { colors } from '_/constants/theme';
import { useEventContext } from '_/hooks/EventContext';
import React from 'react';
import { ActivityIndicator, FlatList, Platform, RefreshControl, View } from 'react-native';

import styles from './styles';

export default function PastReservations() {
  const { pastReservations, getPastEvents, pastReservationsResponseTotal, eventsLoading } =
    useEventContext();

  async function loadMore() {
    await getPastEvents(pastReservations.length, true);
  }

  if (pastReservations.length === 0) {
    return null;
  }

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        key="PastReservations"
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={async () => {
              await getPastEvents();
            }}
          />
        }
        data={pastReservations}
        showsVerticalScrollIndicator={Platform.OS === 'web'}
        keyExtractor={(item) => item.id}
        style={styles.list}
        renderItem={({ item }) => <Reservation event={item} />}
        onEndReachedThreshold={0.1}
        onEndReached={() =>
          pastReservations.length === pastReservationsResponseTotal ? null : loadMore()
        }
        ListFooterComponent={
          eventsLoading ? <ActivityIndicator color={colors.lightBlue} size="large" /> : <></>
        }
      />
    </View>
  );
}
