import { Feather } from '@expo/vector-icons';
import Text from '_/components/Text';
import { DATE_FORMAT, DAY_MONTH_YEART_FORMAT } from '_/config/date';
import { colors, fontSizes } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { useDateContext } from '_/hooks/DateContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import { parse } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';

import { styles } from './styles';

interface RecurrenceFinishDateProps {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  setFinalDateCalendarModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RecurrenceFinishDate({
  selected,
  setSelected,
  setFinalDateCalendarModalVisible,
}: RecurrenceFinishDateProps) {
  const { selectedFinalDay, setIsEndOfYear } = useDateContext();
  const { spaceType } = useSpaceContext();
  const { t } = useTranslation();
  const hitSlop = { top: 8, right: 8, bottom: 8, left: 8 };

  const handleOpenModal = () => {
    setSelected('specificDate');
    setIsEndOfYear(false);
    setFinalDateCalendarModalVisible(true);
  };

  const handleEndOfYear = () => {
    setSelected('endOfYear');
    setIsEndOfYear(true);
  };

  return (
    <View style={styles().wrapper}>
      <View style={styles().item}>
        <TouchableOpacity hitSlop={hitSlop} style={styles().radioInput} onPress={handleEndOfYear}>
          {selected === 'endOfYear' && <View style={styles(spaceType).selected} />}
        </TouchableOpacity>
        <TouchableOpacity style={styles().borderButton} onPress={handleEndOfYear}>
          <Text fontSize={fontSizes.md2} style={styles().text}>
            {t('workstationScreen.customRecurrenceModal.endOfYear')}
          </Text>
        </TouchableOpacity>
      </View>

      <View style={styles().item}>
        <TouchableOpacity hitSlop={hitSlop} style={styles().radioInput} onPress={handleOpenModal}>
          {selected === 'specificDate' && <View style={styles(spaceType).selected} />}
        </TouchableOpacity>
        <TouchableOpacity style={styles().borderButton} onPress={handleOpenModal}>
          <Text fontSize={fontSizes.md2} style={{ marginRight: 18 }}>
            {selectedFinalDay
              ? formatDate(parse(selectedFinalDay, DATE_FORMAT, new Date()), DAY_MONTH_YEART_FORMAT)
              : t('workstationScreen.customRecurrenceModal.chooseDate')}
          </Text>
          <Feather name="calendar" size={16} color={colors.grey} />
        </TouchableOpacity>
      </View>
    </View>
  );
}
