import Text from '_/components/Text';
import { colors, fontSizes } from '_/constants/theme';
import React, { useCallback } from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';

interface ButtonInterface {
  checkinAt?: string;
  onPress?: () => void;
  marginTop?: number | undefined;
}

export default function Button({ checkinAt, onPress }: ButtonInterface) {
  const CheckinButton = useCallback(() => {
    if (checkinAt) {
      return (
        <TouchableOpacity
          onPress={onPress}
          style={[styles.button, { backgroundColor: colors.errorRed }]}
        >
          <Text color={colors.white} fontSize={fontSizes.sm} fontType="bold">
            Check-out
          </Text>
        </TouchableOpacity>
      );
    } else if (!checkinAt) {
      return (
        <TouchableOpacity
          onPress={onPress}
          style={[styles.button, { backgroundColor: colors.successGreen }]}
        >
          <Text color={colors.white} fontSize={fontSizes.sm} fontType="bold">
            Check-in
          </Text>
        </TouchableOpacity>
      );
    } else {
      return null;
    }
  }, [checkinAt, onPress]);

  return <CheckinButton />;
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    height: 40,
    width: 80,
  },
});
