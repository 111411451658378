import { Feather } from '@expo/vector-icons';
import Button from '_/components/Button';
import Text from '_/components/Text';
import { colors, fontSizes, spacing } from '_/constants/theme';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Dimensions } from 'react-native';
import Modal from 'react-native-modal';

interface ModalConfirmationProps {
  title: string;
  description: string;
  isVisible: boolean;
  onCloseModal(): void;
  onConfirmModal(): void;
  onCancelButton(): void;
  leftButtonText?: string;
  rightButtonText?: string;
  leftButtonConfirm?: boolean;
  oneOptionOnly?: boolean;
  disabledButtons?: boolean;
}

const windowWidth = Dimensions.get('window').width;

export default function ModalConfirmation({
  title,
  description,
  isVisible,
  leftButtonText,
  rightButtonText,
  onCloseModal,
  onCancelButton,
  onConfirmModal,
  oneOptionOnly,
  disabledButtons,
}: ModalConfirmationProps) {
  const { t } = useTranslation();

  const HandleButtons = useCallback(() => {
    if (!oneOptionOnly) {
      return (
        <View style={styles.itemsRow}>
          <Button
            onPress={onCancelButton}
            backgroundColor={colors.darkBlue}
            style={[styles.modalButton, styles.leftButton]}
            disabled={disabledButtons}
          >
            <Text fontSize={fontSizes.lg} color={colors.clear} fontType="bold">
              {leftButtonText || t('no')}
            </Text>
          </Button>
          <Button
            backgroundColor={colors.red}
            style={styles.modalButton}
            onPress={onConfirmModal}
            disabled={disabledButtons}
          >
            <Text fontSize={fontSizes.lg} color={colors.clear} fontType="bold">
              {rightButtonText || t('yes')}
            </Text>
          </Button>
        </View>
      );
    } else {
      return (
        <View style={styles.itemsRow}>
          <Button backgroundColor={colors.red} style={styles.modalButton} onPress={onConfirmModal}>
            <Text fontSize={fontSizes.lg} color={colors.clear} fontType="bold">
              {rightButtonText || t('yes')}
            </Text>
          </Button>
        </View>
      );
    }
  }, [leftButtonText, onCancelButton, onConfirmModal, oneOptionOnly, rightButtonText, t]);
  return (
    <Modal
      animationIn="slideInUp"
      isVisible={isVisible}
      hasBackdrop
      backdropOpacity={0.5}
      onBackdropPress={onCloseModal}
      onBackButtonPress={onCloseModal}
    >
      <View style={styles.centeredView}>
        <View style={styles.modal}>
          <View style={[styles.header, { alignSelf: oneOptionOnly ? 'center' : undefined }]}>
            <Text fontSize={fontSizes.xl} color={colors.darkGrey} fontType="bold">
              {title}
            </Text>
            {!oneOptionOnly && (
              <Feather name="x" size={fontSizes.xl} color={colors.black} onPress={onCloseModal} />
            )}
          </View>
          <Text
            fontSize={fontSizes.md2}
            color={colors.darkGrey}
            fontType="regular"
            style={styles.description}
          >
            {description}
          </Text>
          <HandleButtons />
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    padding: spacing.md,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  modalButton: {
    flex: 1,
    justifyContent: 'center',
    height: 20,
  },
  leftButton: {
    marginRight: spacing.xs,
  },
  itemsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modal: {
    height: 'auto',
    width: windowWidth - 50,
    maxWidth: 550 - 50,
    backgroundColor: colors.clear,
    borderRadius: 16,
    padding: spacing.xl,
  },
  description: {
    lineHeight: spacing.lg,
  },
});
