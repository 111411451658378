import { colors, spacing } from '_/constants/theme';
import { getSpaceTypeColor, SpaceType } from '_/services/models/enums/space-type.enum';
import { darken } from 'polished';
import { StyleSheet, StatusBar } from 'react-native';
import { getStatusBarHeight } from 'react-native-iphone-x-helper';

const styles = (spaceType?: SpaceType) =>
  StyleSheet.create({
    header: {
      backgroundColor: getSpaceTypeColor(spaceType),
      paddingBottom: spacing.sm,
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      paddingTop: getStatusBarHeight(),
    },
    infoGroup: {
      paddingHorizontal: spacing.md,
      marginTop: 9,
      width: '100%',
    },
    artGroup: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '50%',
    },
    artOne: {
      backgroundColor: darken(0.15, getSpaceTypeColor(spaceType)) || colors.darkYellow,
      position: 'absolute',
      width: '100%',
      height: '30%',
      borderBottomLeftRadius: 15,
      opacity: 0.5,
    },
    artTwo: {
      backgroundColor: darken(0.15, getSpaceTypeColor(spaceType)) || colors.darkYellow,
      position: 'absolute',
      right: 0,
      width: '30%',
      height: '50%',
      borderTopRightRadius: 15,
      borderBottomLeftRadius: 15,
      opacity: 0.5,
    },
    artThree: {
      backgroundColor: darken(0.15, getSpaceTypeColor(spaceType)) || colors.darkYellow,
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '70%',
      height: '20%',
      borderTopLeftRadius: 15,
      opacity: 0.5,
    },
    arrowBackHeader: {
      marginLeft: spacing.md,
      marginTop: (StatusBar.currentHeight || 0) + 21,
    },
    title: {
      marginVertical: 6,
    },
    lowerContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    optionsContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    changeModeButtons: {
      borderRadius: 6,
      backgroundColor: colors.white,
      alignItems: 'center',
      justifyContent: 'center',
      padding: spacing.xs,
    },
    selectorOrSearchButton: {
      marginLeft: spacing.xl,
      marginRight: spacing.sm,
    },
  });

export default styles;
