import { useNavigation } from '@react-navigation/native';
import { images } from '_/assets';
import { Button, Input, LanguageModal } from '_/components';
import generalConfig from '_/config/general';
import { colors } from '_/constants/theme';
import { AppRoute } from '_/navigation/types';
import { usersApi } from '_/services/api';
import logger from '_/services/logger';
import * as Google from 'expo-auth-session/providers/google';
import * as WebBrowser from 'expo-web-browser';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  View,
  Image,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';

import { useAuth } from '../../hooks/AuthContext';
import ForgotPasswordModal from './ForgotPasswordModal/index';
import styles from './styles';
import OAuthMicrosoft from './OAuth/microsoft';
import { useLanguage } from '_/hooks/LanguageContext';

enum Step {
  CheckEmail = 'CheckEmail',
  Login = 'Login',
  Pin = 'Pin',
}

export default function Signin() {
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [languageModal, setLanguangeModal] = useState(false);
  const { language } = useLanguage();
  const [selectedLanguage, setSelectedLanguage] = useState(language.code);
  const [userEmail, setUserEmail] = useState('');
  const [signUpPin, setSignUpPin] = useState('');
  const [step, setStep] = useState(Step.CheckEmail);
  const [userPassword, setUserPassword] = useState('');
  const { signIn, signInGoogle, webSignInGoogle, setAuthLoading } = useAuth();
  const navigation = useNavigation<any>();
  const { t } = useTranslation();

  WebBrowser.maybeCompleteAuthSession();

  const [request, response, promptAsync] = Google.useAuthRequest(generalConfig.googleLoginConfig);
  useEffect(() => {
    if (response?.type === 'success') {
      const { authentication } = response;
      if (authentication) {
        webSignInGoogle(authentication);
      }
    }
    if (response?.type === 'cancel' || response?.type === 'dismiss' || response?.type === 'error') {
      setAuthLoading(false);
    }
  }, [response, setAuthLoading, webSignInGoogle]);

  const handleGoogleSignIn = useCallback(async () => {
    if (Platform.OS === 'web') {
      await promptAsync();
    } else {
      signInGoogle();
    }
  }, [promptAsync, setAuthLoading, signInGoogle]);

  const handleReset = useCallback(() => {
    setUserPassword('');
    setSignUpPin('');
    setStep(Step.CheckEmail);
  }, []);

  const handleCheckEmail = useCallback(async () => {
    if (!userEmail) {
      showMessage({
        message: t('error'),
        description: t('signinScreen.errorMessages.emailRequired'),
        backgroundColor: colors.errorRed,
      });
      return;
    }
    setLoading(true);
    try {
      const response = await usersApi.checkEmail({ email: userEmail, lang: selectedLanguage });
      if (response.canMakeLogin === true) {
        setStep(Step.Login);
      } else {
        setStep(Step.Pin);
        showMessage({
          message: t('signinScreen.successMessages.emailSent'),
          description: t('signinScreen.successMessages.emailSentDesc'),
          backgroundColor: colors.successGreen,
          duration: 10000,
        });
      }
    } catch (error: any) {
      showMessage({
        message: t('error'),
        description: t('genericError'),
        backgroundColor: colors.errorRed,
      });
      logger(error);
    }
    setLoading(false);
  }, [t, userEmail]);

  const handleSendPin = useCallback(async () => {
    if (!signUpPin) {
      showMessage({
        message: t('error'),
        description: t('signinScreen.errorMessages.pinCodeRequired'),
        backgroundColor: colors.errorRed,
      });
      return;
    }

    try {
      const response = await usersApi.sendSignUpPin({ email: userEmail, pin: signUpPin });

      handleReset();
      navigation.navigate(AppRoute.SIGNUP, {
        token: response.data.token,
        user: response.data.user,
        email: userEmail,
        lang: selectedLanguage,
      });
    } catch (error: any) {
      showMessage({
        message: t('error'),
        description: t('genericError'),
        backgroundColor: colors.errorRed,
      });
      logger(error);
    }
  }, [handleReset, navigation, selectedLanguage, signUpPin, t, userEmail]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleSignin() {
    if (!userEmail || !userPassword) {
      showMessage({
        message: t('error'),
        description: t('signinScreen.errorMessages.passwordRequired'),
        backgroundColor: colors.errorRed,
      });
      return;
    }

    try {
      setAuthLoading(true);
      await signIn({ email: userEmail, password: userPassword });
    } catch (error: any) {
      setAuthLoading(false);
      if (error?.response?.data?.message === 'Invalid login') {
        showMessage({
          message: t('error'),
          description: t('signinScreen.errorMessages.wrongCredentials'),
          backgroundColor: colors.errorRed,
        });
      } else {
        showMessage({
          message: t('error'),
          description: t('signinScreen.errorMessages.loginFail'),
          backgroundColor: colors.errorRed,
        });
      }
      logger(error);
    }
  }

  const handleButtonPress = useCallback(() => {
    if (step === Step.CheckEmail) {
      handleCheckEmail();
    } else if (step === Step.Login) {
      handleSignin();
    } else if (step === Step.Pin) {
      handleSendPin();
    }
  }, [handleCheckEmail, handleSendPin, handleSignin, step]);

  const HandleButton = useCallback(() => {
    return (
      <Button
        loading={loading}
        backgroundColor={colors.lightBlue}
        onPress={handleButtonPress}
        textColor={colors.white}
        style={styles.loginButton}
      >
        {step === Step.CheckEmail || step === Step.Pin ? t('next') : t('signinScreen.signin')}
      </Button>
    );
  }, [handleButtonPress, loading, step, t]);

  const showMicrosoft =
    (process.env.APP_MANIFEST as any)?.name === 'PlaceHub Stg' ||
    process.env.NODE_ENV === 'development';

  return (
    <>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.wrapper}>
          <View style={styles.background}>
            <Image source={images.upperCard} style={styles.topImage} />
            <Image source={images.logo} style={styles.logo} />
            <Image source={images.lowerCard} style={styles.bottomImage} />
          </View>
          <KeyboardAvoidingView enabled behavior="padding" style={{ flex: 1.5 }}>
            <View style={styles.modal}>
              <Input
                tag={t('email')}
                icon="mail"
                placeholder="email@placehub.io"
                autoCapitalize="none"
                returnKeyType="next"
                autoComplete="email"
                textContentType="emailAddress"
                keyboardType="email-address"
                autoCorrect={false}
                value={userEmail.trim().toLowerCase()}
                onChangeText={setUserEmail}
                editable={!loading && step === Step.CheckEmail}
                disabled={step === Step.Login || step === Step.Pin}
                onSubmitEditing={handleButtonPress}
              />

              {step === Step.Login && (
                <Input
                  isPassword
                  tag={t('signinScreen.password')}
                  placeholder="*********"
                  autoCapitalize="none"
                  textContentType="password"
                  value={userPassword}
                  onChangeText={setUserPassword}
                  editable={!loading}
                  returnKeyType="go"
                  onSubmitEditing={handleButtonPress}
                  autoFocus
                />
              )}
              {step === Step.Pin && (
                <Input
                  tag={t('signinScreen.signupCode')}
                  placeholder="123554"
                  autoCapitalize="none"
                  returnKeyType="next"
                  keyboardType="numeric"
                  autoCorrect={false}
                  onChangeText={setSignUpPin}
                  value={signUpPin}
                  onSubmitEditing={handleButtonPress}
                  autoFocus
                />
              )}
              <HandleButton />
              <View>
                {step === Step.CheckEmail && Platform.OS === 'web' && (
                  <Button
                    leftIcon={images.googleLogo}
                    onPress={handleGoogleSignIn}
                    disabled={!request}
                  >
                    {t('signinScreen.signInWithGoogle')}
                  </Button>
                )}
                {showMicrosoft && <OAuthMicrosoft />}
              </View>

              {(step === Step.Pin || step === Step.Login) && (
                <Button
                  disabled={loading}
                  onPress={() => {
                    setUserPassword('');
                    setStep(Step.CheckEmail);
                  }}
                  textColor={colors.midGrey}
                >
                  {t('back')}
                </Button>
              )}
              {(step === Step.CheckEmail || step === Step.Login) && (
                <Button
                  disabled={loading}
                  onPress={() => setModalVisible(true)}
                  textColor={colors.midGrey}
                >
                  {t('signinScreen.forgotPassword')}
                </Button>
              )}
              {Platform.OS !== 'web' && (
                <Button
                  disabled={loading}
                  onPress={() => setLanguangeModal(true)}
                  textColor={colors.midGrey}
                >
                  {t('profileScreen.chooseLanguangeButton.chooseLanguage')}
                </Button>
              )}
            </View>
          </KeyboardAvoidingView>
          <ForgotPasswordModal
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
            selectedEmail={userEmail}
          />
          <LanguageModal
            visible={languageModal}
            handleClose={() => setLanguangeModal(false)}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        </View>
      </TouchableWithoutFeedback>
    </>
  );
}
