export enum ReportType {
  DEFECT = 'DEFECT',
  IMPROVEMENT = 'IMPROVEMENT',
  CLEANING = 'CLEANING',
  JUSTIFICATION_OF_ABSENCE = 'JUSTIFICATION_OF_ABSENCE',
  OTHER = 'OTHER',
}

export const reportTypeName = {
  [ReportType.DEFECT]: 'Defeito',
  [ReportType.IMPROVEMENT]: 'Melhoria',
  [ReportType.CLEANING]: 'Limpeza',
  [ReportType.JUSTIFICATION_OF_ABSENCE]: 'Justificativa da ausência',
  [ReportType.OTHER]: 'Outros',
};
