import { createStackNavigator } from '@react-navigation/stack';
import DatePicker from '_/screens/DatePicker';
import EditEvents from '_/screens/EditEvents';
import Home from '_/screens/Home';
import MyReservations from '_/screens/MyReservations';
import Notifications from '_/screens/Notifications';
import OnBoarding from '_/screens/OnBoarding';
import QRCodeScanner from '_/screens/QRCodeScanner';
import RecurringReservations from '_/screens/RecurringReservations';
import ReportAProblem from '_/screens/Reports/ReportAProblem';
import CreateReport from '_/screens/Reports/ReportAProblem/CreateReport';
import SetSlotCoordinate from '_/screens/SetSlotCoordinate';
import Workstation from '_/screens/Workstation';
import React from 'react';

import { AppRoute } from './types';

const HomeStack = createStackNavigator();

export default function HomeStackScreen() {
  return (
    <HomeStack.Navigator
      screenOptions={{ animationEnabled: true, headerShown: false, presentation: 'card' }}
    >
      <HomeStack.Screen options={{ headerShown: false }} name={AppRoute.HOME} component={Home} />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name={AppRoute.NO_CONTENT}
        component={OnBoarding}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name={AppRoute.MY_RESERVATIONS}
        component={MyReservations}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name={AppRoute.RECURRING_RESERVATIONS}
        component={RecurringReservations}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name={AppRoute.EDIT_EVENT}
        component={EditEvents}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name={AppRoute.DATE_PICKER}
        component={DatePicker}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name={AppRoute.WORK}
        component={Workstation}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name={AppRoute.QR_CODE_SCANNER}
        component={QRCodeScanner}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name={AppRoute.NOTIFICATIONS}
        component={Notifications}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name={AppRoute.REPORT_PROBLEM}
        component={ReportAProblem}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name={AppRoute.CREATE_REPORT}
        component={CreateReport}
      />
      <HomeStack.Screen
        options={{ headerBackTitleVisible: false, title: 'Select a slot below' }}
        name={AppRoute.SET_SLOT_COORDINATE}
        component={SetSlotCoordinate}
      />
    </HomeStack.Navigator>
  );
}
