import Text from '_/components/Text';
import { colors, fontSizes, spacing } from '_/constants/theme';
import { getSpaceTypeColor, SpaceType } from '_/services/models/enums/space-type.enum';
import React from 'react';
import { View, TouchableOpacity, StyleSheet, GestureResponderEvent } from 'react-native';

interface RadioInputProps {
  text?: string;
  spaceType: SpaceType;
  selected?: boolean;
  onPress?: ((event: GestureResponderEvent) => void) | undefined;
}

export default function RadioInput({ text, spaceType, selected, onPress }: RadioInputProps) {
  return (
    <TouchableOpacity style={styles.radioInputContainer} onPress={onPress}>
      <View style={styles.radioInput}>
        {selected && (
          <View style={[styles.selected, { backgroundColor: getSpaceTypeColor(spaceType) }]} />
        )}
      </View>
      <Text fontSize={fontSizes.md} fontType="medium">
        {text}
      </Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  radioInputContainer: {
    flexDirection: 'row',
    marginVertical: spacing.sm,
  },
  radioInput: {
    height: 18,
    width: 18,
    borderRadius: 18 * 0.5,
    borderWidth: 4,
    borderColor: colors.white,
    marginRight: spacing.xs,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    height: 10,
    width: 10,
    borderRadius: 10 * 0.5,
    backgroundColor: colors.black,
  },
});
