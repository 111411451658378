import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Text } from '_/components';
import { DATETAB_TIME_FORMAT, DATE_FORMAT } from '_/config/date';
import { colors, fontSizes, spacing } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { useLanguage } from '_/hooks/LanguageContext';
import { SpaceType } from '_/services/models/enums/space-type.enum';
import { parse } from 'date-fns';
import React, { useMemo } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';

interface DateSelectorProps {
  spaceType?: string;
}

export default function DateSelector({ spaceType }: DateSelectorProps) {
  const { language, locale } = useLanguage();
  const { availableListLoading: loading } = useEventContext();
  const {
    handleChangeSelectedDay,
    selectedDay,
    today,
    selectedEventDate,
    maxDate,
    timePickerVisible,
  } = useDateContext();
  const navigation = useNavigation<any>();

  const checkIfIsToday = useMemo(() => {
    return selectedDay === formatDate(today, DATE_FORMAT);
  }, [selectedDay, today]);

  const isMaxDate = useMemo(() => {
    return maxDate && selectedDay === formatDate(maxDate as Date, DATE_FORMAT);
  }, [maxDate, selectedDay]);

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={[styles.button, styles.sideButton]}
        disabled={checkIfIsToday || loading === 'loadData'}
        onPress={() => handleChangeSelectedDay('sub')}
      >
        <Feather
          name="chevron-left"
          color={checkIfIsToday || loading === 'loadData' ? colors.lightGrey : colors.black}
          size={22}
        />
      </TouchableOpacity>
      <TouchableOpacity style={[styles.button, styles.innerButton]} onPress={navigation.goBack}>
        <Text fontSize={fontSizes.md}>
          {formatDate(parse(selectedDay, DATE_FORMAT, new Date()), language.format?.full_date, {
            locale,
          })}
        </Text>
        {(timePickerVisible || spaceType === SpaceType.WORK) && (
          <Text fontSize={fontSizes.md} fontType="semibold">
            {formatDate(selectedEventDate.start, DATETAB_TIME_FORMAT)} -{' '}
            {formatDate(selectedEventDate.end, DATETAB_TIME_FORMAT)}
          </Text>
        )}
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.button, styles.sideButton]}
        onPress={() => handleChangeSelectedDay('add')}
        disabled={isMaxDate || loading === 'loadData'}
      >
        <Feather
          name="chevron-right"
          color={isMaxDate || loading === 'loadData' ? colors.lightGrey : colors.black}
          size={22}
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: spacing.md,
    paddingBottom: spacing.xxxs,
    paddingTop: spacing.lg,
  },
  button: {
    borderWidth: 1,
    borderColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    height: 48,
  },
  innerButton: {
    flex: 1,
    marginHorizontal: spacing.sm,
  },
  sideButton: {
    width: 48,
  },
});
