import React from 'react';
import { useMap } from 'react-leaflet';

import SearchResultList from '../SearchResultList';

const SearchList: React.FC = () => {
  const map = useMap();

  const handleAnimateToSlot = (slot?: any) => {
    if (slot && slot.latitude && slot.longitude) {
      map.setView([slot.latitude, slot.longitude]);
    }
  };

  return <SearchResultList handleAnimateToSlot={handleAnimateToSlot} />;
};

export default SearchList;
