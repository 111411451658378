import { images } from '_/assets';
import { Text } from '_/components/';
import { fontSizes, spacing } from '_/constants/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, StyleSheet, Dimensions } from 'react-native';

export default function NoReservedList() {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Image source={images.denied} style={styles.image} />
      <Text fontType="medium" fontSize={fontSizes.md2} style={{ textAlign: 'center' }}>
        {t('workstationScreen.noReservation')}
      </Text>
    </View>
  );
}

const { height } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: height * 0.1 + 32,
    marginBottom: 60,
    paddingHorizontal: spacing.md,
  },
  image: {
    height: 87,
    width: 87,
    marginBottom: spacing.md,
  },
});
