import { colors } from '_/constants/theme';
import { t } from 'i18next';
import { MutableRefObject } from 'react';
import { showMessage } from 'react-native-flash-message';

export function networkErrorMessage(error: any, modalRef?: MutableRefObject<any>) {
  const message = {
    message: t('error'),
    description: t('connectionIsUnstable'),
    backgroundColor: colors.errorRed,
  };

  if (error.message === 'Network Error') {
    if (modalRef) {
      modalRef?.current?.showMessage(message);
    }
    showMessage(message);

    throw error;
  }
}
