import Button from '_/components/Button';
import Input from '_/components/Input';
import Title from '_/components/Title';
import { colors, spacing } from '_/constants/theme';
import { getSpaceTypeColor, SpaceType } from '_/services/models/enums/space-type.enum';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, StyleSheet, TextInput } from 'react-native';
import Modal from 'react-native-modal';

interface CustomTextFieldModalProps {
  label: string | undefined;
  visible: boolean;
  value: string | undefined;
  spaceType: SpaceType;
  hideDropdown: () => void;
  setTextFieldValue: (value: string) => void;
}

export default function CustomTextFieldModal({
  label,
  visible,
  value,
  spaceType,
  hideDropdown,
  setTextFieldValue,
}: CustomTextFieldModalProps) {
  const inputRef = useRef() as React.RefObject<TextInput>;
  const { t } = useTranslation();

  useEffect(() => {
    if (visible && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [visible, inputRef]);

  return (
    <Modal
      style={styles.modal}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      isVisible={visible}
      coverScreen
      hasBackdrop
      propagateSwipe
      backdropOpacity={0.5}
      onBackdropPress={() => hideDropdown()}
      onBackButtonPress={() => hideDropdown()}
    >
      <Title
        title={label}
        hasCloseButton
        onPress={() => hideDropdown()}
        style={{ maxWidth: '90%' }}
        numberOfLines={20}
      />
      <ScrollView
        keyboardShouldPersistTaps="handled"
        style={styles.modalContent}
        showsVerticalScrollIndicator={false}
      >
        <Input
          ref={inputRef}
          autoCorrect={false}
          onChangeText={setTextFieldValue}
          returnKeyType="done"
          value={value}
        />
        <Button
          textColor={colors.clear}
          backgroundColor={getSpaceTypeColor(spaceType)}
          onPress={hideDropdown}
        >
          {t('confirm')}
        </Button>
      </ScrollView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    width: '100%',
    ...(Platform.OS === 'web' && { maxWidth: 550 }),
    margin: 0,
    marginTop: 80,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    backgroundColor: colors.clear,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    paddingVertical: 20,
  },
  modalContent: {
    paddingHorizontal: spacing.md,
  },
});
