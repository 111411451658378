import { Picker } from '@react-native-picker/picker';
import { colors, fontFamily, fontSizes, spacing } from '_/constants/theme';
import { getHours, getMinutes } from 'date-fns';
import React from 'react';
import { Platform, StyleSheet } from 'react-native';

interface IWebPicker {
  value: Date;
  disabled?: boolean;
  type: 'hour' | 'minutes';
  setHour?: (option: number) => void;
  setMinute?: (option: number) => void;
}

const minutes = [...Array(60).keys()];
const hours = [...Array(24).keys()];

const hourOptions = hours.map((hour) => ({
  label: `${hour}`,
  value: hour,
}));

const minutesOptions = minutes.map((minute) => ({
  label: `${minute}`.padStart(2, '0'),
  value: minute,
}));

const WebPicker = ({ value, disabled, type, setHour, setMinute }: IWebPicker) => {
  return (
    <Picker
      selectedValue={type === 'hour' ? getHours(value) : getMinutes(value)}
      enabled={!disabled}
      mode="dropdown"
      style={Platform.OS === 'web' ? styles.inputWeb : styles.inputAndroid}
      onValueChange={(v) => {
        if (type === 'hour') {
          const h = Number(v);
          setHour?.(h);
        } else {
          const m = Number(v);
          setMinute?.(m);
        }
      }}
    >
      {type === 'hour'
        ? hourOptions.map((option) => (
            <Picker.Item
              key={option.value}
              label={option.label}
              value={option.value}
              style={styles.pickerItem}
            />
          ))
        : minutesOptions.map((option) => (
            <Picker.Item
              key={option.value}
              label={option.label}
              value={option.value}
              style={styles.pickerItem}
            />
          ))}
    </Picker>
  );
};

const styles = StyleSheet.create({
  inputWeb: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.white,
    padding: spacing.sm,
    fontSize: fontSizes.xl,
    fontFamily: fontFamily.bold,
  },
  inputAndroid: {
    width: '25%',
    fontSize: fontSizes.xl,
    fontFamily: fontFamily.bold,
  },
  pickerItem: {
    fontSize: fontSizes.xl,
    fontFamily: fontFamily.bold,
  },
});

export default WebPicker;
