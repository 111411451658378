import SearchBar from '_/components/SearchBar';
import Text from '_/components/Text';
import Title from '_/components/Title';
import { colors, fontFamily, spacing } from '_/constants/theme';
import { usersSlotsApi } from '_/services/api';
import { UsersModel } from '_/services/models/users.model';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, FlatList, ActivityIndicator, Platform } from 'react-native';
import FlashMessage from 'react-native-flash-message';
import Modal from 'react-native-modal';

import Member from './Member';

interface SearchForMembersModalProps {
  visible: boolean;
  hideDropdown: () => void;
  slotId: string;
  setForMember: React.Dispatch<React.SetStateAction<UsersModel | undefined>>;
}

export default function SearchForMembersModal({
  visible,
  hideDropdown,
  slotId,
  setForMember,
}: SearchForMembersModalProps) {
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState<UsersModel[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const { t } = useTranslation();
  const modalMessage = useRef<any>(null);

  const getUsersSlot = useCallback(
    async (slotId: string) => {
      setLoading(true);
      try {
        const result = await usersSlotsApi.getItem(slotId, {
          params: {
            ...(searchValue && { 'name[$iLike]': `${searchValue}%` }),
            '$sort[name]': 1,
          },
        });
        setMembers(result.data);
      } catch (error) {
        modalMessage.current.showMessage({
          message: 'Erro!',
          description: 'Houve um erro com a pesquisa. Por favor, tente novamente mais tarde',
          backgroundColor: colors.errorRed,
        });
      }
      setLoading(false);
    },
    [searchValue]
  );

  useEffect(() => {
    if (visible) {
      getUsersSlot(slotId);
    }
  }, [getUsersSlot, searchValue, slotId, visible]);

  return (
    <Modal
      style={styles.modal}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      isVisible={visible}
      coverScreen
      hasBackdrop
      propagateSwipe
      backdropOpacity={0.5}
      onBackdropPress={() => hideDropdown()}
      onBackButtonPress={() => hideDropdown()}
    >
      <FlashMessage
        floating
        position="top"
        duration={5000}
        titleStyle={{ fontFamily: fontFamily.bold }}
        textStyle={{ fontFamily: fontFamily.regular }}
        ref={modalMessage}
      />
      <Title
        title={t('workstationScreen.searchMemberModal.searchMember')}
        hasCloseButton
        onPress={() => hideDropdown()}
        style={[styles.title, { maxWidth: '90%' }]}
      />
      <View style={styles.modalContent}>
        <SearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder={t('workstationScreen.searchMemberModal.search')}
        />
        <FlatList
          ListEmptyComponent={
            loading ? (
              <ActivityIndicator color={colors.black} />
            ) : (
              <Text>{t('workstationScreen.searchMemberModal.noResults')} </Text>
            )
          }
          showsVerticalScrollIndicator={false}
          keyExtractor={(item) => item.id}
          style={{ marginTop: 32 }}
          data={members}
          keyboardShouldPersistTaps="always"
          renderItem={({ item }) => (
            <Member user={item} setForMember={setForMember} hideDropdown={hideDropdown} />
          )}
        />
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    width: '100%',
    ...(Platform.OS === 'web' && { maxWidth: 550 }),
    margin: 0,
    marginTop: 80,
    alignSelf: 'center',
    justifyContent: 'flex-start',
    backgroundColor: colors.clear,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    paddingVertical: 20,
  },
  modalContent: {
    paddingHorizontal: spacing.md,
  },
  title: {
    marginBottom: spacing.md,
  },
});
