import { DATETAB_TIME_FORMAT } from '_/config/date';
import { colors, fontSizes, spacing } from '_/constants/theme';
import { formatDate } from '_/helpers/formatDate';
import React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';

import Avatar from '../Avatar';
import Text from '../Text';

interface ModalInterface {
  eventModalVisible?: string | boolean;
  onBackdropPress: () => void;
  onBackButtonPress: () => void;
  onSwipeStart: () => void;
  source?: string;
  id: string;
  name?: string;
  startDate: string;
  endDate: string;
  description?: string;
}

export default function EventModal({
  eventModalVisible,
  id,
  onBackdropPress,
  onBackButtonPress,
  onSwipeStart,
  name,
  source,
  startDate,
  endDate,
  description,
}: ModalInterface) {
  return (
    <Modal
      style={[styles.modal, { height: description && 160 }]}
      animationIn="fadeIn"
      animationOut="fadeOut"
      isVisible={id === eventModalVisible}
      coverScreen
      hasBackdrop
      backdropOpacity={0.5}
      onBackdropPress={onBackdropPress}
      onBackButtonPress={onBackButtonPress}
      onSwipeStart={onSwipeStart}
    >
      <View style={styles.whoReservedContainer}>
        <View style={styles.userInfo}>
          <Avatar size={40} source={source} />
          <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            <View style={styles.username}>
              <Text fontType="bold" fontSize={fontSizes.md2}>
                {name}
              </Text>
              <Text fontSize={fontSizes.md} color={colors.black}>
                {formatDate(Date.parse(startDate), DATETAB_TIME_FORMAT)} às{' '}
                {formatDate(Date.parse(endDate), DATETAB_TIME_FORMAT)}
              </Text>
            </View>
            {!!description && (
              <View style={styles.description}>
                <Text numberOfLines={4} style={{ textAlign: 'center' }}>
                  {description}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
}
const { height } = Dimensions.get('window');

const styles = StyleSheet.create({
  modal: {
    backgroundColor: colors.white,
    borderRadius: 16,
    alignSelf: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    width: '85%',
    maxWidth: 550,
    height: 80,
    top: height / 2.5,
  },
  whoReservedContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: spacing.md,
    paddingVertical: spacing.xs,
    flex: 1,
  },
  userInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  description: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '80%',
    marginTop: spacing.xxxs,
  },
  // checkoutTag: {
  //   maxWidth: 90,
  //   marginLeft: spacing.sm,
  //   marginTop: spacing.xxxs,
  // },
  username: {
    alignItems: 'center',
    marginLeft: spacing.sm,
  },
});
