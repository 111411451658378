import { Feather } from '@expo/vector-icons';
import { Text } from '_/components';
import { colors, fontSizes } from '_/constants/theme';
import React from 'react';
import { View, TouchableOpacity, TouchableOpacityProps } from 'react-native';

import styles from './styles';

interface ProfileButtonProps extends TouchableOpacityProps {
  buttonName: string;
  buttonInfo: string;
  icon: 'user' | 'calendar' | 'bell' | 'file-text' | 'globe' | 'user-x';
}

export default function ProfileButton({
  buttonName,
  buttonInfo,
  icon,
  ...rest
}: ProfileButtonProps) {
  return (
    <TouchableOpacity {...rest}>
      <View style={styles.buttonContainer}>
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.buttonIcon}>
            <Feather name={icon} size={24} />
          </View>
          <View>
            <View style={styles.buttonNameAndInfo}>
              <Text fontSize={fontSizes.md2} fontType="bold">
                {buttonName}
              </Text>
              <Text style={styles.info} color={colors.midGrey} fontSize={fontSizes.sm}>
                {buttonInfo}
              </Text>
            </View>
          </View>
        </View>
        <Feather name="chevron-right" size={20} />
      </View>
    </TouchableOpacity>
  );
}
