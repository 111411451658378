import Text from '_/components/Text';
import { fontSizes, spacing } from '_/constants/theme';
import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

interface TagProps {
  text?: string;
  backgroundColor: string;
  style?: StyleProp<ViewStyle>;
  icon?: 'clock' | 'check';
}

export default function Tag({ text, backgroundColor, style }: TagProps) {
  return (
    <View style={[style, styles.tag, { backgroundColor }]}>
      <Text fontSize={fontSizes.xs} color="#FFF" style={{ textAlign: 'center' }}>
        {text}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  tag: {
    paddingHorizontal: spacing.sm,
    paddingBottom: 2,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
  },
});
