import { colors, spacing } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    margin: 0,
    paddingTop: 24,
    paddingBottom: 32,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    backgroundColor: colors.clear,
  },
  flags: {
    width: 30,
    height: 30,
  },
  wrapper: {
    marginTop: spacing.xl,
    paddingHorizontal: spacing.md,
  },
});

export default styles;
