import { colors } from '_/constants/theme';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F8F9fA',
    marginHorizontal: 20,
    borderRadius: 8,
    fontSize: 18,
  },
  optionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomColor: colors.grey,
    borderBottomWidth: 1,
    padding: 10,
    backgroundColor: colors.white,
  },
  optionTxt: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    marginLeft: 20,
  },
});

export default styles;
