import { Header } from '_/components';
import { colors } from '_/constants/theme';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions } from 'react-native';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';

import ClosedReports from './ClosedReports';
import OpenReports from './OpenReports';
import styles from './styles';

export default function ReportAProblem() {
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();

  const FirstRoute = useCallback(() => <OpenReports />, []);

  const SecondRoute = useCallback(() => <ClosedReports />, []);

  const renderScene = SceneMap({
    first: FirstRoute,
    second: SecondRoute,
  });

  const routes = [
    { key: 'first', title: t('reportsScreen.opened') },
    { key: 'second', title: t('reportsScreen.closed') },
  ];

  const renderTabBar = (props: any) => (
    <TabBar
      {...props}
      indicatorStyle={styles.indicator}
      style={styles.tab}
      labelStyle={styles.label}
      inactiveColor={colors.lightGrey}
      activeColor={colors.lightBlue}
      allowFontScaling={false}
    />
  );

  const initialLayout = { width: Dimensions.get('window').width };

  return (
    <>
      <Header screenName={t('reportsScreen.reports')} />
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={initialLayout}
        renderTabBar={renderTabBar}
      />
    </>
  );
}
