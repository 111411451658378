import { Button, Input, Text, Title } from '_/components';
import { colors, fontSizes } from '_/constants/theme';
import { usersApi } from '_/services/api';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { ForgotPasswordProps } from '..';
import styles from '../SendEmail/styles';

export default function ForgotPassword({
  setNextStep,
  setToken,
  userEmail,
  pin,
  setPin,
  modalRef,
}: ForgotPasswordProps) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleValidadePinCode = async () => {
    if (!pin) {
      modalRef?.current?.showMessage({
        message: t('error'),
        description: t('signinScreen.errorMessages.pinRequired'),
        backgroundColor: colors.errorRed,
      });
      return;
    }

    try {
      setLoading(true);
      const { token } = await usersApi.validatePinCode({ email: userEmail, pin });
      setToken!(token);
      setNextStep();
    } catch (error) {
      setLoading(false);
      modalRef?.current?.showMessage({
        message: t('error'),
        description: t('signinScreen.errorMessages.invalidPin'),
        backgroundColor: colors.errorRed,
      });
    }
  };

  return (
    <>
      <Title title={t('signinScreen.forgotPasswordModal.recoveryCode')} style={styles.title} />
      <View style={styles.wrapper}>
        <Text fontSize={fontSizes.md2}>
          {t('signinScreen.forgotPasswordModal.recoveryCodeMessage')}
        </Text>
        <Text fontSize={fontSizes.md2} fontType="bold" marginBottom={32}>
          {userEmail}
        </Text>
        <Input
          tag={t('signinScreen.forgotPasswordModal.recoveryCode')}
          placeholder="123554"
          autoCapitalize="none"
          returnKeyType="next"
          keyboardType="numeric"
          autoCorrect={false}
          value={pin}
          onChangeText={setPin}
        />
        <Button
          loading={loading}
          backgroundColor={colors.lightBlue}
          textColor={colors.white}
          onPress={handleValidadePinCode}
        >
          {t('confirm')}
        </Button>
      </View>
    </>
  );
}
