import logger from '../logger';
import { CheckinType } from '../models/enums/checkin-type.enum';
import { EventsModel, EventsResponse, GetAvailableSlotsType } from '../models/events.model';
import RequestCreator from './helpers/requestCreator';
import { ReqBase } from './types/ReqQuery';

interface EventsTypes {
  id: string;
  slotId?: string;
  latitude?: number;
  longitude?: number;
}

interface RequestReportTypes {
  start?: Date;
  end?: Date;
  fullReport?: boolean;
}

class Events extends RequestCreator<EventsModel, EventsResponse> {
  checkinByQrCode = async ({ id, slotId }: EventsTypes) => {
    try {
      const result = await this.api.post(
        '/check-in',
        {
          checkinAt: new Date(),
          slotId,
          eventId: id,
        },
        {
          params: { checkinType: CheckinType.QR_CODE },
        }
      );
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  checkinByLocation = async ({ id, latitude, longitude }: EventsTypes) => {
    try {
      const result = await this.api.post(
        '/check-in',
        {
          checkinAt: new Date(),
          latitude,
          longitude,
          eventId: id,
        },
        {
          params: { checkinType: CheckinType.LOCATION },
        }
      );
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  checkout = async ({ id }: Partial<EventsTypes>) => {
    try {
      const result = await this.api.patch(`${this.path}/${id}`, {
        checkoutAt: new Date(),
        recurrenceId: null,
      });
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  getSpaceEvents = async ({
    spaceId,
    startAtTime,
    endAtTime,
    ...rest
  }: GetAvailableSlotsType & ReqBase<any>) => {
    try {
      const result = await this.api.get(`/space-events/${spaceId}`, {
        params: {
          ...rest,
          start: startAtTime,
          end: endAtTime,
          listAllSlots: false,
        },
      });

      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  requestReport = async ({ start, end }: RequestReportTypes) => {
    try {
      const result = await this.api.post(
        '/report/events',
        {},
        {
          params: {
            start,
            end,
          },
        }
      );
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };
}

const api = new Events('/events');
export default api;
