import logger from '../logger';
import { DisabledSpaceDateType } from '../models/spaces.model';
import RequestCreator from './helpers/requestCreator';

class DisabledSpaceDate extends RequestCreator<DisabledSpaceDateType> {
  private disabledSpace = '/disabled-space-date';
  getDatesBySpaceId = async ({ spaceId, ...rest }: { [x: string]: any; spaceId: any }) => {
    try {
      const result = await this.api.get(this.disabledSpace, {
        params: { spaceId, ...rest },
      });
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };
}

const api = new DisabledSpaceDate('/disabled-space-date');
export default api;
