export default {
  tokenKey: '@Placehub:token',
  userKey: '@Placehub:user',
  memberKey: '@Placehub:member',
  refreshTokenKey: '@Placehub:refreshToken',
  userIdKey: '@Placehub:userId',
  tokenExpirationKey: '@Placehub:tokenExpiration',
  languageKey: '@app:language',
  loadUserTimestamp: '@app:loadUserTimestamp',
};
