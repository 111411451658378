import { colors, fontSizes, spacing } from '_/constants/theme';
import React from 'react';
import {
  TouchableOpacity,
  StyleSheet,
  TouchableOpacityProps,
  StyleProp,
  ViewStyle,
  ActivityIndicator,
  ImageSourcePropType,
  View,
  TextStyle,
} from 'react-native';

import Text from '../Text/index';
import ButtonIcon from './ButtonIcon';

interface ButtonInterface extends TouchableOpacityProps {
  children: string | JSX.Element;
  backgroundColor?: string | undefined;
  hasBorder?: boolean;
  textColor?: string | undefined;
  borderWidth?: number | undefined;
  style?: StyleProp<ViewStyle>;
  isHidden?: boolean;
  leftIcon?: string | ImageSourcePropType;
  rightIcon?: string | ImageSourcePropType;
  iconSize?: number;
  loading?: boolean;
  fontStyle?: StyleProp<TextStyle>;
}

export default function Button({
  children,
  backgroundColor,
  textColor,
  style,
  hasBorder = false,
  isHidden,
  leftIcon,
  rightIcon,
  iconSize = 24,
  loading,
  disabled,
  fontStyle,
  ...rest
}: ButtonInterface) {
  return (
    <TouchableOpacity
      style={[
        styles.button,
        {
          backgroundColor,
          borderWidth: hasBorder ? 1 : 0,
          display: isHidden ? 'none' : 'flex',
          justifyContent: loading ? 'center' : 'space-between',
        },
        style,
      ]}
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? (
        <ActivityIndicator size="small" color={colors.white} style={styles.activityIndicator} />
      ) : (
        <View style={styles.wrapper}>
          <View style={styles.leftIcon}>
            <ButtonIcon icon={leftIcon} iconSize={iconSize} textColor={textColor} />
          </View>
          <Text
            center
            fontType="medium"
            color={textColor}
            fontSize={fontSizes.md2}
            adjustsFontSizeToFit
            numberOfLines={2}
            style={[{ alignSelf: 'center' }, fontStyle]}
          >
            {children}
          </Text>
          <View style={styles.rightIcon}>
            <ButtonIcon icon={rightIcon} iconSize={iconSize} textColor={textColor} />
          </View>
        </View>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 2,
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: colors.lightGrey,
    borderRadius: 8,
    marginTop: spacing.md,
    width: '100%',
    padding: spacing.md,
    minHeight: 52,
  },
  activityIndicator: {
    paddingVertical: 2,
  },
  leftIcon: {
    alignSelf: 'flex-start',
  },
  rightIcon: {
    alignSelf: 'flex-end',
  },
});
