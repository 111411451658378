import { Header } from '_/components';
import general from '_/config/general';
import { useNotificationContext } from '_/hooks/NotificationsContext';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, RefreshControl } from 'react-native';

import NoNotifications from './NoNotifications';
import Notification from './Notification';

export default function Notifications() {
  const { t } = useTranslation();
  const {
    notifications,
    notReadNotifications,
    notificationsListLoading,
    getNotifications,
    setNotificationsRead,
    getNotReadNotifications,
  } = useNotificationContext();
  const onEndReachedCalledDuringMomentum = useRef(false);

  useEffect(() => {
    if (notReadNotifications > 0) {
      setNotificationsRead();
    }
    getNotReadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notReadNotifications, setNotificationsRead]);

  useEffect(() => {
    getNotifications(true);
  }, []);

  return (
    <>
      <Header screenName={t('notifications')} />
      <FlatList
        key="Notifications"
        contentContainerStyle={{ paddingBottom: 40 }}
        ListEmptyComponent={notificationsListLoading ? null : <NoNotifications />}
        showsVerticalScrollIndicator={false}
        data={notifications}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => <Notification notification={item} />}
        refreshControl={
          <RefreshControl
            refreshing={notificationsListLoading}
            onRefresh={async () => await getNotifications(true)}
          />
        }
        onEndReached={async () => {
          if (
            !onEndReachedCalledDuringMomentum.current &&
            !notificationsListLoading &&
            notifications.length >= general.notificationLimit
          ) {
            await getNotifications();
            onEndReachedCalledDuringMomentum.current = true;
          }
        }}
        onMomentumScrollBegin={() => (onEndReachedCalledDuringMomentum.current = false)}
        onEndReachedThreshold={0.01}
      />
    </>
  );
}
