export const weekDays = [
  {
    id: 0,
    name: 'mon',
  },
  {
    id: 1,
    name: 'tue',
  },
  {
    id: 2,
    name: 'wed',
  },
  {
    id: 3,
    name: 'thu',
  },
  {
    id: 4,
    name: 'fri',
  },
  {
    id: 5,
    name: 'sat',
  },
  {
    id: 6,
    name: 'sun',
  },
];
