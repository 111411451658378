import { Title, WebWrapper } from '_/components';
import { useLocationContext } from '_/hooks/LocationContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, Platform, View } from 'react-native';

import Office from './Office';

export default function OfficesList() {
  const { locations } = useLocationContext();
  const { t } = useTranslation();

  return (
    <WebWrapper>
      <Title title={t('locations')} />
      <View style={{ flex: 1 }}>
        <FlatList
          key="OfficesList"
          keyboardShouldPersistTaps="always"
          showsVerticalScrollIndicator={Platform.OS === 'web'}
          data={locations?.data}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => (
            <Office locationId={item.id} locationName={item.name} locationAvatar={item.imageUrl} />
          )}
        />
      </View>
    </WebWrapper>
  );
}
